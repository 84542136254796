import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLang, setLang, updateLang, useI18nContext } from "@hopper-b2b/i18n";
import { Language } from "@hopper-b2b/types";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { HeaderMenuDropdown } from "../HeaderMenuDropdown";
import "./styles.scss";

interface ILanguageSelectDropdownProps {
  supportedLanguages: Array<Language>;
  defaultLocale?: string;
  onSelect?: (language: Language) => void;
  arrowIcon?: React.ReactElement;
  dropdownLabel?: string;
}

const LanguageSelectDropdown = ({
  supportedLanguages,
  defaultLocale = "en",
  onSelect,
  arrowIcon,
  dropdownLabel,
}: ILanguageSelectDropdownProps) => {
  const { t } = useI18nContext();

  const [storageAvailable, setStorageAvailable] = useState(true);
  useEffect(() => {
    try {
      // Testing to see if localstorage is available
      updateLang();
    } catch (e) {
      setStorageAvailable(false);
    }
  }, []);

  const handleLocaleSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLang(e.target.value);
      if (onSelect) {
        onSelect(
          supportedLanguages.find((l) => l.key.includes(getLang())) ||
            supportedLanguages[0]
        );
      }
      window?.location?.reload();
      //TODO: save to user preferences
    },
    [onSelect, supportedLanguages]
  );

  // Hide component if storage is unavailable
  //TODO: Stop hiding when user preference service is available
  if (!storageAvailable) {
    return null;
  }

  return (
    <HeaderMenuDropdown
      className="b2b-LanguageSelectDropdown"
      content={
        <legend className="b2b-LanguageSelectDropdownLabel" align="left">
          {dropdownLabel || t("languages")}
          {arrowIcon ? (
            arrowIcon
          ) : (
            <FontAwesomeIcon
              icon={faChevronDown as IconProp}
              className="chevron-expand-icon b2b-LanguageSelectDropdownLabelIcon"
            />
          )}
        </legend>
      }
      dropdownContent={
        <fieldset className="b2b-LanguageSelectDropdownList">
          {supportedLanguages.map((l, index) => (
            <div
              key={l.key}
              className={clsx("b2b-LanguageSelectDropdownItem", {
                selected: defaultLocale === l.key,
                last: index === supportedLanguages.length - 1,
              })}
            >
              <input
                type="radio"
                name="locale"
                id={l.key}
                value={l.key}
                defaultChecked={defaultLocale === l.key}
                onChange={handleLocaleSelect}
              />
              <label htmlFor={l.key}>{l.label}</label>
            </div>
          ))}
        </fieldset>
      }
    />
  );
};

export default LanguageSelectDropdown;
