import { memo, ReactElement, useMemo } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  FarePricing,
  ISummaryLineItem,
  SummaryLineItemBaseEnum,
  TripCategory,
} from "@hopper-b2b/types";
import { FiatPrice } from "@b2bportal/purchase-api";
import { Box, Typography } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import { Icon, IconName } from "../Icon";
import { SecurePaymentsBanner } from "../SecurePaymentsBanner";
import clsx from "clsx";
import "./styles.scss";
import { Slot } from "../Slots";

interface PricingBreakdownTitles {
  baseFareText: string;
  frozenBaseFareText?: string;
  taxesAndFeesText: string;
  totalText: string;
  seatsText?: string;
  rewardsAppliedText: (accountName: string) => string;
  rewardsAccountText: (lastFour?: string) => string;
}

export interface PricingBreakdownProps {
  className?: string;
  pricingItems?: IPricingLineItem[];
  summaryItems: ISummaryLineItem[];
  showSecurePaymentsBanner?: boolean;
  titles?: Partial<PricingBreakdownTitles>;
  initialPassengerPricing?: FarePricing;
  tripCategory?: TripCategory;
  currencyString?: string;
  modalTitle?: string;
  priceItemIcon?: ReactElement;
  perTraveler?: boolean;
  useHeader?: boolean;
  rewardsBack?: string;
  displayProductsSeparately?: boolean;
}

export interface IPricingLineItem {
  lineTitle?: string;
  customLineItemText?: string;
  customItemDescriptionText?: string;
  baseAmount: FiatPrice;
  changeFee?: FiatPrice;
  taxesAndFees?: FiatPrice;
  seats?: FiatPrice;
  icon?: ReactElement;
  frozenPrice?: boolean;
  products?: Record<string, FiatPrice>;
  useHtml?: boolean;
  className?: string;
}

interface PriceItemProps {
  baseAmount: FiatPrice;
  taxesAndFees?: FiatPrice;
  titles: {
    itemText: string;
    description?: string;
    taxesAndFeesText: string;
    changeFeeText?: string;
    seatsText?: string;
  };
  changeFee?: FiatPrice;
  lineTitle?: string;
  seats?: FiatPrice;
  icon?: ReactElement;
  priceItemIcon?: ReactElement;
  products?: Record<string, FiatPrice>;
  useHtml?: boolean;
  className?: string;
}

const Products = ({ products }: { products: Record<string, FiatPrice> }) => {
  const { t, formatFiatCurrency } = useI18nContext();

  return (
    <>
      {Object.keys(products).map((product) => (
        <Box
          className={clsx("price-line", "fees")}
          key={`price-line-${product}`}
        >
          <Typography className="label" variant="subtitle2">
            {t(product)}
          </Typography>
          <Typography className="value" variant="subtitle2">
            {formatFiatCurrency(products[product])}
          </Typography>
        </Box>
      ))}
    </>
  );
};

const PriceItem = ({
  baseAmount,
  taxesAndFees,
  titles: { itemText, description, taxesAndFeesText, changeFeeText, seatsText },
  changeFee,
  lineTitle,
  seats,
  icon,
  priceItemIcon,
  products,
  useHtml,
  className,
}: PriceItemProps) => {
  const { formatFiatCurrency } = useI18nContext();

  return (
    <div className="pricing-item-container">
      {priceItemIcon ? priceItemIcon : null}
      <Box className={clsx("pricing-item-wrapper", className)}>
        {lineTitle && (
          <Box className={clsx("price-line", "title")}>
            <Typography variant="subtitle1">{lineTitle}</Typography>
          </Box>
        )}
        <Box className={clsx("pricing-item-body", { "with-icon": !!icon })}>
          {icon && <Box className="icon-section">{icon}</Box>}
          <Box className="content-section">
            <Box className={clsx("price-line", "base")}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                {useHtml ? (
                  <Typography
                    className="label"
                    variant="subtitle2"
                    dangerouslySetInnerHTML={{ __html: itemText }}
                  />
                ) : (
                  <Typography className="label" variant="subtitle2">
                    {itemText}
                  </Typography>
                )}
                <Typography className="description" variant="caption">
                  {description}
                </Typography>
              </Box>
              <Typography className="value" variant="subtitle2">
                {formatFiatCurrency(baseAmount)}
              </Typography>
            </Box>
            {changeFee != null && (
              <Box className="price-line fees">
                <Typography className="label" variant="subtitle2">
                  {changeFeeText || "Change Fee"}
                </Typography>
                <Typography className="value" variant="subtitle2">
                  {formatFiatCurrency(changeFee)}
                </Typography>
              </Box>
            )}
            {seats != null && (
              <Box className={clsx("price-line", "fees")}>
                <Typography className="label" variant="subtitle2">
                  {seatsText || "Seat Selection"}
                </Typography>
                <Typography className="value" variant="subtitle2">
                  {formatFiatCurrency(seats)}
                </Typography>
              </Box>
            )}
            {taxesAndFees !== undefined ? (
              <Box className={clsx("price-line", "fees")}>
                <Typography className="label" variant="subtitle2">
                  {taxesAndFeesText}
                </Typography>
                <Typography className="value" variant="subtitle2">
                  {formatFiatCurrency(taxesAndFees)}
                </Typography>
              </Box>
            ) : null}
            {products ? <Products products={products} /> : null}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

interface SummaryItemProps {
  summaryLineItem: ISummaryLineItem;
  titles: {
    totalText: string;
    rewardsAppliedText?: (accountName: string) => string;
    rewardsAccountText?: (lastFour?: string) => string;
  };
}

type SummaryItemContent = {
  icon?: ReactElement;
  titleVariant?: Variant;
  title: string;
  details?: string;
  priceVariant?: Variant;
  price: string;
  priceSubstring?: string;
};

const SummaryItem = memo(
  ({ summaryLineItem, titles: { totalText } }: SummaryItemProps) => {
    const { t, formatFiatCurrency } = useI18nContext();

    const summaryItemContent: SummaryItemContent = useMemo(() => {
      switch (summaryLineItem.type) {
        case SummaryLineItemBaseEnum.TOTAL:
          return {
            titleVariant: "subtitle1",
            title: summaryLineItem.label ?? totalText,
            details: summaryLineItem.details,
            price: formatFiatCurrency(summaryLineItem.fiatPrice),
          };
        case SummaryLineItemBaseEnum.PAYMENT:
          return {
            icon: (
              <Icon name={IconName.Payment} className="pricing-item-icon" />
            ),
            title: "",
            price: formatFiatCurrency(summaryLineItem.fiatPrice),
          };
        case SummaryLineItemBaseEnum.CUSTOM:
          return {
            titleVariant: "subtitle1",
            title: summaryLineItem.label ?? "",
            priceVariant: "subtitle2",
            price: formatFiatCurrency(summaryLineItem.fiatPrice),
            icon: summaryLineItem.icon,
          };
        case SummaryLineItemBaseEnum.PRODUCT:
          return {
            titleVariant: "subtitle1",
            title: t(summaryLineItem.product),
            details: `${formatFiatCurrency(
              summaryLineItem.perPaxPrice
            )} per traveler`,
            price: formatFiatCurrency(summaryLineItem.fiatPrice),
          };
        case SummaryLineItemBaseEnum.PRICE_FREEZE_DISCOUNT:
          return {
            title: t("priceFreezeDiscountText") as string,
            price: `-${formatFiatCurrency(summaryLineItem.fiatPrice)}`, //Negative value to render as a discount
          };
        case SummaryLineItemBaseEnum.CHFAR_DISCOUNT:
          return {
            title: t("chfarDiscountText") as string,
            price: `-${formatFiatCurrency(summaryLineItem.fiatPrice)}`, //Negative value to render as a discount
          };
        case SummaryLineItemBaseEnum.VOUCHER_DISCOUNT:
          return {
            title: t("voucherDiscountText") as string,
            price: `-${formatFiatCurrency(summaryLineItem.fiatPrice)}`, //Negative value to render as a discount
          };
        case SummaryLineItemBaseEnum.CREDIT_PAYMENT:
          return {
            title: t("creditPaymentText") as string,
            price: `-${formatFiatCurrency(summaryLineItem.fiatPrice)}`, //Negative value to render as a discount
          };
        case SummaryLineItemBaseEnum.PROMOTION_DISCOUNT:
          return {
            title: t("promotionDiscountText") as string,
            price: `-${formatFiatCurrency(summaryLineItem.fiatPrice)}`, //Negative value to render as a discount
          };
        case SummaryLineItemBaseEnum.REWARDS:
          return {
            titleVariant: "subtitle1",
            title: t("pointsApplied"),
            price: `-${summaryLineItem.rewardsPrice.value} ${summaryLineItem.rewardsPrice.currency}`,
          };
        case SummaryLineItemBaseEnum.CARD_PAYMENT:
          return {
            icon: (
              <Icon name={IconName.Payment} className="pricing-item-icon" />
            ),
            title: `${summaryLineItem.label} ${summaryLineItem.lastFour}`,
            price: `-${formatFiatCurrency(summaryLineItem.fiatPrice)}`,
          };
        case SummaryLineItemBaseEnum.AMOUNT_DUE:
          return {
            title: t("amountDue"),
            price: `${formatFiatCurrency(summaryLineItem.fiatPrice)}`,
          };
        default:
          return {
            title: "",
            price: "",
          };
      }
    }, [summaryLineItem, totalText, t, formatFiatCurrency]);

    return (
      <Box className={clsx("summary-item-wrapper", summaryLineItem.type)}>
        <Box className={clsx("summary-line-section", "left")}>
          {summaryItemContent.icon}
          <Box className="label">
            <Typography
              variant={summaryItemContent.titleVariant ?? "subtitle2"}
              component="p"
            >
              {summaryItemContent.title}
            </Typography>
            {summaryItemContent.details && (
              <Typography className="label-details" variant="subtitle2">
                {summaryItemContent.details}
              </Typography>
            )}
          </Box>
        </Box>
        <Box className={clsx("summary-line-section", "right")}>
          <Typography
            className={clsx("value", "fiat")}
            variant={summaryItemContent.priceVariant ?? "subtitle1"}
            component="p"
          >
            {summaryItemContent.price}
          </Typography>
          {summaryItemContent.priceSubstring ? (
            <Typography
              className={clsx("value", "rewards")}
              variant={summaryItemContent.priceVariant ?? "subtitle1"}
              component="p"
            >
              {summaryItemContent.priceSubstring}
            </Typography>
          ) : null}
        </Box>
      </Box>
    );
  }
);

export const PricingBreakdown = ({
  className,
  pricingItems,
  summaryItems,
  showSecurePaymentsBanner = false,
  titles: lineTitles,
  initialPassengerPricing,
  tripCategory,
  currencyString,
  modalTitle,
  priceItemIcon,
  useHeader = false,
  perTraveler = false,
  rewardsBack,
  displayProductsSeparately,
}: PricingBreakdownProps): JSX.Element => {
  const { t } = useI18nContext();
  const titles = {
    baseFareText: t("baseFare"),
    changeFeeText: t("changeFee"),
    frozenBaseFareText: t("frozenBaseFare"),
    rewardsAccountText: (lastFour?: string) =>
      lastFour
        ? `${t("endingInWithLastFour", { lastFour })}: `
        : `${t("paymentMethodSubtitleAmount")}: `,
    rewardsAppliedText: (accountName: string) =>
      `${t("appliedWithAccountName", { accountName })}:`,
    seatsText: t("seatSelection"),
    taxesAndFeesText: t("taxesAndFees"),
    totalText: perTraveler ? t("totalPerTraveler") : t("total"),
    ...lineTitles,
  };

  const content = {
    oneWayPerTraveler: t("oneWayPerTraveler"),
    roundTripPerTraveler: t("roundTripPerTraveler"),
    emptyLineItems: t("emptyLineItems"),
  };
  const LineItems = pricingItems?.length ? (
    pricingItems.map((item, index) => (
      <PriceItem
        key={`price-item-${index}`}
        baseAmount={item.baseAmount}
        taxesAndFees={item.taxesAndFees}
        titles={{
          itemText:
            item.customLineItemText ??
            (item.frozenPrice
              ? titles.frozenBaseFareText ?? titles.baseFareText
              : titles.baseFareText),
          description: item.customItemDescriptionText,
          taxesAndFeesText: titles.taxesAndFeesText,
          seatsText: titles.seatsText,
        }}
        changeFee={item.changeFee}
        lineTitle={item.lineTitle}
        seats={item.seats}
        icon={item.icon}
        priceItemIcon={priceItemIcon}
        products={!displayProductsSeparately ? item.products : undefined}
        useHtml={item.useHtml}
        className={item.className}
      />
    ))
  ) : initialPassengerPricing?.baseAmount ? (
    <>
      <Typography
        className="initial-passenger-pricing-title"
        variant="subtitle1"
      >
        {tripCategory === TripCategory.ONE_WAY
          ? content.oneWayPerTraveler
          : content.roundTripPerTraveler}
      </Typography>
      <PriceItem
        key={"price-item-initial"}
        baseAmount={initialPassengerPricing.baseAmount.fiat}
        taxesAndFees={initialPassengerPricing.taxAmount.fiat}
        titles={{
          itemText: titles.baseFareText,
          taxesAndFeesText: titles.taxesAndFeesText,
          seatsText: titles.seatsText,
        }}
      />
    </>
  ) : (
    <Typography className="empty-line-items">
      {content.emptyLineItems}
    </Typography>
  );
  const products =
    Object.keys(pricingItems?.[0]?.products ?? []).length > 0
      ? pricingItems[0].products
      : null;

  return (
    <Box className={clsx(className, "pricing-breakdown")}>
      {modalTitle ? (
        useHeader ? (
          <Box className="pricing-header-container">
            <Typography className="pricing-header" variant="h6">
              {modalTitle}
            </Typography>
          </Box>
        ) : (
          <div className="price-breakdown-modal-title">{modalTitle}</div>
        )
      ) : null}

      <Slot id="price-breakdown-additional-info" rewardsBack={rewardsBack} />
      {pricingItems && <Box className="pricing-items-section">{LineItems}</Box>}
      {displayProductsSeparately && products && (
        <Box className="pricing-items-section">
          <Box className={clsx("pricing-item-wrapper", className)}>
            <Box className={clsx("pricing-item-body", { "with-icon": false })}>
              <Box className="content-section">
                <Products products={products} />
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <Box className="summary-items-section">
        {summaryItems.map((item, index) => (
          <SummaryItem
            key={`summary-item-${index}`}
            summaryLineItem={item}
            titles={{
              totalText: titles.totalText,
              // rewardsAppliedText: titles.rewardsAppliedText,
              // rewardsAccountText: titles.rewardsAccountText,
            }}
          />
        ))}
      </Box>
      <Slot id="price-breakdown-discount-price-line" />
      {currencyString ? (
        <div className="trip-total-currency">{currencyString}</div>
      ) : null}
      {showSecurePaymentsBanner && (
        <SecurePaymentsBanner className="price-breakdown-secure-banner" />
      )}
    </Box>
  );
};
