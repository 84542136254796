import React, { useState, FC, useEffect } from "react";
import { Header as GenericHeader } from "@hopper-b2b/ui";
import { Grid, IconButton } from "@material-ui/core";
import {
  PATH_HOME,
  PATH_FLIGHTS,
  PATH_FLIGHTS_BOOK_CONFIRMATION,
  PATH_FLIGHTS_SEARCH,
} from "@hopper-b2b/common-utils";
import { useIsSmallScreen } from "@hopper-b2b/utilities";
import clsx from "clsx";
import { Route, Switch, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom-v5-compat";

import { HeaderMenu } from "../HeaderMenu";
import { B2B_LOGO_ALT } from "../../../../utils/constants";
import { ReactComponent as ClientLogo } from "../../../../assets/b2b/client-logo.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/client/flair/close.svg";
import { ReactComponent as HamburgerIcon } from "../../../../assets/client/hamburguer.svg";
import "./styles.scss";
import { DegradedPerformanceNotice } from "../DegradedPerformanceNotice";

export const HEADER_MENU_POPPER_ID = "flair-header-menu-popper";

const HeaderLogos: FC = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(PATH_HOME);
  };

  return (
    <div className={clsx("header-logo-container")} onClick={handleClick}>
      <ClientLogo
        aria-label={B2B_LOGO_ALT}
        className={clsx("mobile img-logo client-logo header-logo-client")}
      />
    </div>
  );
};

const HamburgerHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isSmall = useIsSmallScreen();
  const menuOpen = Boolean(anchorEl);
  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add("hamburguer-menu-open");
    } else {
      document.body.classList.remove("hamburguer-menu-open");
    }
  }, [menuOpen]);

  const handleClick = (event: React.MouseEvent) => {
    const header = event.currentTarget.closest("header");
    setAnchorEl(anchorEl ? null : isSmall ? header : event.currentTarget);
  };
  const onMenuClose = (event: Event) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  return (
    <Grid item>
      <IconButton
        disableRipple
        onClick={handleClick}
        aria-describedby={HEADER_MENU_POPPER_ID}
        aria-label={menuOpen ? "close menu" : "open menu"}
        className={menuOpen ? "close" : "open"}
      >
        {menuOpen ? (
          <CloseIcon className="header-close-icon" />
        ) : (
          <HamburgerIcon className="header-hamburguer-icon" />
        )}
      </IconButton>
      <HeaderMenu open={menuOpen} anchorEl={anchorEl} onClose={onMenuClose} />
    </Grid>
  );
};

type MobileHeaderProps = {
  displayPerfNotice?: boolean;
};

const MobileHeader = ({ displayPerfNotice = false }: MobileHeaderProps) => {
  const location = useLocation();

  const renderGenericHeader = (isConfirmation: boolean) => (
    <>
      <GenericHeader
        isMobile={true}
        shadow={true}
        className={clsx("b2b", {
          confirmation: isConfirmation,
          dark: location.pathname === PATH_HOME,
        })}
        left={
          <div className={clsx("header-logo")}>
            <HeaderLogos />
          </div>
        }
        right={<HamburgerHeader />}
      />
      {displayPerfNotice && <DegradedPerformanceNotice />}
    </>
  );

  return (
    <Switch>
      <Route
        path={[PATH_FLIGHTS]}
        render={() => renderGenericHeader(false)}
        exact
      />
      <Route
        path={[PATH_FLIGHTS_BOOK_CONFIRMATION]}
        render={() => renderGenericHeader(true)}
        exact
      />
      <Route path={PATH_FLIGHTS_SEARCH} />
      <Route path="*" render={() => renderGenericHeader(false)} />
    </Switch>
  );
};

export { HeaderLogos, MobileHeader };
