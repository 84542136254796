import { DEFAULT_CURRENCY } from "../../utils/constants";

const B2B_CURRENCY_PREFERENCE_KEY = "b2b-currency-preference";

/**
 * get user selected currency, if exists. Check local storage first since
 * some tenants (flair) can select their currenc If there's nothing in local storage, fallback to USD
 */
export const getCurrency = (): string => {
  let fromStorage = null;
  try {
    fromStorage = localStorage?.getItem(B2B_CURRENCY_PREFERENCE_KEY);
  } catch (e) {
    //console.warn("storage unavailable")
  }

  return fromStorage || DEFAULT_CURRENCY;
};

export const setCurrency = (lang: string) => {
  localStorage.setItem(B2B_CURRENCY_PREFERENCE_KEY, lang);
};
