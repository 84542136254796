import AirplaneFintech from "./client/airplane-fintech.svg";
import Bell from "./client/bell.svg";
import BlackLock from "./client/black-lock.svg";
import Card from "./client/card.svg";
import CheckmarkFilled from "./client/checkmark-filled.svg";
import CheckmarkSimple from "./client/checkmark-simple.svg";
import Chevron from "./client/chevron.svg";
import ChevronLeft from "./client/chevron-left.svg";
import Filter from "./client/flair/filter.svg";
import RoundTrip from "./client/flair/roundtrip.svg";
import Flights from "./client/flights.svg";
import GreenShieldCheck from "./client/green-shield-check.svg";
import GreenBolt from "./client/green-bolt.svg";
import Info from "./client/info.svg";
import LocationMarker from "./client/flair/location-marker.svg";
import Lock from "./client/lock.svg";
import LogoSmall from "./client/logo_small.svg";
import MissedConnection from "./client/missed-connection.svg";
import OneWay from "./client/flair/oneway.svg";
import Shield from "./client/shield.svg";
import Cfar from "./client/cfar.svg";
import Check from "./client/check.svg";
import Close from "./client/flair/close.svg";
import FlightDelay from "./client/flight-delay.svg";
import InfoQuestion from "./client/info-question.svg";
import InfoWhite from "./client/info-white.svg";
import Radio from "./client/radio.svg";
import RadioSelected from "./client/radio-selected.svg";
import Star from "./client/star.svg";
import Copy from "./client/copy.svg";
import Headset from "./client/headset.svg";
import Dropdown from "./client/dropdown.svg";
import ErrorState from "./client/flair/error-state.svg";
import PlaneLanding from "./client/flair/plane-landing.svg";
import PlaneTakeoff from "./client/flair/plane-takeoff.svg";
import PlaneForward from "./client/flair/plane-forward.svg";
import Calendar from "./client/flair/calendar.svg";
import Menu from "./client/flair/menu.svg";
import Clock from "./client/flair/clock.svg";
import Guest from "./client/flair/user.svg";
import CheckedBag from "./client/flair/checked-bag.svg";
import CarryOnBag from "./client/flair/carry-on-bag.svg";

import { IIconSet } from "@hopper-b2b/utilities";

export const lightModeIcons: IIconSet = {
  airplane: PlaneForward,
  airplaneDepart: PlaneTakeoff,
  airplaneArrive: PlaneLanding,
  calendarStartIcon: Calendar,
  calendarEndIcon: Calendar,
  airplaneFintech: AirplaneFintech,
  bell: Bell,
  blackLock: BlackLock,
  calendar: Calendar,
  card: Card,
  checkmarkFilled: CheckmarkFilled,
  checkmarkSimple: CheckmarkSimple,
  chevron: Chevron,
  chevronLeft: ChevronLeft,
  clock: Clock,
  error: ErrorState,
  filter: Filter,
  flights: Flights,
  info: Info,
  locationMarker: LocationMarker,
  lock: Lock,
  missedConnection: MissedConnection,
  oneway: OneWay,
  passenger: Guest,
  roundtrip: RoundTrip,
  shield: Shield,
  logoSmall: LogoSmall,
  star: Star,
  close: Close,
  infoWhite: InfoWhite,
  greenShieldCheck: GreenShieldCheck,
  copy: Copy,
  cfar: Cfar,
  check: Check,
  flightDelay: FlightDelay,
  infoQuestion: InfoQuestion,
  vipIcon: GreenBolt,
  headset: Headset,
  radio: Radio,
  radioSelected: RadioSelected,
  guest: Guest,
  hamburguer: Menu,
  dropdown: Dropdown,
  checkedBag: CheckedBag,
  carryOnBag: CarryOnBag,
};
