import ErrorState from "./client/flair/error-state.svg";
import Money from "./client/flair/money.svg";

// loading screen images
import LoadingPlaneDeparture from "./client/flair/loading/departure.svg";
import LoadingUser from "./client/flair/loading/user.svg";
import LoadingBags from "./client/flair/loading/bags.svg";

export const images = {
  errorState: ErrorState,
  priceDifferenceImage: Money,
  user: LoadingUser,
  planeDeparture: LoadingPlaneDeparture,
  loadingBags: LoadingBags,
};
