import { Typography } from "@material-ui/core";
import clsx from "clsx";

import styles from "./styles.module.scss";
import { PATH_TERMS_MCP } from "../../utils/constants";

export const EnglishTermsAndConditions = () => {
  return (
    <>
      <Typography
        variant="h3"
        className={clsx(
          styles.bold,
          styles.underline,
          styles.mb,
          styles.center
        )}
      >
        Terms of Service
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Hopper Inc. and its affiliates (
        <span className={styles.bold}>"Hopper"</span>) provide the Hopper
        Technology Solutions Connect Services (
        <span className={styles.bold}>"HTS Connect Services"</span>) on behalf
        of users of Flair Airlines Ltd. (
        <span className={styles.bold}>"Flair"</span>) for use by you (
        <span className={styles.bold}>"User"</span>). HTS Connect Services
        include, but are not limited to, utilizing Hopper data and proprietary
        predictive algorithms to search for, and receive notifications about
        flights and other travel services; booking and payment for such travel
        services; purchasing Hopper and/or third-party products; utilizing
        published Hopper reports (
        <span className={styles.bold}>"Hopper Research Data"</span>); and other
        related services. Hopper provides you access to the HTS Connect
        Services, subject to your acceptance of all of the following Terms and
        Conditions (<span className={styles.bold}>"Terms"</span>), which
        constitute a binding legal agreement between you and Hopper. By
        accessing, downloading, copying, and/or using the HTS Connect Services,
        you agree to be bound by these Terms.
      </Typography>
      <Typography className={styles.mb}>
        PLEASE REVIEW THE{" "}
        <span className={styles.bold}>"DISPUTE RESOLUTION"</span> OF THESE TERMS
        (SECTION 13) CAREFULLY, AS IT REQUIRES YOU TO RESOLVE ANY FUTURE
        DISPUTES WITH HOPPER THROUGH BINDING INDIVIDUAL ARBITRATION. YOU
        ACKNOWLEDGE AND AGREE THAT YOU ARE WAIVING THE RIGHT TO A TRIAL BY JURY,
        AND YOUR RIGHT TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY
        PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING AGAINST HOPPER.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        You should also read Hopper's separate{" "}
        <a
          href="https://hopper.com/legal/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          PRIVACY NOTICE
        </a>
        , which explains how Hopper handles and uses the data that Hopper
        collects when you use the HTS Connect Services, and which is
        incorporated by reference in these Terms. By agreeing to these Terms,
        you also consent to the collection and use of data as set forth in
        Hopper's Privacy Notice.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        If you need to contact Hopper Customer Support, you must first reach out
        to Flair customer support, who will redirect you to Hopper. You can
        contact Flair customer support via the information provided in your
        booking confirmation email.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        In addition to these Terms, Hopper offers individual products and
        services for purchase that may be subject to additional terms and
        conditions, which will be provided to you both prior to and after
        purchase. Travel services provided by third-parties are subject to
        third-party terms. Please review all additional terms carefully prior to
        any purchase or booking.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        The HTS Connect Solution is protected by reCAPTCHA Enterprise, a
        third-party service provided by Google. Google's{" "}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>{" "}
        apply to reCAPTCHA Enterprise. By using the Hopper website, you consent
        to the use of this service and Google's terms.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        1. Use of the Apps and Services; Restrictions
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        1.1. <span className={styles.underline}>Acceptable Use</span>. You may
        use the HTS Connect Services only for lawful personal use, and in
        accordance with these Terms, to search for and make travel bookings
        and/or related purchases for yourself, or non-commercially on behalf of
        a person who has authorized you to do so. Hopper may, in its sole
        discretion, terminate your right to use the HTS Connect Services at any
        time, and may take all available legal recourse for actual or suspected
        violations of these Terms, including cancellation of bookings made in
        violation of these Terms. Any delay by Hopper in taking such actions
        does not constitute a waiver of Hopper's rights to enforce these Terms.
        By making the HTS Connect Services available for your use, Hopper does
        not consent to act as your agent or fiduciary.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        1.2. <span className={styles.underline}>Use Restrictions</span>. The HTS
        Connect Services are not intended for use by any person who is, and you
        are not permitted to use the HTS Connect Services if you are, under the
        age of 16 or under the legal age of majority in the jurisdiction in
        which you reside. Other than information required to complete a booking,
        Hopper does not knowingly collect personal identifiable information from
        minors under the age of 16. If a minor under 16 has provided us with
        personal information without parental or guardian consent, the parent or
        guardian may contact us by emailing us at{" "}
        <a href="mailto:privacy@hopper.com">privacy@hopper.com</a>, in which
        case we will remove the information and unsubscribe the minor from any
        of our electronic marketing lists.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        You may not use the HTS Connect Services if you are identified on any
        U.S. or Canadian government list of prohibited, sanctioned, or
        restricted parties, or if you are located in a country that is subject
        to a U.S. or Canadian government embargo, or that has been designated by
        the U.S. or Canadian government as a "terrorist supporting" country.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        While you may make copies of information related to your booked travel
        reservations, you may not attempt to access, copy, decompile,
        disassemble, or reverse-engineer, distribute, sublicense, publish,
        interrupt, destroy, or interfere with the source code, libraries,
        databases, algorithms, data, or Hopper systems or networks that make up
        the HTS Connect Services, or attempt to circumvent any security controls
        included in the HTS Connect Services. You may not attempt to access the
        HTS Connect Services using any automated tools, including without
        limitation the use of bots, spiders, crawlers, scrapers or other similar
        tools, or other technology not authorized by Hopper. You may not
        deep-link to any portion of the HTS Connect Services (including, without
        limitation, the purchase path for any travel services) or frame, mirror
        or otherwise incorporate any part of the HTS Connect Services into any
        other app or site without Hopper's prior express written authorization.
        You may not use the HTS Connect Services for any purpose that is
        fraudulent or deceptive. If your right to access the HTS ConnectServices
        has been previously revoked by Hopper, all use of the HTS Connect
        Services is prohibited.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        1.3. <span className={styles.underline}>Updates</span>. Hopper may
        update the HTS Connect Services from time to time in order to improve
        the HTS Connect Services. If you delay or refuse updates to the HTS
        Connect Services, this may compromise or prevent us from delivering the
        HTS Connect Services.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        2. Payment Authorizations
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        2.1. <span className={styles.underline}>Checkout</span>. In order to
        make purchases using the HTS Connect Services from Hopper and/or from
        third-party travel service providers (e.g., airlines), you will need to
        provide Hopper with certain information. You agree: (i) to provide and
        maintain accurate, current and complete information for your booking,
        (ii) to maintain the security of your information by not sharing,
        disclosing, or making available access credentials to any unauthorized
        person, and (iii) to notify Hopper promptly if you discover or otherwise
        suspect any security breaches relating to your use of the HTS Connect
        Services. You agree to be responsible for all activities and purchases
        that occur using the HTS Connect Services, and you accept all risks of
        unauthorized access to the HTS Connect Services on your behalf resulting
        from any act or omission by you in violation of these terms. Information
        entered in connection with a booking via the HTS Connect Services will
        be stored for that booking only, and will not be linked to any prior or
        future bookings.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        2.2. <span className={styles.underline}>Payment Methods</span>. Hopper
        enables you to make purchases from Hopper, and/or third-party travel
        service providers, by allowing you to provide a valid, authorized debit,
        credit, prepaid credit card or other authorized payment method during
        checkout (<span className={styles.bold}>"Payment Instrument"</span>). By
        entering a payment method in connection with your booking, you represent
        to Hopper that you are an authorized user of the Payment Instrument. You
        are responsible for ensuring that the Payment Instrument(s) associated
        with your booking is at all times current, non-expired, and valid for
        payments. Hopper is not a bank and does not offer banking or "money
        transmission services," and is not a "money service business," as those
        terms are defined by the United States Department of Treasury.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        2.3. <span className={styles.underline}>Payment Authorization</span>.
        You authorize Hopper, and/or applicable third-party travel service
        providers, to charge your entered Payment Instrument(s) for the total
        amount of all purchases or other payment transactions initiated while
        using the HTS Connect Services by you during checkout as necessary to
        complete the purchase, payment or other financial transaction requested
        or agreed to by you, including without limitation all fees and taxes. In
        some cases, Hopper may pass your Payment Instrument(s) to a third party
        travel service provider (such as an airline) in order to process your
        authorized payment. By making a booking, you authorize such transfer of
        your Payment Instrument. In the event you are entitled to a reversal,
        refund, chargeback, or other adjustment associated with a transaction
        you made using your Payment Instrument, you also authorize a credit to
        your Payment Instrument(s) by Hopper as necessary to execute that
        transaction.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        2.4. <span className={styles.underline}>Failed Transactions</span>. In
        the event that a charge to any Payment Instrument fails, you agree to
        link a new, valid Payment Instrument within three (3) business days of
        notification by Hopper. Your l purchased Hopper products and third party
        travel services may be cancelled, until a valid Payment Instrument is
        provided and the outstanding uncharged balance is resolved. You further
        authorize Hopper to attempt to re-submit any failed charge to any
        Payment Instrument(s) associated with your account at subsequent times
        until successful. Hopper is not responsible for any charges imposed by
        the issuer of your Payment Instrument as a result of any failed charge
        or re-charge by Hopper. To the extent that Hopper is unable to
        successfully charge any Payment Instrument entered as payment for
        authorized transactions for more than thirty (30) days, you understand
        and agree that Hopper may employ a collection agency or other business
        in an effort to collect any outstanding debts and fees associated with
        your booking, and you will not contest the use of a third party to
        collect the debt and fees owed to Hopper. You agree that you will be
        responsible for any legal, court, arbitration or collection agency fees
        associated with rectifying your Hopper account and all monies owed
        thereunder. You agree that Hopper, or any agency or business employed by
        Hopper, has the right to contact you and your heirs via telephone, email
        or (if not prohibited by law) in-person using the information you
        provided during any contact with Hopper, or any other publicly available
        or attainable information, in an effort to collect any monies and fees
        owed to Hopper, whether specifically referenced in these Terms or not,
        and such contact may be made in a manual or automated fashion.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        2.5.{" "}
        <span className={styles.underline}>
          Unauthorized Transactions; Lost Devices
        </span>
        . If any transaction receipt received by you appears incorrect, or was
        unauthorized or fraudulent, you shall notify Hopper immediately, by
        contacting Flair, which will redirect you to Hopper Support by
        submitting a request through the information available via your booking
        confirmation email.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        2.6. <span className={styles.underline}>Currency Conversions</span>.
        Some prices and/or fees provided by the HTS Connect Services for
        purchases may be displayed to you in a default, or your preferred or
        selected, currency, although the charge made to your Payment Instrument
        by a third-party service provider will be in a local currency. In these
        cases, the amount charged to your Payment Instrument for the purchase
        may vary slightly from the amount displayed, due to fluctuations in
        currency exchange rates beyond Hopper's control. Your Payment Instrument
        provider or issuer may also include a fee to process the transaction or
        convert currency, plus any applicable taxes for international purchases.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        3. Consent to Electronic Communications, Disclosures and Notices; Paper
        Copies.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        3.1. By accepting these Terms, you agree that Hopper will provide the
        Terms, its Privacy Policy, and all other applicable terms, and any
        amendments made by Hopper thereto, as well as all disclosures, notices,
        transaction receipts, transaction authorizations, and all transaction
        statements or other information required by law (
        <span className={styles.bold}>"Disclosures"</span>), electronically to
        the email address you provide. Your electronic acceptance or receipt of
        the Disclosures, and/or your continued use of the HTS Connect Services
        after publication of the Disclosures by Hopper, has the same effect as
        if you received them in paper, or signed them in ink. Except as
        otherwise required by applicable law, Disclosures are considered
        received and accepted by you within 24 hours of the time emailed or
        messaged to you, whether or not you choose to read them. In order to
        access and retain the electronic Disclosures you will need, and
        represent that you have, the following: (i) a computer or mobile device
        with an internet connection; (ii) a valid email address that can receive
        emails from @hopper.com and (iii) sufficient storage space to save past
        Disclosures and/or an installed printer to print them. You have a right
        to receive Disclosures in paper form. If you wish to receive a paper
        copy of any Disclosure, or any transaction receipt, you may request a
        copy of the Disclosure or receipt within 180 days of the date of the
        Disclosure or receipt. Hopper will provide paper copies free of charge.
        Requests to receive any paper copy, or to withdraw your consent to
        future electronic Disclosures, may be made by mailing a written request
        to Hopper (USA), Inc., Attn: Legal Department, 265 Franklin Street,
        Suite 1702, Boston, MA 02110. Requests to cancel your consent to future
        electronic Disclosures will be completed in a reasonable time.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        3.2.{" "}
        <span className={styles.underline}>
          Consent to Communications and Contact
        </span>
        . By making any purchase or booking using the HTS Connect Services, you
        consent to be contacted by Hopper, and/or by the third-party provider of
        the travel services booked, electronically, including via email, phone,
        SMS, in-app messaging, or other available digital messaging service, for
        any purpose related to your use of the HTS Connect Services, and/or any
        bookings or other purchases made by you via the HTS Connect Services.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        3.3. Your computer and/or mobile device must be connected to the
        internet in order to access the HTS Connect Services and to make and
        receive communications to/from Hopper, including to contact Hopper's
        customer service team. You are responsible for making all arrangements
        necessary for your device to have internet connectivity and are
        responsible for all third-party charges related to such access and/or
        the transmission and receipt of data.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        3.4. When you make a booking with Hopper, you agree that communications
        to Hopper's Customer Support team related to your booking may be made
        via any channels, such as phone, SMS, or email, that may be initiated by
        Hopper or you.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        4. Hopper Sort Order
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        4.2. When you search for flights using the HTS Connect Services, Hopper
        provides you a number of options for displaying, filtering, and sorting
        the results, which includes price and duration.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        5. Third-Party Travel Services
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.1. The HTS Connect Services provide access to travel information, such
        as flight pricing and availability, features and amenities, and terms
        and conditions, provided and maintained by third-parties. These
        third-party providers are independent contractors and not agents of
        Hopper. Your contract for these travel services is with the third-party
        provider, and not with Hopper. While Hopper works hard to ensure
        correctness, Hopper cannot verify, and is not responsible for, the
        accuracy of information provided by third parties regarding their
        respective services. Hopper also is not liable for the acts, errors,
        delays, omissions, representations, warranties, breaches, bankruptcy, or
        negligence of any such third-party providers or for any personal
        injuries, death, property damage, or other damages or expenses resulting
        from third-party provided services. Hopper is not responsible to make
        any refund in the event of any delay, failure, breach, cancellation,
        overbooking, strike, force majeure or other causes beyond Hopper's
        direct control. By offering third-party travel services for booking via
        the HTS ConnectServices, Hopper does not endorse, or make any
        representation or warranty about, the quality of any service provider.
        In addition, unless otherwise expressly set forth, Hopper does not
        purport to be affiliated with, or to be endorsed by any third-party
        service providers.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.2. The purchase of third-party travel products and services through
        the HTS Connect Services, and entitlement to cancellation or refunds for
        such services, including eligibility to loyalty programs, will be
        governed by and subject to additional terms and conditions from such
        providers, and subject to laws and regulations imposed by government
        entities, and you agree to abide by all such terms, laws, and
        regulations. Where possible, Hopper will make these terms available to
        you. However, additional applicable terms may be provided on the
        third-party provider's website. Please read these additional provider
        terms and conditions carefully prior to purchase from any third-party
        service provider using the HTS Connect Services, and ensure that you
        understand the full terms applicable to your airfare or other
        third-party service. If you make bookings on behalf of another person(s)
        with their authorization, you agree that you will inform such other
        person(s) about these Terms and any third-party terms, rules and
        restrictions that apply to the bookings made on their behalf. You
        understand and agree that any violation of third party terms of service
        may result in a cancellation of your bookings, denial of service, and
        forfeit of any amounts paid. Hopper is not responsible for any damages
        resulting from your violation of third party terms.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3. <span className={styles.underline}>Air Bookings</span>: Air travel
        bookings are only guaranteed once the purchase has been completed and
        the tickets have been issued, as confirmed to you by Hopper by email
        following your purchase. Until such time, you may not be able to view
        information about your reservation on the carrier's website.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.1. <span className={styles.underline}>Multi-Ticket Travel</span>.
        Your booking of air travel through the HTS Connect Services will be by
        combining multiple one-way tickets, on one or more airlines, instead of
        a single one-way or roundtrip ticket. Combining one-way tickets in this
        manner may provide a greater choice of flights, at lower cost. However,
        each one-way ticket in your journey will be subject to its own airline
        rules, restrictions and fees. For such travel, if one of your flights is
        impacted by a flight irregularity (e.g., an airline-initiated
        cancelation, delay or schedule change) and such irregularity may cause
        you to miss your departure for another flight on which you are
        separately ticketed, the airline for that other flight is under no
        obligation to re-accommodate you, and subject to costs covered by
        Hopper's Missed Connection Guarantee which is included with the HTS
        Connect Services, you remain responsible for any charges due to changes
        (if permitted) to the routing, travel dates or times, or carriers, for
        the portion of your journey covered by such separate ticket(s). Some
        tickets may not be eligible for passenger-initiated changes under
        applicable fare rules, and "no-showing" for a flight may result in the
        ticket being canceled and losing all remaining value. Your flights
        purchased using the HTS Connect Services will include Hoppe'/s{" "}
        <a href={PATH_TERMS_MCP} target="_blank" rel="noreferrer">
          Missed Connection Guarantee
        </a>
        , which is subject to its own terms and conditions which are located
        here, and incorporated herein by reference.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Multi-ticket travel may require switching terminals and / or picking up
        baggage and re-checking baggage prior to the next scheduled flight in
        the itinerary. This may require the possible exit and re-entry of
        security. Prior to purchasing a multi-ticket itinerary, please be aware
        of any timing considerations that may impact your ability to complete
        your trip. While multi-ticket itineraries include Hopper's Missed
        Connection Guarantee, you are solely responsible for retrieving your
        luggage in between destinations and being aware of any baggage and
        security obligations in between connections.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.2.{" "}
        <span className={styles.underline}>International Air Travel</span>. You
        are solely responsible for ensuring that you meet all of the entry
        requirements imposed by governmental authorities at your destination(s),
        including countries you may just be transiting through, including health
        and passport/visa requirements, and that your travel documents, such as
        passports and visas (transit, business, tourist, and otherwise), are in
        order. Hopper does not provide advice regarding entry requirements for
        any destination. You must consult the appropriate embassy or consulate
        for such information. Requirements may change and you should check for
        up-to-date information before booking and departure. Hopper is not
        responsible if you are refused entry onto a flight or into any country
        due to your failure to satisfy any entry requirements. BY OFFERING
        RESERVATIONS FOR TRAVEL PRODUCTS IN PARTICULAR DESTINATIONS, HOPPER DOES
        NOT REPRESENT OR WARRANT THAT TRAVEL TO SUCH AREAS IS ADVISABLE OR
        WITHOUT RISK, AND IS NOT LIABLE FOR DAMAGES OR LOSSES THAT MAY RESULT
        FROM TRAVEL TO SUCH DESTINATIONS.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.3.{" "}
        <span className={styles.underline}>
          Advice to International Passengers on Limitations of Liability
        </span>
        . PASSENGERS EMBARKING UPON A JOURNEY INVOLVING AN ULTIMATE DESTINATION
        OR A STOP IN A COUNTRY OTHER THAN THE COUNTRY OF DEPARTURE ARE ADVISED
        THAT THE PROVISIONS OF AN INTERNATIONAL TREATY (THE WARSAW CONVENTION,
        THE 1999 MONTREAL CONVENTION, OR OTHER TREATY), AS WELL AS A CARRIER'S
        OWN CONTRACT OF CARRIAGE OR TARIFF PROVISIONS, MAY BE APPLICABLE TO
        THEIR ENTIRE JOURNEY, INCLUDING ANY PORTION ENTIRELY WITHIN THE
        COUNTRIES OF DEPARTURE AND DESTINATION. THE APPLICABLE TREATY GOVERNS
        AND MAY LIMIT THE LIABILITY OF CARRIERS TO PASSENGERS FOR DEATH OR
        PERSONAL INJURY, DESTRUCTION OR LOSS OF, OR DAMAGE TO, BAGGAGE, AND FOR
        DELAY OF PASSENGERS AND BAGGAGE.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        ADDITIONAL PROTECTION CAN USUALLY BE OBTAINED BY PURCHASING INSURANCE
        FROM A PRIVATE COMPANY. SUCH INSURANCE IS NOT AFFECTED BY ANY LIMITATION
        OF THE CARRIER'S LIABILITY UNDER AN INTERNATIONAL TREATY. FOR FURTHER
        INFORMATION PLEASE CONSULT YOUR AIRLINE OR INSURANCE COMPANY
        REPRESENTATIVE.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.4.{" "}
        <span className={styles.underline}>
          Notice of Contract Terms Incorporated by Reference
        </span>
        . Your contract of carriage with the carrier that provides you with
        carriage by air, whether international, domestic or a domestic portion
        of an international journey is subject to this notice; to any notice or
        receipt of the carrier; and to the carrier's individual terms and
        conditions (Conditions), related rules, regulations and policies
        (Regulations) and any applicable tariffs. If your carriage is by more
        than one carrier, different Conditions, Regulations and any applicable
        tariffs may apply for each carrier. The Conditions, Regulations and any
        applicable tariffs of each carrier are, by this notice, incorporated by
        reference into and made part of your contract of carriage. The
        Conditions may include, but are not restricted to: (a) Conditions and
        limits on the carrier's liability for the bodily injury or death of
        passengers; (b) Conditions and limits on the carrier's liability for the
        loss of, damage to or delay of goods and baggage, including fragile or
        perishable goods; (c) rules for declaring a higher value for baggage and
        for paying any supplementary fee that may apply; (d) application of the
        carrier's Conditions and limits of liability to the acts of the
        carrier's agents, servants and representatives, including any person
        providing either equipment or services to the carrier; (e) claims
        restrictions, including time limits by which passengers must file claims
        or bring actions against the carrier; (f) rules about reconfirmations or
        reservations; check in times; the use, duration and validity of air
        transportation services; and the carrier's right to refuse carriage; (g)
        rights of the carrier and limits on the carrier's liability for delay or
        failure to perform a service, including schedule changes, substitution
        of alternative carriers or aircraft and re-routing, and, when required
        by applicable law, the obligation of the carrier to notify passengers of
        the identity of the operating carrier or substituted aircraft; and (h)
        rights of the carrier to refuse carriage to passengers who fail to
        comply with applicable laws or who fail to present all necessary travel
        documents.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        You can obtain additional information on items (a) through (h) above and
        about your contract of carriage (including how to request a copy
        thereof) at places where transportation on the carrier is sold. Many
        carriers also have this information on their websites. When required by
        applicable law, you have the right to inspect the full text of your
        contract of carriage at the carrier's airport and sales offices, and
        upon request, to receive a copy by mail or other delivery service from
        each carrier free of charge. If a carrier sells air transportation
        services or checks baggage specifying carriage on another carrier, it
        does so only as agent for the other carrier.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.5.{" "}
        <span className={styles.underline}>Itinerary Changes/Cancelations</span>
        . Itinerary changes or cancelations you request (i.e., voluntary
        refunds), if permitted under the airline fare rules applicable to your
        ticket, may be subject to airline-imposed fees in addition to any fare
        differential at re-ticketing. Such fees vary by airline. Your airline
        may permit a portion of the fare paid for an unused nonrefundable ticket
        to be applied toward the purchase of future travel. For many airlines,
        tickets not changed or canceled prior to departure have no remaining
        value.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.6. <span className={styles.underline}>Tax Refunds</span>. In case of
        a no-show or cancellation of an air booking, you may be entitled to a
        refund of government taxes and fees included in the price of the ticket
        purchased. Where you are eligible for a refund, you can request such a
        refund from Hopper customer service, who will submit your request to the
        airline on your behalf.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.7. <span className={styles.underline}>EU Traveler Rights</span>. If
        you are traveling into or out of the European Union, or on an EU-based
        airline, you may have rights in the event that your flight is cancelled,
        delayed or you are denied boarding. Please consult Regulation (EC)
        261/2004 for more information.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.8. <span className={styles.underline}>Hazardous Materials</span>.
        United States federal law forbids the carriage of hazardous materials
        aboard aircraft in your luggage or on your person. A violation can
        result in five years' imprisonment and penalties of $250,000 or more (49
        U.S.C. 5124). Hazardous materials include explosives, compressed gases,
        flammable liquids and solids, oxidizers, poisons, corrosives and
        radioactive materials. Examples: Paints, lighter fluid, fireworks, tear
        gases, oxygen bottles, and radio-pharmaceuticals. There are special
        exceptions for small quantities (up to 70 ounces total) of medicinal and
        toilet articles carried in your luggage, spare lithium batteries and
        certain smoking materials carried on your person. Certain items are
        required to be carried with you onboard the aircraft. Spare lithium
        batteries and cigarette lighters must be removed from checked or
        gate-checked baggage and carried onboard the aircraft.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.9.{" "}
        <span className={styles.underline}>Airline Prohibited Practices</span>.
        The circumvention of an air carrier's rules, including practices such as
        back-to-back ticketing (booking two or more tickets with overlapping
        travel dates in order to circumvent minimum stay requirements) and
        hidden-city, point beyond or throwaway ticketing (booking tickets that
        include segments you do not intend to use in order to circumvent an air
        carrier's pricing structure), is prohibited by many air carriers. You
        agree that you will not use the Apps or Services to engage in these or
        similar activities if prohibited by an airline. CARRIERS RESERVE THE
        RIGHT TO REFUSE CARRIAGE TO ANY PERSON WHO HAS ACQUIRED A TICKET IN
        VIOLATION OF APPLICABLE LAW OR THE CARRIER'S TARIFFS, RULES OR
        REGULATIONS.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.10. <span className={styles.underline}>Required Information</span>.
        The Transportation Security Administration (TSA), part of the U.S.
        Department of Homeland Security, requires Hopper to collect information
        from you for purposes of watch list screening, pursuant to 49 U.S.C.
        Sec. 114, and the Intelligence Reform and Terrorism Prevention Act of
        2004. TSA may share information you provide with law enforcement or
        intelligence agencies or others under its published system of records
        notice. For more information, please see{" "}
        <a href="https://www.tsa.gov" target="_blank" rel="noreferrer">
          www.tsa.gov
        </a>
        .
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.11. <span className={styles.underline}>Data Transfer</span>.
        GOVERNMENTS MAY REQUIRE YOUR AIRLINE TO PROVIDE INFORMATION ON OR PERMIT
        ACCESS TO PASSENGER DATA, INCLUDING INFORMATION WE MAY BE REQUIRED TO
        COLLECT FROM YOU DURING THE RESERVATION PROCESS.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.12.{" "}
        <span className={styles.underline}>
          U.S. Notice - Overbooking of Flights
        </span>
        . Airline flights may be overbooked, and there is a slight chance that a
        seat will not be available on a flight for which a person has a
        confirmed reservation. If the flight is overbooked, no one will be
        denied a seat until airline personnel first ask for volunteers willing
        to give up their reservation in exchange for a payment of the airline's
        choosing. If there are not enough volunteers the airline will deny
        boarding to other persons in accordance with its particular boarding
        priority. With few exceptions persons denied boarding involuntarily are
        entitled to compensation. The complete rules for the payment of
        compensation and each airline's boarding priorities are available at all
        airport ticket counters and boarding locations.{" "}
        <span className={styles.bold}>
          Some airlines do not apply these consumer protections to travel from
          some foreign countries although other consumer protections may be
          available. Check with the airline or your travel agent.
        </span>
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.13. <span className={styles.underline}>Baggage</span>. Excess
        valuation may be declared on certain types of articles. Carriers may
        apply special rules for fragile, valuable, or perishable articles. Check
        with your carrier. Checked Baggage: Carriers may permit a free checked
        baggage allowance, which is set by the carrier and may differ by class,
        and/or route. Carriers may apply extra charges for checked baggage in
        excess of their permitted allowance. Check with your carrier. Cabin
        (Unchecked) Baggage: Carriers may permit a free cabin baggage allowance,
        which is set by the carrier and may differ by class, route, and/or
        aircraft type. It is recommended that cabin baggage be kept to a
        minimum. Check with your carrier.{" "}
        <span className={styles.bold}>
          If more than one carrier is providing the transportation for your
          journey, each carrier may apply different rules on baggage (both
          checked and cabin).
        </span>{" "}
        SPECIAL BAGGAGE LIABILITY LIMITATIONS FOR U.S. TRAVEL: For domestic
        travel wholly between U.S. points, federal rules require any limit on a
        carrier's baggage liability to be at least US $3,800 per passenger, or
        the amount currently mandated by 14 CFR § 254.5. If you are checking
        baggage on a multi-ticket itinerary, you must exit security, retrieve
        your baggage, and re-check your baggage prior to your next flight (if
        applicable). You are solely responsible for understanding your baggage
        responsibilities on multi-ticket itineraries.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.14. <span className={styles.underline}>Check-In Times</span>. The
        time shown on the itinerary/receipt is the departure time of the
        aircraft. Flight departure time is not the same as the time you must
        check-in or the time you must be available for boarding. Your carrier
        may refuse you carriage if you are late. Check-in times, as advised by
        your carrier, are the latest times at which passengers can be accepted
        for travel; boarding times, as advised by your carrier, are the latest
        times at which passengers must present themselves for boarding. Flights
        cannot be held for late-arriving passengers, and Hopper accepts no
        responsibility in such cases.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.15. <span className={styles.underline}>Aircraft Disinsection</span>.
        Some countries may require aircraft cabin insecticide treatment for
        in-bound foreign flights. A list of such countries is available at{" "}
        <a
          href="https://www.transportation.gov/airconsumer/spray"
          target="_blank"
          rel="noreferrer"
        >
          www.transportation.gov/airconsumer/spray
        </a>
        .
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.16. <span className={styles.underline}>Sequence of Travel</span>.
        Airlines require passengers to travel as per the itinerary shown on
        their ticket, from the place of departure via any agreed connecting
        points to their final destination, and vice versa. If all the segments
        are not used in sequence, the ticket as a whole will lose its validity
        in most circumstances.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.17. Hopper may receive commissions or other financial incentives
        from air carriers in connection with your air booking via the HTS
        Connect Services. In some cases, this incentive may be realized by
        Hopper as a markup included within the price that you pay to Hopper for
        such booking, and that price may differ from the price reflected on your
        carrier ticket or itinerary.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.18. Flights sold in the United Kingdom by Hopper are sold as agent
        of the carrier named on your ticket, and are not protected under the Air
        Travel Organisers' Licensing scheme.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.6. <span className={styles.underline}>Third-Party Sites</span>. The
        HTS Connect Services may contain links to third-party websites that are
        not operated by Hopper. These links are provided for reference only.
        Hopper is not responsible for the contents or the privacy or other
        practices of such sites. You should review the terms applicable to any
        third-party site that you choose to access.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        6. Hopper Entities; Sellers of Travel Registrations and Disclosures
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Except as otherwise set forth below, all bookings are made by Hopper
        (USA), Inc., a wholly owned-subsidiary of Hopper Inc. Hopper (USA), Inc.
        is a registered seller of travel in each of the U.S. states listed
        below:
      </Typography>
      <Typography variant="body2" className={styles.mb}></Typography>
      <Typography variant="body2" className={styles.center}>
        California*, registration number:{" "}
        <span className={styles.bold}>2141604-70</span>
      </Typography>
      <Typography variant="body2" className={styles.center}>
        Hawaii, registration number:{" "}
        <span className={styles.bold}>TAR 7387</span>
      </Typography>
      <Typography variant="body2" className={clsx(styles.mb, styles.center)}>
        Washington, registration number:{" "}
        <span className={styles.bold}>604516639</span>
      </Typography>
      <Typography variant="body2" className={clsx(styles.mb, styles.italic)}>
        *Registration as a seller of travel in California does not constitute
        the state's approval of Hopper. Hopper is not a participant in the
        Travel Consumer Restitution Fund.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        <span className={styles.underline}>
          Additional Disclosures for Residents of California
        </span>
        : Upon cancellation of the transportation or travel services, where the
        passenger is not at fault and has not cancelled in violation of any
        terms and conditions previously clearly and conspicuously disclosed and
        agreed to by the passenger, all sums paid to the seller of travel for
        services not provided will be promptly reimbursed to the passenger. This
        provision does not apply where the seller of travel has remitted the
        payment from customer to another registered wholesale seller of travel
        or a carrier, without obtaining a refund and such other provider fails
        to provide the agreed-upon transportation or service. In this situation,
        the initial seller of travel must provide the passenger with a written
        statement accompanied by bank records establishing the disbursement of
        the customer payment, and if disbursed to a registered wholesaler of
        travel, proof of current registration of that wholesaler.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        <span className={styles.underline}>
          Additional Disclosures for Residents of Washington State
        </span>
        : If transportation or other services are canceled by the seller of
        travel, all sums paid to the seller of travel for services not performed
        in accordance with the contract between the seller of travel and the
        purchaser will be refunded within 30 days of receiving the funds from
        the vendor with whom the services were arranged, or if the funds were
        not sent to the vendor, the funds shall be returned within 14 days after
        cancellation by the seller of travel to the purchaser unless the
        purchaser requests the seller of travel to apply the money to another
        travel product and/or date.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        <span className={styles.underline}>
          Additional Disclosures for Residents of the European Union
        </span>
        : For residents of the European Union, travel bookings via the HTS
        Connect Services are made by Hopper Travel (Ireland), Ltd., Mespil
        Business Centre, Mespil House, Sussex Road, Dublin 4, Ireland ("Hopper
        Ireland"). If, after selecting and paying for one travel service, you
        book additional travel services for your trip or holiday using the HTS
        Connect Services, you will NOT benefit from rights applying to packages
        under the Package Holidays and Travel Trade Act 1995 . Therefore, Hopper
        Ireland will not be responsible for the proper performance of the
        individual travel services. In case of problems please contact the
        relevant service provider. However, if you book any additional travel
        services during the same visit using the HTS Connect Services , the
        travel services will become part of a linked travel arrangement. In that
        case, Hopper Ireland has, as required by the Package Holidays and Travel
        Trade Act 1995 , protection in place to refund your payments to Hopper
        Ireland for services not performed because of Hopper Ireland's
        insolvency. Please note that this does not provide a refund in the event
        of the insolvency of the relevant service provider.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        More information on insolvency protection for Hopper Ireland is provided
        at this{" "}
        <a
          href="https://drive.google.com/file/d/13tZwUmxfmogu-bJreEVgBJZdYNWMP-WW/view"
          target="_blank"
          rel="noreferrer"
        >
          link
        </a>
        . Hopper Ireland has taken out insolvency protection with International
        Passenger Protection (IPP). Travellers may contact IPP or, where
        applicable, the Irish Aviation Authority (IAA), The Times Building,
        11-12 D'Olier Street, Dublin 2. D02 T449, email: traveltrade@iaa.ie, and
        telephone number: +353 1 603 1100. if the services are denied because of
        Hopper Ireland's insolvency. Note: This insolvency protection does not
        cover contracts with parties other than Hopper Ireland, which can be
        performed despite Hopper Ireland's insolvency.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        You can access the Package Holidays and Travel Trade Act 1995 at this
        link.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        For residents of Canada, bookings are made by Hopper (Canada), Inc., a
        wholly owned-subsidiary of Hopper Inc., organized under the laws of
        Alberta, Canada.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        For residents of Mexico, bookings are made by Hopper App Travel Mexico,
        S.A. de R.L., Av. Paseo de la Reforma #403, oficina 806, Cuauhtèmoc,
        Mexico.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        For residents of Colombia, bookings are made by Hopper Colombia SAS, Cra
        9 No. 116-06 Piso 17 - Complejo Tierra Firme, Bogotá, Colombia.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        For residents of Chile, bookings are made by Hopper Chile, Marchant
        Pereira 150, Of. 1002, Providencia, Santiago, Chile.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        For residents of the United Kingdom, bookings are made by Hopper UK
        Inc., Ltd., 58-60 Kensington Church St., Kensington, London W8 4DB, U.K.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Your payment may be processed by an affiliated Hopper entity other than
        the entity responsible for your booking.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Any customer service inquiries directed may be communicated to Hopper
        via Flair's customer service team 24 hours a day, 7 days a week by
        contacting Flair's support team via the information provided in your
        booking confirmation email.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        7. Intellectual Property
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        7.1. The HTS Connect Services accessed by you pursuant to these Terms,
        including without limitation all of the text, images, sound, marks,
        logos, compilations (meaning the collection, arrangement and assembly of
        information) and other content contained therein, and all software,
        source or object code, systems, data, and algorithms embodied in or
        supporting the HTS Connect Services, or otherwise used by Hopper to
        deliver the HTS Connect Services, and all intellectual property rights
        therein including without limitation patent, copyright, trademark rights
        (collectively, the{" "}
        <span className={styles.bold}>"Hopper Property"</span>), is the sole and
        exclusive property of Hopper and/or of third parties provided under
        license to, or used with permission by, Hopper, and is protected under
        United States, Canada, and international laws. Hopper reserves all
        rights in the Hopper Property, including without limitation copyright,
        trademark, patent, and trade secret rights, and no rights in such Hopper
        Property are granted except as expressly provided herein. You are hereby
        granted a limited, personal, non-exclusive, non-transferrable,
        non-sublicensable, and revocable right to access, download, and use the
        HTS ConnectServices solely for the purposes expressly authorized by
        these Terms, provided that you comply with these Terms.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        7.2. <span className={styles.underline}>Trademarks</span>. The marks
        "HOPPER," the bunny and running bunny images, among other marks, are
        registered or unregistered trademarks of Hopper, protected under the
        laws of the United States, Canada, and other countries around the world,
        and may not be used in connection with any service or products other
        than those provided by or expressly authorized by Hopper, in any manner
        that is likely to cause confusion among customers, or in any manner that
        disparages or discredits Hopper. The HTS Connect Services may also
        incorporate protected trademarks of third parties, and the use of such
        trademarks does not constitute an affiliation, endorsement or
        sponsorship of Hopper by any third party.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        7.3. <span className={styles.underline}>Third-Party Property</span>. The
        HTS Connect Services feature the trademarks, service marks, images,
        logos, and data of third parties, including those of Hopper's
        third-party travel service partners (
        <span className={styles.bold}>"Third-Party Property"</span>). Each
        third-party owner retains all rights in such Third-Party Property. Any
        use of such Third-Party Property provided or displayed in the HTS
        Connect Services, inures solely to the benefit of their respective
        owners. Hopper's use of such Third-Party Property does not imply any
        affiliation or relationship between Hopper and the third party nor any
        endorsement of Hopper or the HTS Connect Services by such third party.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        7.4. <span className={styles.underline}>Use of Research Data</span>.
        Your use of the research data found at media.hopper.com/research or that
        we make available by other means or at other physical or electronic
        locations, including without limitation any data, databases, reports,
        whitepapers, analysis, or compilations produced and/or distributed by
        Hopper (<span className={styles.bold}>"Research Data"</span>) is
        governed additionally by the terms of this Section. Hopper grants to you
        a non-exclusive, limited, non-transferrable, non-sublicensable, and
        revocable right to use, reproduce, display, and redistribute Research
        Data subject to your compliance with the following terms. You must cite
        Hopper as the source of any Research Data in any publication which you
        produce that includes, quotes, or relies on Research Data in any way. If
        your publication is in electronic format, you must link to Hopper.com
        and any existing back-links. Apart from crediting Hopper as the Source
        of the Research Data, you must not use any Hopper trademarks or any
        confusingly similar variants. You must not claim or imply that any
        Hopper entity endorses your use of the Research Data or the conclusions
        or derivative works which you might create. You acknowledge that the
        Research Data is being provided on a "AS IS" basis. Hopper does not
        warrant that data and publications will be error-free or complete, and
        cannot assume responsibility for your use of or reliance upon the
        Research Data. You acknowledge our right to alter, delete, or withdraw
        Research Data at any time without prior notice. You acknowledge that we
        maintain all right, title, and interest, including ownership, to all
        Research Data.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        7.5.{" "}
        <span className={styles.underline}>
          Digital Millennium Copyright Act
        </span>
        . Hopper respects the intellectual property of others. If you believe
        that your copyrighted work has been copied and is accessible in the HTS
        Connect Services in a way that constitutes copyright infringement,
        please provide Hopper with notification containing the following
        information required by the Digital Millennium Copyright Act, 17 U.S.C.
        §512 (<span className={styles.bold}>"DMCA"</span>) to Hopper, Attention:
        Legal Department, 265 Franklin Street, Suite 1702, Boston, MA 02110:
      </Typography>
      <span className={clsx(styles.gridCenter, styles.mb)}>
        <ul>
          <li>
            Information reasonably sufficient to permit us to contact the party
            alleging infringement, including an email address;
          </li>
          <li>
            Identification of the copyrighted work claimed to have been
            infringed, or, if multiple copyrighted works allegedly have been
            infringed, then a representative list of such copyrighted works;
          </li>
          <li>
            Identification of the material that is claimed to be infringing and
            that is to be removed or access to which is to be disabled, and
            information reasonably sufficient to permit us to locate the
            allegedly infringing material, e.g., the specific HTS Connect
            Service screenshot;
          </li>
          <li>
            A statement that the party alleging infringement has a good-faith
            belief that use of the copyrighted work in the manner complained of
            is not authorized by the copyright owner or its agent, or is not
            otherwise permitted under the law;
          </li>
          <li>
            A statement that the information in the notification is accurate,
            and under penalty of perjury, that the party alleging infringement
            is authorized to act on behalf of the copyright owner of the work
            that allegedly has been infringed; and
          </li>
          <li>
            A physical or electronic signature of a person authorized to act on
            behalf of the copyright owner certifying the statements and
            information provided.
          </li>
        </ul>
      </span>
      <Typography variant="body2" className={styles.mb}>
        UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT ONLINE MATERIAL IS
        INFRINGING, YOU MAY BE SUBJECT TO CRIMINAL PROSECUTION FOR PERJURY AND
        CIVIL PENALTIES, INCLUDING MONETARY DAMAGES, COURT COSTS, AND ATTORNEYS'
        FEES.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        7.6 <span className={styles.underline}>Feedback</span>. You have no
        obligation to provide Hopper with ideas, suggestions, recommendations,
        enhancement requests, testimonials, endorsements, product reviews or
        information regarding your experience using the HTS Connect Service (
        <span className={styles.bold}>"Feedback"</span>). However, if you submit
        Feedback to or about Hopper, either directly or indirectly via a
        third-party review site or a survey initiated by Hopper, then you grant
        to Hopper a non-revocable, non-exclusive, worldwide, royalty-free
        license that is sub-licensable and transferable, to make, use,
        reproduce, publicly display, distribute, sell, modify or publicly
        perform the Feedback in any manner without any obligation, royalty or
        restriction based on intellectual property rights or otherwise.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        8. Hopper Proprietary Products.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        The HTS Connect Services may offer a number of proprietary products (
        <span className={styles.bold}>"Products"</span>), which aim to increase
        the flexibility of purchased travel items. The purchase of each Product
        is subject to specified terms and conditions that will be made available
        to you prior to purchasing. These Products are NOT regulated financial
        products such as insurance, and the specified terms and conditions are
        not offers to insure or an insurance contract, and do not take the place
        of insurance obtained or obtainable by you. The HTS Connect Solution
        includes Hopper's Missed Connection Guarantee product. This product is
        subject to specified terms and conditions that can be located{" "}
        <a href={PATH_TERMS_MCP} target="_blank" rel="noreferrer">
          here
        </a>
        , and are incorporated herein by reference.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        9. Disclaimer of Warranties.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        9.1. The HTS Connect Services, including without limitation Hopper's
        price predictions and recommendations, are provided on a "AS IS" and "AS
        AVAILABLE" basis. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW,
        HOPPER DISCLAIMS, WITH RESPECT TO THE HTS CONNECT SERVICES, AND ALL
        CONTENT THEREIN, ALL WARRANTIES, EXPRESS OR IMPLIED, STATUTORY OR
        OTHERWISE, INCLUDING, BUT NOT LIMITED TO IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
        Hopper does not warrant that the HTS Connect Services will operate
        error-free or without downtime. Hopper may pause or interrupt the HTS
        ConnectServices at any time, and users should expect periodic downtime
        for updates. Hopper does not warrant or make any representations
        regarding the use or the results of the HTS Connect Services in terms of
        their correctness, accuracy, reliability, timeliness, completeness,
        currentness, or otherwise, including, without limitation, the safety,
        quality, and/or timing of any travel service purchased from
        third-parties via the HTS Connect Services. Hopper shall not be liable
        for delay, damage, or failure in performance resulting from causes
        beyond Hopper's reasonable control, including, without limitation,
        delays and other problems inherent in the use of the internet and
        electronic communications, force majeure, or the actions or omissions of
        third-parties. All content in the HTS Connect Services is provided for
        informational purposes only. Reliance on any information provided
        through the HTS Connect Services is solely at your own risk, including,
        without limitation, third-party travel services information, and Hopper
        predictions and recommendations. While unanticipated, errors and bugs
        may occur in the HTS Connect Services from time to time that may impact
        your booking. This may result in incorrect pricing or other other
        offers. Hopper reserves the right to correct any pricing errors in the
        HTS ConnectServices and/or pending reservations made under an incorrect
        price, and it is in Hopper's sole discretion to honor any incorrect
        pricing error.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        9.2. APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES,
        SO SOME OR ALL OF THESE ABOVE EXCLUSIONS MAY NOT FULLY APPLY TO YOU.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        10. Limitations of Liability
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, IN NO EVENT SHALL
        HOPPER BE LIABLE TO YOU FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE,
        EXEMPLARY, OR CONSEQUENTIAL DAMAGES, OR ANY LOSS OR DAMAGES WHATSOEVER
        (INCLUDING PERSONAL INJURY, LOSS OF DATA, REVENUE, PROFITS, REPUTATION,
        USE, OR OTHER ECONOMIC ADVANTAGE), EVEN IF HOPPER HAS BEEN PREVIOUSLY
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF A WARRANTY,
        CONTRACT, NEGLIGENCE, TORT, OR OTHER ACTION THAT IN ANY MANNER ARISES
        OUT OF OR IN CONNECTION WITH THE USE OF, INABILITY TO USE, OR
        PERFORMANCE OF THE HTS CONNECT SERVICES. HOPPER ASSUMES NO
        RESPONSIBILITY OR LIABILITY IN ANY MANNER ARISING OUT OF OR IN
        CONNECTION WITH ANY INFORMATION, CONTENT, PRODUCTS, AIR TRAVEL,, OR
        OTHER TRAVEL SERVICE OR MATERIAL AVAILABLE ON OR THROUGH THE HTS CONNECT
        SERVICES, OR VIA ANY THIRD PARTY WEBSITE PAGES OR ADDITIONAL WEBSITES
        LINKED TO THE HTS CONNECT SERVICES. THESE LIMITATIONS SHALL APPLY
        NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
        EXCEPT WHERE PROHIBITED BY LAW IN NO EVENT SHALL HOPPER'S TOTAL
        LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER
        IN WARRANTY, CONTRACT, OR TORT EXCEED (A) THE AMOUNT PAID BY YOU TO
        HOPPER OR A THIRD-PARTY TRAVEL SERVICE PROVIDER VIA THE HTS CONNECT
        SERVICES, IF ANY, OR (B) $1,000 (WHICHEVER IS GREATER). IN NO EVENT
        SHALL HOPPER BE LIABLE FOR ANY FAILURE TO COMPLY WITH THESE TERMS, OR
        FAILURE TO PROVIDE THE HTS CONNECT SERVICES, TO THE EXTENT THAT SUCH
        FAILURE ARISES FROM FACTORS OUTSIDE HOPPER'S REASONABLE CONTROL.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        10.2. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
        LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH
        JURISDICTIONS LIABILITY IS LIMITED TO THE GREATEST EXTENT PERMITTED BY
        LAW.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        10.3. YOU AGREE THAT THE WARRANTY DISCLAIMERS AND LIMITATIONS OF
        LIABILITY IN THIS SECTION ARE MATERIAL, BARGAINED-FOR BASES OF THESE
        TERMS, AND THAT THEY HAVE BEEN TAKEN INTO ACCOUNT IN DETERMINING THE
        CONSIDERATION TO BE GIVEN BY EACH PARTY HEREUNDER AND IN THE DECISION BY
        EACH PARTY TO CONTRACT WITH THE OTHER. YOU AGREE THAT THE WARRANTY
        DISCLAIMERS AND LIMITATIONS OF LIABILITY IN THESE TERMS OF SERVICE ARE
        FAIR AND REASONABLE. EXCEPT AS MAY BE OTHERWISE PROVIDED FOR IN THIS
        SECTION, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE HTS
        CONNECT SERVICES.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        10.4. IMPORTANT NOTE TO NEW JERSEY CONSUMERS: IF YOU ARE A CONSUMER
        RESIDING IN NEW JERSEY, THE FOLLOWING PROVISIONS OF THESE TERMS OF USE
        DO NOT APPLY TO YOU (AND DO NOT LIMIT ANY RIGHTS THAT YOU MAY HAVE) TO
        THE EXTENT THAT THEY ARE UNENFORCEABLE UNDER NEW JERSEY LAW: (A) THE
        DISCLAIMER OF LIABILITY FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE,
        EXEMPLARY, OR CONSEQUENTIAL DAMAGES OF ANY KIND (FOR EXAMPLE, TO THE
        EXTENT UNENFORCEABLE UNDER THE NEW JERSEY PUNITIVE DAMAGES ACT, NEW
        JERSEY PRODUCTS LIABILITY ACT, NEW JERSEY UNIFORM COMMERCIAL CODE, AND
        NEW JERSEY CONSUMER FRAUD ACT); (B) THE LIMITATION ON LIABILITY FOR LOST
        PROFITS OR LOSS OR MISUSE OF ANY DATA (FOR EXAMPLE, TO THE EXTENT
        UNENFORCEABLE UNDER THE NEW JERSEY IDENTITY THEFT PROTECTION ACT AND NEW
        JERSEY CONSUMER FRAUD ACT); (C) APPLICATION OF THE LIMITATIONS OF
        LIABILITY TO THE RECOVERY OF DAMAGES THAT ARISE UNDER CONTRACT AND TORT,
        INCLUDING, WITHOUT LIMITATION, NEGLIGENCE, STRICT LIABILITY, OR ANY
        OTHER THEORY (FOR EXAMPLE, TO THE EXTENT SUCH DAMAGES ARE RECOVERABLE BY
        A CONSUMER UNDER NEW JERSEY LAW, INCLUDING, WITHOUT LIMITATION, THE NEW
        JERSEY PRODUCTS LIABILITY ACT); AND (D) THE MASSACHUSETTS GOVERNING LAW
        PROVISION (FOR EXAMPLE, TO THE EXTENT THAT YOUR RIGHTS AS A CONSUMER
        RESIDING IN NEW JERSEY ARE REQUIRED TO BE GOVERNED BY NEW JERSEY LAW).
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        10.5. With respect to any unauthorized, fraudulent transaction on your
        behalf conducted while using the HTS Connect Solution, absent any
        violation of these Terms by you, your right to reimbursement for any
        direct losses (i.e., losses not refunded by your Payment Instrument
        issuing entity) as set forth herein shall be your sole and exclusive
        right and remedy for any loss or damages incurred by you arising out of,
        in connection with, or relating to any fraudulent or unauthorized use of
        the HTS Connect Solution on your behalf, and Hopper may condition its
        payment of any fraud or unauthorized use reimbursement upon your
        releasing Hopper from any further claims with respect thereto.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        10.6. An action or proceeding relating to any claim arising out of the
        HTS Connect Services must commence pursuant to Section 13.1 within the
        shorter of the applicable statute of limitations or one year after the
        cause of action has accrued, or it shall be waived.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        11. Indemnification
      </Typography>
      <Typography variant="body2" className={styles.mb}></Typography>
      <Typography variant="body2" className={styles.mb}>
        11.1. If you use the HTS Connect Services for commercial purposes and/or
        to make travel bookings on behalf of any third person or party, you
        agree to indemnify and hold harmless Hopper and its officers, directors,
        employees, agents, and affiliated companies against any claims, suits,
        proceedings, disputes, demands, liabilities, damages, losses, costs and
        expenses, including reasonable legal and accounting fees, caused by,
        arising out of, or related to your use of the HTS ConnectServices,
        breach of these Terms, or the use of any products or services purchased
        via the HTS Connect Services.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        12. Dispute Resolution; Arbitration; Waiver Of Rights
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        12.1.{" "}
        <span className={styles.underline}>
          ARBITRATION AND CLASS ACTION WAIVER
        </span>
        . You agree that all claims, disputes, or disagreements that may arise
        out of the interpretation of or performance under these Terms, any
        payments to Hopper, or otherwise related in any way to your use of the
        HTS Connect Services and/or the content therein, shall be submitted
        exclusively to binding arbitration, except that each party retains: (i)
        the right to bring an individual action in small claims court for
        disputes and actions within the scope of such court's jurisdiction; and
        (ii) the right to seek injunctive or other equitable relief in a court
        of competent jurisdiction to prevent the actual or threatened
        infringement, misappropriation, or violation of a party's copyrights,
        trademarks, trade secrets, patents, or other intellectual property
        rights. Arbitration means that an arbitrator and not a judge or jury
        will decide the claim. Rights to prehearing exchange of information and
        appeals may also be limited in arbitration. YOU ACKNOWLEDGE AND AGREE
        THAT YOU AND HOPPER ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY. You
        further acknowledge and agree that you waive your right to participate
        as a plaintiff or class member in any purported class action or
        representative proceeding. Further, unless both you and Hopper otherwise
        agree in writing, the arbitrator may not consolidate more than one
        person's claims, and may not otherwise preside over any form of any
        class or representative proceeding. If this class action waiver is held
        unenforceable, then the entirety of this "Dispute Resolution" section
        will be deemed void. Except as provided in the preceding sentence, this
        "Dispute Resolution" section will survive any termination of these
        Terms.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        12.2. <span className={styles.underline}>Arbitration Rules</span>. Any
        arbitration will be administered by the American Arbitration Association
        ("AAA"). Except as modified by this "Dispute Resolution" provision, the
        AAA will administer the arbitration in accordance with either (A) the
        Commercial Arbitration Rules then in effect, or (B) the Consumer
        Arbitration Rules then in effect if the matter involves a "consumer
        agreement" as defined by Consumer Arbitration Rule R-1 (together, the
        "Applicable AAA Rules"). (The Applicable AAA Rules are available at{" "}
        <a href="https://www.adr.org/Rules" target="_blank" rel="noreferrer">
          https://www.adr.org/Rules
        </a>{" "}
        or by calling the AAA at 1-800-778-7879). The Federal Arbitration Act
        will govern the interpretation and enforcement of this entire "Dispute
        Resolution" provision.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        12.3. <span className={styles.underline}>Arbitration Process</span>. A
        party who desires to initiate arbitration must provide the other party
        with a written Demand for Arbitration as specified in the Applicable AAA
        Rules. (The AAA provides applicable forms for Demands for Arbitration)
        at{" "}
        <a
          href="https://www.adr.org/sites/default/files/Demand_for_Arbitration_0.pdf"
          target="_blank"
          rel="noreferrer"
        >
          https://www.adr.org/sites/default/files/Demand_for_Arbitration_0.pdf
        </a>{" "}
        (Commercial Arbitration Rules) and{" "}
        <a
          href="https://www.adr.org/sites/default/files/Consumer_Demand_for_Arbitration_Form_1.pdf"
          target="_blank"
          rel="noreferrer"
        >
          https://www.adr.org/sites/default/files/Consumer_Demand_for_Arbitration_Form_1.pdf
        </a>{" "}
        (Consumer Arbitration Rules), and a separate affidavit for waiver of
        fees for California residents only is available at{" "}
        <a
          href="https://www.adr.org/sites/default/files/AAA%20Affidavit%20for%20Waiver%20of%20Fees%20Notice%20California%20Consumers.pdf"
          target="_blank"
          rel="noreferrer"
        >
          https://www.adr.org/sites/default/files/AAA%20Affidavit%20for%20Waiver%20of%20Fees%20Notice%20California%20Consumers.pdf.
        </a>{" "}
        The arbitrator will be either a retired judge or an attorney licensed to
        practice law in the county in which you reside and will be selected in
        accordance with the Applicable AAA Rules. If the parties are unable to
        agree upon an arbitrator within fourteen (14) days of the AAA's notice
        to the parties of its receipt of the Demand for Arbitration, then the
        AAA will appoint the arbitrator in accordance with the AAA Rules.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        12.4.{" "}
        <span className={styles.underline}>
          Arbitration Location and Procedure
        </span>
        . Unless you and Hopper otherwise agree, the arbitration will be
        conducted in the county where you reside. If your claim does not exceed
        $10,000, then the arbitration will be conducted solely on the basis of
        documents you and Hopper submit to the arbitrator, unless you request a
        hearing or the arbitrator determines that a hearing is necessary. If
        your claim exceeds $10,000, your right to a hearing will be determined
        by the Applicable AAA Rules. Subject to the Applicable AAA Rules, the
        arbitrator will have the discretion to direct a reasonable exchange of
        information by the parties, consistent with the expedited nature of the
        arbitration.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        12.5. <span className={styles.underline}>Arbitrator's Decision</span>.
        The arbitrator will render an award within the time frame specified in
        the Applicable AAA Rules. The arbitrator's decision will include the
        essential findings and conclusions upon which the arbitrator based the
        award. Judgment on the arbitration award may be entered in any court
        having jurisdiction thereof. The arbitrator's award of damages and/or
        other relief must be consistent with the terms of the "Limitations of
        Liability" section above as to the types and the amounts of damages or
        other relief for which a party may be held liable. The arbitrator may
        award declaratory or injunctive relief only in favor of the claimant and
        only to the extent necessary to provide the relief warranted by the
        claimant's individual claim. If the arbitrator determines that you are
        the prevailing party in the arbitration, and only if authorized under
        applicable law, the arbitrator may award reasonable attorneys' fees and
        expenses. Hopper will not seek, and hereby waives, all rights Hopper may
        have under applicable law to recover attorneys' fees and expenses if
        Hopper prevails in arbitration unless the arbitrator finds that either
        the substance of your claim or the relief sought in your Demand for
        Arbitration was frivolous or was brought for an improper purpose (as
        measured by the standards set forth in Federal Rule of Civil Procedure
        11(b)).
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        12.6. <span className={styles.underline}>Fees</span>. Your
        responsibility to pay any AAA filing, administrative and arbitrator fees
        will be solely as set forth in the Applicable AAA Rules. However, if
        your claim for damages does not exceed $25,000, Hopper will pay all such
        fees unless the arbitrator finds that either the substance of your claim
        or the relief sought in your Demand for Arbitration was frivolous or was
        brought for an improper purpose (as measured by the standards set forth
        in Federal Rule of Civil Procedure 11(b)).
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        12.7. <span className={styles.underline}>Changes</span>. Hopper reserves
        the right to change this "Dispute Resolution" section, but any such
        changes will not apply to disputes arising before the effective date of
        such amendment. Notwithstanding the provisions of the
        modification-related provisions above, if Hopper changes this "Dispute
        Resolution" section after the date you first accepted the Terms(or
        accepted any subsequent changes to these Terms), you agree that your
        continued use of the HTS Connect Services after such change will be
        deemed acceptance of those changes. If you do not agree to such change,
        you may reject any such change by providing Hopper written notice of
        such rejection by mail or hand delivery to: Hopper, Attn: Legal
        Department, 265 Franklin Street, Suite 1702, Boston, MA 02110 or by
        email from the email address associated with your account to:
        legal@hopper.com, within 30 days of the date such change became
        effective, as indicated herein. In order to be effective, the notice
        must include your full name and clearly indicate your intent to reject
        changes to this "Dispute Resolution" section. By rejecting changes, you
        are agreeing that you will arbitrate any dispute between you and Hopper
        in accordance with the provisions of this "Dispute Resolution" section
        as of the date you first accepted these Terms (or accepted any
        subsequent changes to these Terms, as applicable).
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        13. Additional Terms
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        13.1. These Terms constitute the entire agreement of the parties as to
        its subject matter, and supersede all prior written and oral
        representations and discussions between the parties. The Terms shall be
        governed by the laws of the Commonwealth of Massachusetts without
        reference to principles of conflicts of laws, except as otherwise
        provided above in Section 13. No delay or omission by Hopper in
        exercising any of its rights occurring upon any noncompliance or default
        by you with respect to these Terms will impair any such right or be
        construed to be a waiver thereof, and a waiver by Hopper of any of the
        covenants, conditions or agreements to be performed by you will not be
        construed to be a waiver of any succeeding breach thereof or of any
        other covenant, condition or agreement hereof contained. You may not
        assign any of your rights arising under these Terms without Hopper's
        written consent. Except as provided in Section 13 above (Dispute
        Resolution), if any provision of these Terms is found by a court of
        competent jurisdiction to be invalid or unenforceable, then these Terms
        will remain in full force and effect and will be reformed to be valid
        and enforceable while reflecting the intent of the parties to the
        greatest extent permitted by law. The provisions of these Terms are
        entered into for the benefit of Hopper and you and there shall be no
        third-party beneficiaries to these Terms. In the event of a conflict
        between these Terms and any other Hopper agreement, terms, or policy
        provided to you, directly or indirectly, these Terms shall prevail.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        14. Language
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        14.1 You and Hopper have agreed that these Terms and all related
        documents be drawn up in the English language.{" "}
        <span className={styles.bold}>
          Les parties aux présentes reconnaissent avoir convenu que la présente
          entente et les documents connexes soient rédigés en langue anglaise.
        </span>
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        15. Updates and Changes
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        15.1 Hopper may make changes or updates to these Terms at any time, at
        its discretion. You agree that if you do not agree to any such change,
        you shall not book via the HTS Connect Services. Your failure to do so,
        or continued use of any HTS Connect Service, shall constitute agreement
        to these Terms as so amended, except as provided for under Section 13.7.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Effective Date of these Terms of Service: Version 1.1; August 16, 2024
      </Typography>
    </>
  );
};
