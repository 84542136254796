import { useI18nContext } from "@hopper-b2b/i18n";
import { TranslationLanguage } from "@hopper-b2b/types";

import { EnglishTermsAndConditions } from "./EnglishTermsAndConditions";
import { FrenchTermsAndConditions } from "./FrenchTermsAndConditions";
import styles from "./styles.module.scss";

const termsAndConditionsLangMapping = {
  [TranslationLanguage["en-US"]]: <EnglishTermsAndConditions />,
  [TranslationLanguage.fr]: <FrenchTermsAndConditions />,
};

export const TermsAndConditions = () => {
  const { language } = useI18nContext();

  return (
    <div className={styles.termsandConditionsContainer}>
      {termsAndConditionsLangMapping[language]}
    </div>
  );
};
