import { useI18nContext } from "@hopper-b2b/i18n";
import { TranslationLanguage } from "@hopper-b2b/types";

import { EnglishMissedConnectionsTermsAndConditions } from "./EnglishMissedConnectionsTermsAndConditions";
import { FrenchMissedConnectionsTermsAndConditions } from "./FrenchMissedConnectionsTermsAndConditions";
import styles from "./styles.module.scss";

const termsAndConditionsLangMapping = {
  [TranslationLanguage["en-US"]]: (
    <EnglishMissedConnectionsTermsAndConditions />
  ),
  [TranslationLanguage.fr]: <FrenchMissedConnectionsTermsAndConditions />,
};

export const MissedConnectionsTermsAndConditions = () => {
  const { language } = useI18nContext();

  return (
    <div className={styles.termsandConditionsContainer}>
      {termsAndConditionsLangMapping[language]}
    </div>
  );
};
