import { Typography } from "@material-ui/core";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { PATH_TERMS_HTS } from "../../utils/constants";

export const FrenchMissedConnectionsTermsAndConditions = () => (
  <>
    <Typography
      variant="h3"
      className={clsx(styles.bold, styles.underline, styles.mb, styles.center)}
    >
      Conditions générales d'utilisation
    </Typography>

    <Typography variant="body2" className={clsx(styles.bold, styles.mb)}>
      Garantie de correspondance manquée HTS Connect de Hopper - Clients Flair
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Le service de garantie de correspondance manquée de Hopper Technology
      Services (<span className={styles.bold}>« HTS »</span> ou{" "}
      <span className={styles.bold}>« Hopper »</span>) pour les clients Flair
      (le <span className={styles.bold}>« Service »</span>, tel qu'utilisé dans
      cette sous-section), est inclus sans frais supplémentaires sur certains
      itinéraires achetés reliant plusieurs vols de Flair à des transporteurs
      non affiliés (
      <span className={styles.bold}>« Réservation HTS Connect »</span>). Le
      Service offre à l'acheteur un service de nouvelle réservation gratuit dans
      le cas où il manque son vol de correspondance pour une raison indépendante
      de sa volonté.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Veuillez noter que le Service s'applique uniquement aux réservations HTS
      Connect et ne peut pas être acheté en tant que service unique. Les
      réservations HTS Connect et le Service sont soumis aux Conditions
      générales d'utilisation de HTS Connect que vous pouvez trouver{" "}
      <a href={PATH_TERMS_HTS} target="_blank" rel="noreferrer">
        ici
      </a>
      .
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Le Service est soumis aux conditions, prérequis et restrictions suivants :
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      1. <span className={styles.bold}>Fonctionnement</span>. Le Service
      s'appliquera à l'acheteur de Réservations HTS Connect et aux autres
      passagers de la même réservation HTS Connect. Vous ne pouvez utiliser le
      Service que si vous avez une réservation HTS Connect et manquez votre vol
      de correspondance pour une raison indépendante de votre volonté (par
      exemple, le vol de correspondance est annulé par la compagnie aérienne ou
      part sans que vous soyez à bord parce que votre vol antérieur accusait un
      retard) (<span className={styles.bold}>« Perturbation admissible »</span>
      ). Si vous rencontrez une Perturbation admissible, vous pouvez utiliser le
      Service en contactant le service à la clientèle de Flair par
      l'intermédiaire du numéro figurant dans l'e-mail de confirmation de votre
      réservation , qui vous mettra ensuite en relation avec Hopper
      (collectivement, le{" "}
      <span className={styles.bold}>« Service à la clientèle »</span>) pour{" "}
      effectuer une nouvelle réservation pour vous-même et d'autres passagers
      admissibles sur un nouveau vol de correspondance vers votre destination
      sans frais, jusqu'à un montant maximum de 2 000,00 $ US pour le nouveau
      vol (taxes et frais inclus) par passager ({" "}
      <span className={styles.bold}>« Plafond du service »</span>), et sous
      réserve des restrictions supplémentaires pour les nouveaux vols expliquées
      au paragraphe 6 ci-dessous. Si vous choisissez d'utiliser le Service à la
      suite d'une Perturbation admissible et que les coûts par passager du
      nouveau vol de correspondance dépassent le plafond de service, vous devrez
      payer les coûts par passager supérieurs au plafond de service au moment de
      la nouvelle réservation. Si aucune décision d'utiliser le Service n'est
      prise, le Service expirera vingt-quatre (24) heures après la Perturbation
      admissible.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      2. <span className={styles.bold}>Perturbation admissible</span>. Pour être
      admissible au Service, vous devez avoir une réservation HTS Connect et
      avoir manqué votre vol de correspondance du à une raison admissible
      indépendante de votre volonté. Les raisons admissibles comprennent, mais
      sans s'y limiter, les retards ou les annulations liés à des problèmes de
      compagnie aérienne, de problèmes douaniers ou des conditions
      météorologiques. Hopper se réserve le droit, à sa seule discrétion, de
      décider si un vol de correspondance a été manqué pour une raison
      admissible.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      3. <span className={styles.bold}>Régimes de droits des passagers</span>.{" "}
      Certaines juridictions, y compris le Canada et l'Union européenne, ont
      adopté des lois et/ou des règlements obligeant les compagnies aériennes à
      indemniser les passagers avec une réservation en cas de certaines
      perturbations du transport aérien (un{" "}
      <span className={styles.bold}>« Régime de droits des passagers »</span>).
      Dans le cas d'une telle perturbation, vous pourriez être admissible à une
      indemnisation en vertu du Régime de droits des passagers, que vous
      choisissiez ou non d'utiliser le Service.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      4.{" "}
      <span className={styles.bold}>
        Option de nouvelle réservation raisonnable
      </span>
      . Vous ne pourrez pas utiliser le Service dans le cas où la compagnie
      aérienne annule votre vol de correspondance mais vous offre une option de
      nouvelle réservation raisonnable. Une option de nouvelle réservation
      raisonnable signifie un nouveau vol de correspondance avec départ : (i)
      dans l'heure suivant l'heure de départ prévue de votre vol de
      correspondance manqué, ou (ii) si votre vol de correspondance manqué était
      prévu de partir après 20 h, heure locale, avant 9 h, heure locale, le
      lendemain. Hopper se réserve le droit, à sa seule discrétion, de décider
      si une nouvelle réservation proposée par une compagnie aérienne constitue
      une « option de nouvelle réservation raisonnable ».
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.{" "}
      <span className={styles.bold}>
        Informations importantes concernant la réservation d'un nouveau vol de
        correspondance
      </span>
      . Si vous choisissez d'utiliser le Service après une Perturbation
      admissible, vous pouvez réserver un nouveau vol de correspondance en
      contactant le Service à la clientèle sans frais (sous réserve de la
      Section 1), conformément aux conditions suivantes :
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      (i) le nouveau vol de correspondance doit avoir la même destination.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      (ii) le nouveau vol de correspondance peut être sur n'importe quelle
      compagnie aérienne disponible fournie par le service à la clientèle, mais
      doit être dans la même classe tarifaire ou une classe tarifaire inférieure
      à celle de la réservation initiale; et vous devez être en mesure de vous
      rendre à la porte d'embarquement du nouveau vol de correspondance à temps
      pour embarquer. Hopper n'est pas responsable de s'assurer que vous arrivez
      à la porte à l'heure et ne paiera aucun coût supplémentaire si vous
      manquez votre nouveau vol de correspondance.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      (iii) si vous avez des bagages enregistrés, vous devez vous coordonner
      avec la compagnie aérienne d'origine pour récupérer vos bagages avant ou
      après votre nouveau vol de correspondance vers votre destination. Hopper
      n'est pas responsable de la récupération des bagages et ne couvrira aucun
      frais de bagages.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      (iv) les vols proposés pour une nouvelle réservation via le Service ne
      seront réservés que le jour même ou le jour suivant.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      6. <span className={styles.bold}>À qui s'adresse le Service</span>. Hopper
      fournit le Service à tous les passagers inclus dans l'itinéraire de voyage
      au moment de la Réservation HTS Connect, jusqu'à concurrence de la valeur
      maximale du plafond de service par passager. S'il y a plus d'un passager
      sur la réservation, le passager qui a effectué la réservation déterminera
      comment utiliser le Service, qui s'appliquera ensuite à tout autre
      passager inclus dans la réservation.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      7. <span className={styles.bold}>Autres restrictions</span>. Hopper ne
      sera pas responsable envers vous des frais et coûts que vous avez engagés
      (par exemple, les frais de bagages ou de surclassement) autres que les
      coûts du nouveau vol de correspondance jusqu'au plafond de service par
      passager. Le Service est valable pour UN vol de correspondance manqué par
      passager dans le cadre du voyage pour lequel le Service est associé. Le
      Service ne sera pas reporté sur de futures réservations effectuées avec
      HTS. Dans le cas où vous modifiez ou annulez volontairement votre
      Réservation HTS Connect avant votre date de départ, le Service ne sera
      plus valide. Si vous annulez votre vol pendant la période de grâce permise
      par votre compagnie aérienne (appelée la « fenêtre d'annulation ») et
      recevez un remboursement de la compagnie aérienne, le Service sera annulé.
      Le Service est non transférable.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      8. <span className={styles.bold}>Pas une assurance</span>. Le Service est
      une garantie que Hopper effectuera une nouvelle réservation pour vous sur
      un nouveau vol de correspondance vers votre destination d'origine si vous
      avez une Réservation HTS Connect et manquez votre vol de correspondance
      pour une raison indépendante de votre volonté, sous réserve des
      limitations énoncées dans les présentes. HTS offre le Service à ses
      clients afin de leur offrir une expérience plus fluide et plus flexible
      des Réservations HTS Connect. Les Conditions de service ne sont pas
      censées constituer une proposition d'assurance, ne constituent pas une
      assurance ou une police d'assurance et ne remplacent en aucun cas une
      assurance contractée ou pouvant être contractée par vous.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Date d'entrée en vigueur des présentes Conditions générales d'utilisation
      : version 1.1; Août 16, 2024
    </Typography>
  </>
);
