import { Typography } from "@material-ui/core";
import clsx from "clsx";

import styles from "./styles.module.scss";
import { PATH_TERMS_MCP } from "../../utils/constants";

export const FrenchTermsAndConditions = () => {
  return (
    <>
      <Typography
        variant="h3"
        className={clsx(
          styles.bold,
          styles.underline,
          styles.mb,
          styles.center
        )}
      >
        Conditions générales d'utilisation
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Hopper Inc. et ses sociétés affiliées ({" "}
        <span className={styles.bold}>« Hopper »</span>) fournissent les
        services Hopper Technology Solutions Connect ({" "}
        <span className={styles.bold}>« Services HTS Connect »</span>) au nom
        des utilisateurs de Flair Airlines Ltd. ({" "}
        <span className={styles.bold}>« Flair »</span>) pour une utilisation par
        vous (<span className={styles.bold}>« Utilisateur »</span>). Les
        services HTS Connect comprennent, mais sans s'y limiter, l'utilisation
        de données et d'algorithmes prédictifs propriétaires de Hopper pour
        rechercher et recevoir des notifications sur les vols et d'autres
        services de voyage; la réservation et le paiement de ces services de
        voyage; l'achat de produits Hopper et/ou de tiers; l'utilisation de
        rapports publiés par Hopper ({" "}
        <span className={styles.bold}>« Données de recherche de Hopper »</span>{" "}
        ); et d'autres services connexes. Hopper vous donne accès aux Services
        HTS Connect sous réserve de votre acceptation de toutes les Conditions
        générales suivantes (les{" "}
        <span className={styles.bold}>« Conditions »</span>), qui constituent un
        accord juridique contraignant entre vous et Hopper. En accédant, en
        téléchargeant, en copiant et/ou en utilisant les Services HTS Connect,
        vous acceptez d'être lié par ces Conditions.
      </Typography>
      <Typography className={styles.mb}>
        VEUILLEZ CONSULTER ATTENTIVEMENT LA PARTIE SUR LE{" "}
        <span className={styles.bold}>« RÈGLEMENT DES LITIGES »</span> DE CES
        CONDITIONS (SECTION 13) PUISQU'ON VOUS DEMANDE DE RÉGLER TOUT DIFFÉREND
        FUTUR AVEC HOPPER VIA UN ARBITRAGE INDIVIDUEL CONTRAIGNANT. VOUS
        RECONNAISSEZ ET ACCEPTEZ QUE VOUS RENONCEZ À VOTRE DROIT À INTENTER UN
        PROCÈS DEVANT JURY ET À PARTICIPER EN TANT QUE PLAIGNANT OU CODEMANDEUR
        À UN RECOURS COLLECTIF OU À UNE AUTRE PROCÉDURE INTENTÉE CONTRE HOPPER.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Vous devriez également lire l'
        <a
          href="https://hopper.com/legal/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          AVIS DE CONFIDENTIALITÉ
        </a>{" "}
        distinct de Hopper qui explique comment Hopper gère et utilise les
        données que Hopper recueille lorsque vous utilisez les Services HTS
        Connect, et qui est incorporé en référence dans les présentes
        Conditions. En acceptant ces Conditions, vous consentez aussi à la
        collecte et à l'utilisation des données tel qu'indiqué dans l'Avis de
        confidentialité de Hopper.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Si vous avez besoin de contacter le Service à la clientèle de Hopper,
        vous devez d'abord contacter le service à la clientèle de Flair, qui
        vous redirigera vers Hopper. Vous pouvez contacter le service clientèle
        de Flair en utilisant les informations fournies dans l'e-mail de
        confirmation de votre réservation.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        En plus de ces Conditions, Hopper propose des produits et des services
        individuels à l'achat pouvant être soumis à des conditions générales
        supplémentaires qui seront indiquées avant et après l'achat. Les
        services de voyage fournis par des tiers sont soumis à des conditions de
        tiers. Avant d'effectuer un achat ou une réservation, veuillez lire
        attentivement toutes les conditions supplémentaires.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        La Solution HTS Connect est protégée par reCAPTCHA Enterprise, un
        service tiers offert par Google. La{" "}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Politique de confidentialité
        </a>{" "}
        et les{" "}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Conditions générales d'utilisation
        </a>{" "}
        de Google s'appliquent à reCAPTCHA Enterprise. En utilisant le site Web
        de Hopper, vous consentez à l'utilisation de ce service et aux
        conditions de Google.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        1. Utilisation des Applications et des Services, restrictions
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        1.1. <span className={styles.underline}>Utilisation acceptable</span>.
        Vous ne pouvez utiliser les Services HTS Connect qu'à des fins
        personnelles légales, et conformément aux présentes Conditions, afin de
        rechercher et d'effectuer des réservations de voyage et/ou des achats
        connexes pour vous-même, ou de manière non commerciale au nom d'une
        personne vous ayant autorisé à le faire. Hopper peut, à sa seule
        discrétion, résilier votre droit d'utilisation des Services HTS Connect
        en tout temps, et peut entreprendre tous les recours juridiques
        disponibles en cas de violations réelles ou présumées des présentes
        Conditions, y compris l'annulation de réservations effectuées en
        violation des présentes Conditions. Tout délai de la part de Hopper à
        prendre de telles mesures ne constitue pas une renonciation de Hopper à
        faire appliquer les présentes Conditions. En mettant les Services HTS
        Connect à votre disposition, Hopper ne consent pas à agir en tant
        qu'agent ou fiduciaire.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        1.2.{" "}
        <span className={styles.underline}>Restrictions d'utilisation</span>.
        Les Services HTS Connect ne sont pas destinés à être utilisés par
        quiconque, et vous n'êtes pas autorisé à utiliser les Services HTS
        Connect si vous êtes, âgé de moins de 16 ans ou d'âge mineur dans la
        juridiction dans laquelle vous résidez. Mis à part les informations
        requises pour effectuer une réservation, Hopper ne recueille pas
        intentionnellement d'informations personnelles identifiables auprès de
        mineurs âgés de moins de 16 ans. Si un mineur de moins de 16 ans nous a
        fourni des informations personnelles sans le consentement d'un parent ou
        d'un tuteur, le parent ou le tuteur peut nous contacter en nous envoyant
        un courriel à <a href="mailto:privacy@hopper.com">privacy@hopper.com</a>
        . Nous supprimerons ensuite les informations et nous désabonnerons le
        mineur de toutes nos listes de marketing électronique.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Vous ne pouvez pas utiliser les Services HTS Connect si vous êtes
        identifié sur une liste de parties interdites, sanctionnées ou
        restreintes du gouvernement américain ou canadien, ou si vous vous
        trouvez dans un pays soumis à un embargo du gouvernement américain ou
        canadien, ou ayant été désigné par le gouvernement américain ou canadien
        comme pays « soutenant le terrorisme ».
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Même si vous pouvez faire des copies des données liées à vos
        réservations de voyage, vous ne pouvez pas tenter d'accéder, de copier,
        de décompiler, de désassembler, de rétroconcevoir, de distribuer, de
        sous-licencier, de publier, d'interrompre, de détruire ou d'interférer
        avec le code source, les bibliothèques, les bases de données, les
        algorithmes, les données, ou les systèmes ou les réseaux de Hopper
        composant les Services HTS Connect, ou tenter de contourner tout
        contrôle de sécurité compris dans les Services HTS Connect. Vous ne
        pouvez pas tenter d'accéder aux Services HTS Connect à l'aide d'outils
        automatisés, y compris, mais sans s'y limiter, l'utilisation de robots,
        de collecteurs, de robots explorateurs ou d'autres outils similaires, ou
        d'autres technologies non autorisées par Hopper. Vous ne pouvez pas
        établir de lien profond avec tout partie des Services HTS Connect (y
        compris, mais sans s'y limiter, le parcours d'achat de tout service de
        voyages) ou encadrer, refléter ou incorporer de toute autre manière une
        partie des Services HTS Connect dans une autre application ou un autre
        site sans l'autorisation écrite expresse préalable de Hopper. Vous ne
        pouvez pas utiliser les Services HTS Connect à des fins frauduleuses ou
        trompeuses. Si votre droit d'accès aux Services HTS Connect a déjà été
        révoqué par Hopper, toute utilisation des Services HTS Connect est
        interdite.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        1.3. <span className={styles.underline}>Mises à jour</span>. Hopper peut
        mettre à jour les Services HTS Connect de temps à autre afin d'améliorer
        les Services HTS Connect. Si vous retardez ou refusez les mises à jour
        des Services HTS Connect, ceci peut nous empêcher de fournir les
        Services HTS Connect.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        2. Autorisations de paiement
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        2.1. <span className={styles.underline}>Paiement</span>. Pour effectuer
        des achats à l'aide des Services HTS Connect auprès de Hopper et/ou de
        fournisseurs de services de voyage tiers (par exemple, les compagnies
        aériennes), vous devrez fournir à Hopper certaines informations. Vous
        acceptez : (i) de fournir et de maintenir les informations associées à
        votre réservation exactes, à jour et complètes, (ii) de protéger vos
        informations en ne partageant, en ne divulguant ou en ne mettant pas à
        disposition des identifiants d'accès à aucune personne non autorisée, et
        (iii) d'informer rapidement Hopper si vous découvrez ou soupçonnez
        autrement toute violation de sécurité liée à votre utilisation des
        Services HTS Connect. Vous acceptez d'être tenu responsable de toutes
        les activités et achats qui se produisent en utilisant les Services HTS
        Connect, et vous acceptez tous les risques d'accès non autorisé aux
        Services HTS Connect en votre nom résultant de tout acte ou omission de
        votre part en violation des présentes conditions. Les informations
        saisies dans le cadre d'une réservation via les Services HTS Connect
        seront stockées uniquement pour cette réservation, et elles ne seront
        pas liées à des réservations précédentes ou futures.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        2.2. <span className={styles.underline}>Modes de paiement</span>. Hopper
        vous permet d'effectuer des achats auprès de Hopper, et/ou de
        fournisseurs de services de voyage tiers, en vous permettant de fournir
        une carte de débit, une carte de crédit et/ou une carte de crédit
        prépayée valide lors du paiement (« Mode de paiement »). En entrant un
        mode de paiement dans le cadre de votre réservation, vous déclarez à
        Hopper que vous êtes un utilisateur autorisé du mode de paiement. Vous
        êtes responsable de vous assurer que le ou les Modes de paiement
        associés à votre réservation sont en tout temps à jour, non expirés et
        valides pour les paiements. Hopper n'est pas une banque et n'offre pas
        de services bancaires ou de « transfert de fonds », et n'est pas une «
        entreprise de services monétaires », et ce au sens où ces termes sont
        définis par le Département de Trésor des États-Unis.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        2.3. <span className={styles.underline}>Autorisation de paiement</span>.
        Vous autorisez Hopper, et/ou les fournisseurs de services de voyage
        tiers applicables, à facturer le montant total de tous les achats ou de
        toutes les autres transactions de paiement initiées par vous lors de
        l'utilisation des Services HTS Connect lors du paiement, tel que
        nécessaire pour effectuer l'achat, le paiement ou toute autre
        transaction financière que vous avez demandée ou acceptée, y compris,
        mais sans s'y limiter, tous les frais et toutes les taxes. Dans certains
        cas, Hopper peut transmettre votre (vos) Mode(s) de paiement à un
        prestataire de services de voyage tiers (comme une compagnie aérienne)
        afin de traiter votre paiement autorisé. En effectuant une réservation,
        vous autorisez la transmission des détails de votre Mode de paiement.
        Dans le cas où vous avez droit à une annulation, un remboursement, une
        rétrofacturation ou tout autre ajustement associé à une transaction que
        vous avez effectuée à l'aide de votre Mode de paiement, vous autorisez
        également Hopper à créditer votre ou vos Modes de paiement, et ce, tel
        que nécessaire pour effectuer la transaction.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        2.4. <span className={styles.underline}>Transactions échouées</span>.
        Dans le cas où la facturation d'une transaction du votre Mode de
        paiement échoue, vous acceptez d'associer un nouveau Mode de paiement
        valide dans les trois (3) jours ouvrables après que Hopper vous en ait
        informé. Tous les produits Hopper et les services de voyage tiers que
        vous avez achetés peuvent être annulés, jusqu'à ce que vous fournissiez
        un Mode de paiement valide et que le solde non facturé soit réglé. De
        plus, vous autorisez Hopper à essayer à nouveau de facturer la
        transaction qui a échouée sur tout Mode de paiement associé à votre
        compte à des intervalles ultérieurs jusqu'à ce qu'elle réussisse. Hopper
        n'est pas responsable des frais imposés par l'émetteur de votre Mode de
        paiement à la suite d'une transaction qui a échouée ou d'une nouvelle
        facturation par Hopper. Dans la mesure où Hopper n'arrive pas à facturer
        votre Mode de paiement pour des transactions autorisées pendant plus de
        trente (30) jours, vous comprenez et acceptez que Hopper puisse employer
        une agence de recouvrement ou une autre entreprise dans le but de
        recouvrer toutes les dettes et tous les frais impayés associés à votre
        réservation, et vous ne contesterez pas l'utilisation d'un tiers pour
        recouvrer la dette et les frais étant dus à Hopper. Vous acceptez que
        vous serez tenu responsable de tous les frais juridiques, judiciaires,
        d'arbitrage ou d'agence de recouvrement associés au redressement de
        votre compte Hopper et de toutes les sommes dues en vertu de celui-ci.
        Vous acceptez que Hopper, ou toute agence ou entreprise employée par
        Hopper, ait le droit de vous contacter, vous et vos héritiers, par
        téléphone, par courriel ou (si ce n'est pas interdit par la loi) en
        personne en utilisant les informations que vous avez fournies lors de
        tout contact avec Hopper, ou toute autre information publiquement
        disponible ou accessible, dans le but de collecter tout montant d'argent
        et des frais dus à Hopper, tel que spécifiquement indiqué dans les
        présentes Conditions ou non, et ce contact peut être effectué
        manuellement ou de manière automatisée.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        2.5.{" "}
        <span className={styles.underline}>
          Transactions non autorisées, appareils perdus
        </span>
        . Si un reçu de transaction que vous avez reçu semble inexact, ou était
        non autorisé ou frauduleux, vous devez en informer Hopper immédiatement
        en contactant Flair, qui vous redirigera vers l'assistance Hopper en
        soumettant une demande par le biais des informations disponibles dans
        l'e-mail de confirmation de votre réservation.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        2.6. <span className={styles.underline}>Conversions de devises</span>.
        Certains prix et/ou frais indiqués pour des achats au sein des Services
        HTS Connect peuvent être affichés en une devise par défaut, ou dans
        votre devise préférée ou sélectionnée, même si le montant facturé sur
        votre Mode de paiement par un fournisseur de services tiers seront dans
        une devise locale. Dans ces cas, le montant facturé à votre Mode de
        paiement peut varier légèrement du montant affiché en raison des
        fluctuations des taux de change hors du contrôle de Hopper. Le
        fournisseur ou l'émetteur de votre Mode de paiement peut également
        facturer des frais de traitement de transaction ou de conversion de
        devise, en plus des taxes applicables aux achats internationaux.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        3. Consentement aux messages électroniques, aux divulgations et aux
        avis, copies papier.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        3.1. En acceptant ces Conditions, vous acceptez que Hopper fournisse les
        Conditions, sa Politique de confidentialité et toutes les autres
        conditions applicables, ainsi que toutes les modifications apportées par
        Hopper à celles-ci, en plus de toutes les divulgations, tous les avis,
        tous les reçus de transaction, toutes les autorisations de transaction
        et tous les relevés de transaction ou toutes autres informations
        requises par la loi (les{""}
        <span className={styles.bold}>« Divulgations »</span>), par voie
        électronique à l'adresse courriel que vous fournissez. Accepter ou
        recevoir de façon électronique des Divulgations, et/ou en utiliser de
        manière continue des Services HTS Connect suite à la publication des
        Divulgations par Hopper, a le même effet que si vous les aviez reçues
        sur papier, ou encore signées à l'encre. Sauf disposition contraire de
        la loi applicable, les Divulgations sont considérées comme étant reçues
        et acceptées par vous dans les 24 heures après avoir été envoyées par
        courriel ou par messagerie, que vous choisissiez de les lire ou non.
        Afin d'accéder et de conserver les Divulgations électroniques, il vous
        faudra, et vous déclarez que vous avez les éléments suivants : (i) un
        ordinateur ou un appareil mobile avec une connexion Internet; (ii) une
        adresse courriel valide pouvant recevoir des courriels de la part de
        @hopper.com; et (iii) suffisamment d'espace de stockage pour sauvegarder
        les Divulgations passées et/ou une imprimante installée pour les
        imprimer. Vous êtes en droit de recevoir les Divulgations sur papier. Si
        vous souhaitez recevoir une copie papier de toute Divulgation ou de tout
        reçu de transaction, vous pouvez demander une copie de la Divulgation ou
        du reçu dans les 180 jours suivant la date de la Divulgation ou du reçu.
        Hopper fournira des copies papier sans frais. Les demandes de réception
        de toute copie papier, ou de retrait de votre consentement à de futures
        Divulgations électroniques, peuvent être faites en envoyant une demande
        par écrit à Hopper (États-Unis), inc., à l'attention de : Département
        juridique, 265 Franklin Street, bureau 1702, Boston (MA) 02110. Les
        demandes d'annulation de votre consentement à de futures Divulgations
        électroniques seront traitées dans un délai raisonnable.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        3.2.{" "}
        <span className={styles.underline}>
          Consentement aux messages et aux contacts
        </span>
        . En effectuant tout achat ou tout réservation en utilisant les Services
        HTS Connect, vous consentez à ce que Hopper, et/ou le fournisseur tiers
        des services de voyage réservés, vous contacte par voie électronique,
        notamment par courriel, téléphone, texto, messagerie dans l'application,
        ou via tout autre service de messagerie numérique disponible, et ce à
        toute fin liée à votre utilisation des Services HTS Connect, et/ou à
        toute réservation ou tout autre achat que vous avez effectué via les
        Services HTS Connect.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        3.3. Votre ordinateur et/ou appareil mobile doit être connecté à
        Internet afin de pouvoir accéder aux Services HTS Connect, et envoyer et
        recevoir des messages à/de la part de Hopper, y compris pour contacter
        l'équipe du service à la clientèle de Hopper. Vous êtes responsable de
        prendre toutes les dispositions nécessaires pour que votre appareil
        puisse se connecter à Internet, en plus d'être responsable de tous les
        frais de tiers liés à cet accès et/ou à la transmission et à la
        réception de données.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        3.4. Lorsque vous effectuez une réservation avec Hopper, vous acceptez
        que les communications avec l'équipe du Service à la clientèle de Hopper
        liées à votre réservation soient effectuées via tous les moyens de
        communication, comme des appels, des textos ou des courriels, et ces
        communications peuvent être initiées par vous ou Hopper.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        4. Ordre de tri de Hopper
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        4.2. Lorsque vous recherchez des vols à l'aide des Services HTS Connect,
        Hopper vous offre un certain nombre d'options d'affichage, de filtration
        et de tri des résultats, qui comprennent le prix et la durée.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        5. Services de voyage de tiers
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.1. Les Services HTS Connect donnent accès aux informations de voyage,
        tels que le prix et la disponibilité des vols, les caractéristiques et
        équipements, ainsi que les conditions générales, fournies et maintenues
        par des tiers. Ces fournisseurs tiers sont des entrepreneurs
        indépendants et non des agents de Hopper. Votre contrat pour ces
        services de voyage est avec le fournisseur tiers, et non avec Hopper.
        Bien que Hopper travaille dur à assurer l'exactitude des informations,
        Hopper ne peut les vérifier et n'est pas responsable de l'exactitude des
        informations fournies par des tiers quant à leurs services respectifs.
        Hopper n'est pas non plus responsable des actions, des erreurs, des
        retards, des omissions, des déclarations, des garanties, des
        manquements, des faillites ou des négligences de ces fournisseurs tiers,
        ou de tout dommage corporel, décès ou dommage matériel, ou d'autres
        dommages ou dépenses résultant de services fournis par les tiers. Hopper
        n'est pas responsable d'effectuer un remboursement en cas de retard, de
        défaillance, de violation, d'annulation, de surréservation, de grève, de
        cas de force majeure ou d'autres causes indépendantes de la volonté
        directe de Hopper. En offrant des services de voyage tiers pour la
        réservation via les Services HTS Connect, Hopper ne cautionne pas, ni ne
        fait aucune déclaration ou garantie concernant la qualité d'un
        fournisseur de services. En outre, sauf indication contraire
        expressément énoncée, Hopper ne prétend pas être affiliée à ou être
        endossée par des fournisseurs de services tiers.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.2. L'achat de produits et de services de voyage de tiers par le biais
        des Services HTS Connect, et le droit à l'annulation ou au remboursement
        de ces services, y compris l'admissibilité aux programmes de fidélité,
        seront régis par et soumis aux conditions générales supplémentaires de
        ces fournisseurs, et ils seront soumis à des lois et des réglementations
        imposées par des entités gouvernementales, et vous acceptez de respecter
        toutes ces conditions, lois et réglementations. Dans la mesure du
        possible, Hopper mettra ces conditions à votre disposition. Des
        conditions supplémentaires applicables peuvent cependant être indiquées
        sur le site Web du fournisseur tiers. Veuillez lire attentivement ces
        conditions générales supplémentaires avant de faire un achat auprès de
        tout fournisseur de services tiers utilisant les Services HTS Connect,
        et vous assurer que vous comprenez toutes les conditions applicables à
        votre billet d'avion ou autre service de tiers. Si vous effectuez des
        réservations au nom d'une ou de plusieurs autres personnes avec leur
        autorisation, vous acceptez d'informer ces autres personnes de ces
        Conditions, ainsi que des conditions, des règles et des restrictions de
        tiers qui s'appliquent aux réservations effectuées en leur nom. Vous
        comprenez et acceptez que toute violation des conditions générales
        d'utilisation de tiers peut entraîner l'annulation de vos réservations,
        un refus de service et la confiscation de tout montant payé. Hopper
        n'est pas responsable des dommages résultant de votre violation des
        conditions de tiers.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3. <span className={styles.underline}>Réservations de vols</span>: les
        réservations de vols sont seulement garanties une fois l'achat terminé
        et les billets émis, comme vous l'a confirmé Hopper par courriel après
        votre achat. Avant que ce délai ne soit écoulé, il pourrait être
        impossible de consulter des informations à propos de votre réservation
        sur le site Web du transporteur.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.1.{" "}
        <span className={styles.underline}>Voyage avec plusieurs billets</span>.
        Votre réservation de voyage en avion via les Services HTS Connect se
        fera en combinant plusieurs billets simples, auprès d'une ou de
        plusieurs compagnies aériennes, au lieu d'un seul billet simple ou
        aller-retour. Combiner des billets simples de cette manière peut offrir
        un plus grand choix de vols à moindre coût. Cependant, chaque billet
        simple de votre voyage sera soumis à ses propres règles, restrictions et
        frais de compagnie aérienne. Pour ce voyage, si une irrégularité de vol
        a une incidence sur l'un de vos vols (par exemple, une annulation, un
        retard ou un changement d'horaire dont la compagnie aérienne est à
        l'origine) et que cette irrégularité peut vous faire manquer un autre
        vol pour lequel vous avez un billet différent, la compagnie aérienne
        opérant cet autre vol n'est pas tenue de changer votre billet et, sous
        réserve des coûts couverts par la{" "}
        <a href={PATH_TERMS_MCP} target="_blank" rel="noreferrer">
          Garantie de correspondance manquée
        </a>{" "}
        de Hopper qui est incluse dans les Services HTS Connect, vous restez
        responsable de tous les frais dus à des modifications (si permises) de
        l'itinéraire, des transporteurs, et des dates ou heures de voyage pour
        la partie de votre voyage couverte par ce ou ces billets séparés.
        Certains billets peuvent ne pas être admissibles à des modifications
        effectuées par les passagers en vertu des règles des tarifs applicables,
        et si vous ne vous présentez pas pour un vol, le billet peut être annulé
        et perdre toute valeur restante. Vos vols achetés à l'aide des Services
        HTS Connect incluront la Garantie correspondance manquée de Hopper, qui
        est soumise à ses propres conditions générales qui se trouvent ici et
        qui sont intégrées aux présentes à titre de référence.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Les voyages à billets multiples peuvent nécessiter de changer de
        terminal et/ou de récupérer des bagages et les réenregistrer avant le
        prochain vol prévu à l'itinéraire. Vous pouvez également devoir passer à
        nouveau la sécurité. Avant d'acheter un itinéraire à billets multiples,
        veuillez prendre en compte toutes les contraintes de temps qui
        pourraient influer sur votre capacité à effectuer votre voyage. Bien que
        les itinéraires à billets multiples incluent la Garantie correspondance
        manquée de Hopper, vous êtes seul responsable de récupérer vos bagages
        entre les destinations et de connaître toutes les obligations en matière
        de bagages et de sécurité entre les correspondances.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.2. <span className={styles.underline}>Vols internationaux</span>.
        Vous êtes seul responsable de vous assurer que vous respectez toutes les
        exigences d'entrée imposées par les autorités gouvernementales à votre
        ou vos destinations, y compris les pays dans lesquels vous avez une
        escale, y compris les exigences en matière de santé et de passeport ou
        visa, et que vos documents de voyage, tels que les passeports et visas
        (transit, affaires, touriste, etc.), sont valides. Hopper ne fournit
        aucun conseil concernant les exigences d'entrée pour aucune destination.
        Pour obtenir ces informations, vous devez consulter l'ambassade ou le
        consulat approprié. Les exigences peuvent changer et vous devez vérifier
        les informations à jour avant d'effectuer une réservation et de partir.
        Hopper n'est pas responsable si vous ne pouvez pas prendre un vol ou
        entrer dans un pays parce que vous ne vous respectez pas les exigences
        d'entrée. EN PROPOSANT DES RÉSERVATIONS POUR DES PRODUITS DE VOYAGE DANS
        DES DESTINATIONS PARTICULIÈRES, HOPPER NE DÉCLARE OU NE GARANTIT PAS QUE
        DE VOYAGER DANS CES ZONES EST CONSEILLÉ OU SANS RISQUE, ET N'EST PAS
        RESPONSABLE DES DOMMAGES OU PERTES QUI PEUVENT RÉSULTER DU VOYAGE VERS
        CES DESTINATIONS.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.3.{" "}
        <span className={styles.underline}>
          Avis aux passagers internationaux sur les limitations de
          responsabilité
        </span>
        . LES PASSAGERS QUI ENTREPRENNENT UN VOYAGE DONT LA DESTINATION FINALE
        OU UNE ESCALE EST DANS UN AUTRE PAYS QUE LE PAYS DE DÉPART SONT INFORMÉS
        QUE LES DISPOSITIONS D'UN TRAITÉ INTERNATIONAL (LA CONVENTION DE
        VARSOVIE, LA CONVENTION DE MONTRÉAL DE 1999 OU UNE AUTRE CONVENTION),
        AINSI QUE LE CONTRAT DE TRANSPORT OU LES DISPOSITIONS TARIFAIRES D'UN
        TRANSPORTEUR, PEUVENT ÊTRE APPLICABLES À L'ENSEMBLE DE LEUR VOYAGE, Y
        COMPRIS TOUTE PARTIE ENTIÈREMENT À L'INTÉRIEUR DES PAYS DE DÉPART ET DE
        DESTINATION. LA CONVENTION APPLICABLE RÉGIT ET PEUT LIMITER LA
        RESPONSABILITÉ DES TRANSPORTEURS ENVERS LES PASSAGERS EN CAS DE DÉCÈS OU
        DE BLESSURES CORPORELLES, DE DESTRUCTION, DE PERTE OU DE DÉTÉRIORATION
        DES BAGAGES, ET DE RETARD DES PASSAGERS ET DES BAGAGES.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        UNE PROTECTION SUPPLÉMENTAIRE PEUT GÉNÉRALEMENT ÊTRE OBTENUE EN
        SOUSCRIVANT UNE ASSURANCE AUPRÈS D'UNE SOCIÉTÉ PRIVÉE. CETTE ASSURANCE
        N'EST RESTREINTE PAR AUCUNE LIMITATION DE LA RESPONSABILITÉ DU
        TRANSPORTEUR EN VERTU D'UN TRAITÉ INTERNATIONAL. POUR EN SAVOIR PLUS,
        VEUILLEZ CONSULTER VOTRE REPRÉSENTANT DE COMPAGNIE AÉRIENNE OU
        D'ASSURANCE.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.4.{" "}
        <span className={styles.underline}>
          Avis des Conditions du contrat incorporées par référence
        </span>
        . Votre contrat de transport avec le transporteur qui vous fournit le
        transport aérien, qu'il s'agisse d'un transport international, national
        ou d'une partie nationale d'un voyage international, est assujetti au
        présent avis, à tout avis ou reçu du transporteur, et aux conditions
        générales (les Conditions), règles connexes, règlements et politiques
        (les Règlements) du transporteur, et à tous les tarifs applicables. Si
        votre transport est effectué par plus d'un transporteur, des Conditions
        et des Règlements différents, en plus de tous les tarifs applicables
        peuvent s'appliquer pour chaque transporteur. Les Conditions, les
        Règlements et tous les tarifs applicables de chaque transporteur sont,
        par le présent avis, incorporés par référence et font partie de votre
        contrat de transport. Les Conditions peuvent inclure, mais sans s'y
        limiter : (a) les Conditions et les limites de la responsabilité du
        transporteur pour les blessures corporelles ou le décès des passagers;
        (b) les Conditions et les limites de la responsabilité du transporteur
        si les biens ou les bagages sont perdus, endommagés ou en retard, y
        compris les biens fragiles ou périssables; (c) les règles pour déclarer
        une valeur plus élevée pour les bagages et pour payer les frais
        supplémentaires qui peuvent s'appliquer; (d) l'application des
        Conditions et des limites de la responsabilité du transporteur aux actes
        des agents, des préposés et des représentants du transporteur, y compris
        toute personne qui fournit de l'équipement ou des services au
        transporteur; e) les restrictions relatives aux réclamations, y compris
        les délais dans lesquels les passagers doivent déposer des réclamations
        ou intenter des actions contre le transporteur; f) les règles relatives
        aux reconfirmations ou aux réservations, les heures d'enregistrement,
        l'utilisation, la durée et la validité des services de transport aérien,
        et le droit du transporteur de refuser d'embarquer des passagers; g) les
        droits du transporteur et les limites quant à la responsabilité du
        transporteur en cas de retard ou de défaut d'exécution d'un service, y
        compris les changements d'horaire, l'utilisation d'autres transporteurs
        ou avions et le réacheminement et, lorsque la loi applicable l'exige,
        l'obligation du transporteur d'informer les passagers de l'identité du
        transporteur aérien ou de l'avion substitué; et h) les droits du
        transporteur de refuser d'embarquer les passagers qui ne respectent pas
        les lois applicables ou qui omettent de présenter tous les documents de
        voyage nécessaires.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Vous pouvez obtenir des informations supplémentaires sur les articles
        (a) à (h) ci-dessus et sur votre contrat de transport (y compris la
        façon d'en demander une copie) aux endroits où le transport sur le
        transporteur est vendu. Plusieurs transporteurs affichent également ces
        informations sur leurs sites Web. Lorsque la loi applicable l'exige,
        vous avez le droit d'inspecter le texte intégral de votre contrat de
        transport à l'aéroport et aux bureaux de vente du transporteur, et sur
        demande, de recevoir gratuitement une copie par la poste ou tout autre
        service de livraison de chaque transporteur. Si un transporteur vend des
        services de transport aérien ou enregistre des bagages spécifiant le
        transport sur un autre transporteur, il ne le fait qu'en tant qu'agent
        pour l'autre transporteur.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.5.{" "}
        <span className={styles.underline}>
          Modifications d'itinéraire ou annulations
        </span>
        . Les modifications d'itinéraire ou les annulations que vous demandez
        (c'est-à-dire les remboursements volontaires), si elles sont autorisées
        en vertu des règles tarifaires applicables à votre billet d'avion,
        peuvent faire l'objet de frais imposés par la compagnie aérienne en plus
        de tout différentiel tarifaire lors de la revente des billets d'avion.
        Ces frais varient d'une compagnie aérienne à l'autre. Votre compagnie
        aérienne peut permettre qu'une partie du tarif payé pour un billet non
        remboursable inutilisé soit appliquée à l'achat d'un autre voyage. Pour
        beaucoup de compagnies aériennes, les billets non modifiés ou annulés
        avant le départ n'ont plus aucune valeur.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.6.{" "}
        <span className={styles.underline}>Remboursements des taxes</span>. Si
        vous ne vous présentez pas à une réservation de vol ou que vous
        l'annulez, vous pourriez avoir droit à un remboursement des taxes et des
        frais gouvernementaux inclus dans le prix du billet acheté. Lorsque vous
        avez droit à un remboursement, vous pouvez le demander auprès du service
        à la clientèle de Hopper, lequel enverra votre demande à la compagnie
        aérienne en votre nom.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.7.{" "}
        <span className={styles.underline}>Droits des voyageurs dans l'UE</span>
        . Si vous voyagez à destination ou en provenance de l'Union européenne,
        ou si vous voyagez avec une compagnie aérienne basée au sein de l'Union
        européenne, vous pourriez vous prévaloir de certains droits en cas
        d'annulation, de retard ou de refus d'embarquement. Pour en savoir plus,
        veuillez consulter le Règlement (CE) numéro 261/2004.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.8. <span className={styles.underline}>Matières dangereuses</span>.
        La loi fédérale américaine interdit de transporter des matières
        dangereuses à bord des avions dans vos bagages ou sur vous-même. Une
        violation de cette loi peut entraîner une peine d'emprisonnement de cinq
        ans et des amendes de 250 000 $ ou plus (49 U.S.C. 5124). Les matières
        dangereuses comprennent les explosifs, les gaz comprimés, les liquides
        et solides inflammables, les oxydants, les poisons, les agents corrosifs
        et les matières radioactives. Voici quelques exemples : de la peinture,
        de l'essence pour briquets, des feux d'artifice, des gaz lacrymogènes,
        des bouteilles d'oxygène et des produits radiopharmaceutiques. Il y a
        des exceptions spéciales pour de petites quantités (jusqu'à 70 onces au
        total) d'articles médicinaux et de toilette transportés dans vos
        bagages, de batteries au lithium de rechange et de certains matériaux à
        fumer transportés sur vous-même. Vous devez transporter certains
        articles avec vous à bord de l'avion. Les piles de rechange au lithium
        et les briquets doivent être retirés des bagages enregistrés ou
        enregistrés à la porte d'embarquement, puis transportés à bord de
        l'avion.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.9.{" "}
        <span className={styles.underline}>
          Pratiques interdites des compagnies aériennes
        </span>
        . Plusieurs compagnies aériennes interdisent le contournement des règles
        d'un transporteur aérien, y compris les pratiques comme l'achat de
        billets croisés (réserver deux billets ou plus avec des dates de voyage
        qui se chevauchent afin de contourner les exigences de séjour minimum)
        et de billets pour une ville où vous ne vous rendrez pas ou autre que la
        destination (réserver des billets qui comprennent des parties de
        l'itinéraire que vous n'avez pas l'intention d'utiliser afin de
        contourner la structure tarifaire d'un transporteur aérien). Vous
        convenez que vous n'utiliserez pas les Applications ou les Services pour
        vous livrer à ces activités ou à des activités similaires si elles sont
        interdites par une compagnie aérienne. LES TRANSPORTEURS SE RÉSERVENT LE
        DROIT DE REFUSER D'EMBARQUER TOUTE PERSONNE AYANT ACQUIS UN BILLET EN
        VIOLATION DE LA LOI APPLICABLE OU DES TARIFS, RÈGLES OU RÈGLEMENTS DU
        TRANSPORTEUR.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.10. <span className={styles.underline}>Informations requises</span>.
        La Transportation Security Administration (TSA), qui fait partie du
        Département de la sécurité intérieure des États-Unis, exige que Hopper
        recueille des informations auprès de vous aux fins de la vérification
        des listes de surveillance, conformément à la section 114 du 49 U.S.C.,
        et la loi de 2004 sur la réforme du renseignement et la prévention du
        terrorisme. La TSA peut transmettre les informations que vous lui
        fournissez aux représentants de la loi ou à des services de
        renseignement, ou à d'autres entités dans le système publié d'avis
        d'enregistrements. Pour en savoir plus, veuillez consulter le{" "}
        <a href="https://www.tsa.gov" target="_blank" rel="noreferrer">
          www.tsa.gov
        </a>
        .
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.11. <span className={styles.underline}>Transfert de données</span>.
        LES GOUVERNEMENTS PEUVENT DEMANDER À VOTRE COMPAGNIE AÉRIENNE DE FOURNIR
        DES INFORMATIONS SUR LES DONNÉES DES PASSAGERS OU D'AUTORISER L'ACCÈS À
        CES DONNÉES, Y COMPRIS LES INFORMATIONS QUE NOUS POURRIONS ÊTRE TENUS DE
        COLLECTER AUPRÈS DE VOUS PENDANT LE PROCESSUS DE RÉSERVATION.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.12.{" "}
        <span className={styles.underline}>
          Avis américain – Surréservation de vols
        </span>
        . Les vols aériens peuvent être surréservés et, dans de rares cas, il
        peut arriver qu'un siège ne soit pas disponible sur un vol pour lequel
        une personne a une réservation confirmée. Si le vol est surréservé,
        personne ne se verra refuser une place jusqu'à ce que le personnel de la
        compagnie aérienne demande d'abord s'il y a des volontaires disposés à
        renoncer à leur réservation en échange du paiement d'un montant choisi
        par la compagnie aérienne. S'il n'y a pas suffisamment de volontaires,
        la compagnie aérienne refusera l'embarquement à d'autres personnes
        conformément à ses critères de priorité d'embarquement. À quelques
        exceptions près, les personnes à qui on a refusé l'embarquement ont
        droit à une compensation. Vous pouvez consulter les règles complètes
        pour le paiement de la compensation et les critères de priorité
        d'embarquement de chaque compagnie aérienne à tous les comptoirs de
        billets et les zones d'embarquement d'aéroport.{" "}
        <span className={styles.bold}>
          Certaines compagnies aériennes n'appliquent pas ces protections pour
          les consommateurs aux voyages en provenance de certains pays
          étrangers, bien que d'autres protections puissent être offertes aux
          consommateurs. Vérifiez auprès de la compagnie aérienne ou de votre
          agent de voyage.
        </span>
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.13. <span className={styles.underline}>Bagages</span>. Une
        surévaluation peut être déclarée sur certains types d'articles. Les
        transporteurs peuvent appliquer des règles spéciales pour les articles
        fragiles, de grande valeur ou périssables. Vérifiez auprès de votre
        transporteur. Bagages enregistrés : les transporteurs peuvent autoriser
        un certain nombre de bagages enregistrés gratuits, lequel est fixé par
        le transporteur et peut différer selon la classe et/ou l'itinéraire. Les
        transporteurs peuvent facturer des frais supplémentaires pour les
        bagages enregistrés dépassant la limite permise. Vérifiez auprès de
        votre transporteur. Bagages de cabine (non enregistrés) : les
        transporteurs peuvent autoriser un certain nombre de bagages de cabine
        gratuits, lequel est fixé par le transporteur et peut différer selon la
        classe, l'itinéraire et/ou le type d'avion. Il est recommandé de garder
        le nombre de bagages de cabine au strict minimum. Vérifiez auprès de
        votre transporteur.{" "}
        <span className={styles.bold}>
          Si plus d'un transporteur vous transporte pendant le voyage, chaque
          transporteur peut appliquer des règles différentes sur les bagages
          (enregistrés et de cabine).
        </span>{" "}
        LIMITES DE RESPONSABILITÉ RELATIVES AUX BAGAGES SPÉCIAUX POUR LES VOLS
        AUX ÉTATS-UNIS : pour les vols intérieurs effectués entièrement entre
        des destinations américaines, les règles fédérales exigent que toute
        limite de responsabilité relative aux bagages d'un transporteur soit
        d'au moins 3 800 $ US par passager, ou le montant actuellement exigé par
        l'avis 14 CFR § 254.5. Si vous enregistrez des bagages dans le cadre
        d'un itinéraire à billets multiples, vous devez sortir de la zone de
        sécurité, récupérer vos bagages et les réenregistrer avant votre
        prochain vol (le cas échéant). Vous êtes seul responsable de comprendre
        vos responsabilités en matière de bagages sur les itinéraires à billets
        multiples.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.14.{" "}
        <span className={styles.underline}>Heures d'enregistrement</span>.
        L'heure indiquée sur l'itinéraire ou le reçu est l'heure de départ de
        l'avion. L'heure de départ du vol n'est pas la même que l'heure à
        laquelle vous devez vous enregistrer ou celle à laquelle vous devez être
        présent pour l'embarquement. Votre transporteur peut refuser de vous
        embarquer si vous êtes en retard. Les heures d'enregistrement, telles
        qu'indiquées par votre transporteur, sont les dernières heures
        auxquelles les passagers peuvent être acceptés pour prendre leur vol, et
        les heures d'embarquement, telles qu'indiquées par votre transporteur,
        sont les dernières heures auxquelles les passagers doivent se présenter
        pour l'embarquement. Les vols ne peuvent pas attendre les passagers
        arrivant en retard, et Hopper décline toute responsabilité dans de tels
        cas.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.15.{" "}
        <span className={styles.underline}>Désinsectisation de l'avion</span>.
        Certains pays peuvent exiger un traitement insecticide de la cabine de
        l'avion pour les vols arrivant de l'étranger. Vous pouvez consulter la
        liste de ces pays au{" "}
        <a
          href="https://www.transportation.gov/airconsumer/spray"
          target="_blank"
          rel="noreferrer"
        >
          www.transportation.gov/airconsumer/spray
        </a>
        .
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.16.{" "}
        <span className={styles.underline}>Ordre des étapes du voyage</span>.
        Les compagnies aériennes exigent que les passagers empruntent
        l'itinéraire indiqué sur leur billet, du lieu de départ jusqu'à leur
        destination finale en passant par toute correspondance convenue, et vice
        versa. Si le voyage n'est pas effectué dans le bon ordre, le billet
        perdra toute sa validité dans la plupart des cas.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.17. Hopper peut recevoir des commissions ou d'autres incitatifs
        financiers de transporteurs aériens dans le cadre de votre réservation
        aérienne via les Services HTS Connect. Dans certains cas, Hopper peut
        être à l'origine de l'incitatif en appliquant une majoration incluse
        dans le prix que vous payez à Hopper pour cette réservation, et ce prix
        peut différer du prix apparaissant sur votre billet d'avion ou votre
        itinéraire.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.3.18. Les vols vendus au Royaume-Uni par Hopper sont vendus en tant
        qu'agent du transporteur nommé sur votre billet et ne sont pas protégés
        par le régime de licence des organisateurs de voyages aériens (Air
        Travel Organisers’ Licensing).
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        5.6. <span className={styles.underline}>Sites tiers</span>. Les services
        HTS Connect peuvent contenir des liens vers des sites Web de tiers qui
        ne sont pas exploités par Hopper. Ces liens sont fournis à titre
        indicatif uniquement. Hopper n'est pas responsable du contenu, de la
        confidentialité ou d'autres pratiques qu'on trouve sur ces sites. Vous
        devez consulter les conditions applicables de tous les sites tiers que
        vous choisissez de visiter.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        6. Entités Hopper, enregistrement des vendeurs de voyages et
        divulgations
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Sauf indication contraire ci-dessous, toutes les réservations sont
        effectuées par Hopper (États-Unis), inc., une filiale en propriété
        exclusive de Hopper, inc. Hopper (États-Unis), inc. est un vendeur
        enregistré de voyages dans chacun des États américains indiqués
        ci-dessous :
      </Typography>
      <Typography variant="body2" className={styles.mb}></Typography>
      <Typography variant="body2" className={styles.center}>
        Californie*, numéro d'enregistrement:{" "}
        <span className={styles.bold}>2141604-70</span>
      </Typography>
      <Typography variant="body2" className={styles.center}>
        Hawaï, numéro d'enregistrement:{" "}
        <span className={styles.bold}>TAR 7387</span>
      </Typography>
      <Typography variant="body2" className={clsx(styles.mb, styles.center)}>
        Washington, numéro d'enregistrement:{" "}
        <span className={styles.bold}>604516639</span>
      </Typography>
      <Typography variant="body2" className={clsx(styles.mb, styles.italic)}>
        *L'enregistrement en tant que vendeur de voyages en Californie ne
        constitue pas l'approbation de Hopper par l'État. Hopper n'est pas un
        participant du Travel Consumer Restitution Fund.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        <span className={styles.underline}>
          Divulgations supplémentaires pour les résidents de la Californie
        </span>
        : lors de l'annulation des services de transport ou de voyage, lorsque
        le passager n'est pas responsable et n'a pas annulé en enfreignant les
        conditions générales, qui ont précédemment été clairement et visiblement
        divulguées et acceptées par le passager, toutes les sommes versées au
        vendeur de voyages pour couvrir les services non fournis seront
        rapidement remboursées au passager. Cette disposition ne s'applique pas
        lorsque le vendeur de voyages a remis le paiement du client à un autre
        vendeur de voyages en gros enregistré ou à un transporteur, et ce, sans
        obtenir de remboursement, et que cet autre fournisseur ne fournit pas le
        transport ou le service convenu. Dans ce cas, le vendeur de voyages
        initial doit fournir au passager une déclaration écrite accompagnée de
        relevés bancaires démontrant que le paiement a été versé au client et,
        en cas de versement à un grossiste de voyages enregistré, une preuve de
        l'enregistrement valide de ce grossiste.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        <span className={styles.underline}>
          Divulgations supplémentaires pour les résidents de l'État de
          Washington
        </span>
        : si le transport ou d'autres services sont annulés par le vendeur de
        voyages, toutes les sommes versées au vendeur de voyages pour les
        services non exécutés conformément au contrat entre le vendeur de
        voyages et l'acheteur seront remboursées dans les 30 jours suivant la
        réception des fonds du fournisseur avec lequel les services ont été
        organisés, ou si les fonds n'ont pas été envoyés au fournisseur, les
        fonds seront retournés dans les 14 jours suivant l'annulation par le
        vendeur de voyages à l'acheteur, à moins que l'acheteur ne demande au
        vendeur de voyages d'appliquer la somme à un autre produit de voyage
        et/ou à une date ultérieure.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        <span className={styles.underline}>
          Informations supplémentaires pour les résidents de l'Union européenne
        </span>
        : pour les résidents de l'Union européenne, les réservations de voyages
        via les services HTS Connect sont effectuées par Hopper Travel
        (Irlande), ltée., Mespil Business Centre, Mespil House, Sussex Road,
        Dublin 4, Irlande (« Hopper Irlande »). Si, après avoir sélectionné et
        payé un service de voyage, vous réservez des services de voyage
        supplémentaires pour votre voyage ou vos vacances en utilisant les
        Services HTS Connect, les droits applicables aux forfaits en vertu de la
        loi de 1995 sur les voyages à forfait et les voyages organisés (Package
        Holidays and Travel Trade Act) ne s'appliquent PAS à vous. Par
        conséquent, Hopper Irlande ne sera pas responsable de la bonne exécution
        des services de voyage individuels. En cas de problèmes, veuillez
        contacter le fournisseur de services concerné. Cependant, si vous
        réservez des services de voyage supplémentaires au cours de la même
        visite en utilisant les Services HTS Connect, les services de voyage
        feront partie d'un arrangement de voyage lié. Dans ce cas, Hopper
        Irlande dispose, tel qu'exigé par la loi de 1995 sur les voyages à
        forfait et les voyages organisés, d'une protection pour rembourser vos
        paiements à Hopper Irlande pour les services non exécutés en raison de
        l'insolvabilité de Hopper Irlande. Veuillez noter qu'aucun remboursement
        en cas d'insolvabilité du fournisseur de services concerné n'est prévu.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Pour en savoir plus sur la protection contre l'insolvabilité de Hopper
        Irlande, consultez ce{" "}
        <a
          href="https://drive.google.com/file/d/13tZwUmxfmogu-bJreEVgBJZdYNWMP-WW/view"
          target="_blank"
          rel="noreferrer"
        >
          lien.
        </a>
        . Hopper Irlande a souscrit une protection contre l'insolvabilité auprès
        de l'International Passenger Protection (IPP). Les voyageurs peuvent
        contacter l'IPP ou, le cas échéant, l'Irish Aviation Authority (IAA),
        The Times Building, 11-12 D'Olier Street, Dublin 2, D02 T449, adresse
        courriel : traveltrade@iaa.ie, et numéro de téléphone : +353 1 603 1100,
        si les services sont refusés en raison de l'insolvabilité de Hopper
        Irlande. Remarque : cette protection contre l'insolvabilité ne couvre
        pas les contrats avec des parties autres que Hopper Irlande, qui peuvent
        être exécutés malgré l'insolvabilité de Hopper Irlande.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Vous pouvez consulter la loi de 1995 sur les voyages à forfait et les
        voyages organisés en cliquant sur ce lien.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Pour les résidents du Canada, les réservations sont effectuées par
        Hopper (Canada), inc., une filiale en propriété exclusive de Hopper,
        inc., structurée en vertu des lois de l'Alberta au Canada.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Pour les résidents du Mexique, les réservations sont effectuées par
        Hopper App Travel Mexico, S.A. de R.L., Av. Paseo de la Reforma #403,
        oficina 806, Cuauhtèmoc, Mexique.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Pour les résidents de la Colombie, les réservations sont effectuées par
        Hopper Colombia SAS, Cra 9 No 116-06 Piso 17 - Complejo Tierra Firme,
        Bogotá, Colombie.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Pour les résidents du Chili, les réservations sont effectuées par Hopper
        Chile, Marchant Pereira 150, Of. 1002, Providencia, Santiago, Chili.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Pour les résidents du Royaume-Uni, les réservations sont effectuées par
        Hopper UK Inc., ltée., 58-60 Kensington Church St., Kensington, Londres,
        W8 4DB, Royaume-Uni.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Votre paiement peut être traité par une entité Hopper affiliée autre que
        l'entité responsable de votre réservation.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Toute demande de service à la clientèle peut être communiquée à Hopper
        par l'intermédiaire de l'équipe du service à la clientèle de Flair, 24
        heures sur 24, 7 jours sur 7, en contactant l'équipe d'assistance de
        Flair aux coordonnées fournies dans l'e-mail de confirmation de votre
        réservation.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        7. Propriété intellectuelle
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        7.1. Les Services HTS Connect que vous avez téléchargés ou auxquels vous
        avez accédé conformément aux présentes Conditions, y compris, mais sans
        s'y limiter, tous les textes, toutes les images, tous les sons, toutes
        les marques, tous les logos, toutes les compilations (c'est-à-dire la
        collecte, l'organisation et le montage d'informations), en plus de tout
        autre contenu qu'ils contiennent, et tous les logiciels, tous les codes
        source ou objet, tous les systèmes, toutes les données et tous les
        algorithmes incorporés dans ou prenant en charge les Services HTS
        Connect, ou autrement utilisés par Hopper pour fournir les Services HTS
        Connect, et tous les droits de propriété intellectuelle, y compris, mais
        sans s'y limiter, les brevets, les droits d'auteur, les droits de
        propriété industrielle et commerciale (collectivement, la
        <span className={styles.bold}>« Propriété de Hopper »</span>), sont la
        propriété unique et exclusive de Hopper et/ou de tiers fournis sous
        licence de, ou utilisés avec la permission de, Hopper, et sont protégés
        par les lois des États-Unis, du Canada et les lois internationales.
        Hopper se réserve tous les droits sur la Propriété de Hopper, y compris,
        mais sans s'y limiter, les droits d'auteur, de marque de commerce, de
        brevet et de secret commercial et aucun droit sur cette Propriété de
        Hopper n'est accordé, sauf disposition expresse des présentes. Vous
        bénéficiez par les présentes d'un droit limité, personnel, non exclusif,
        non transférable, non sous-cessible et révocable d'accéder, de
        télécharger et d'utiliser les Services HTS Connect uniquement aux fins
        expressément autorisées par les présentes Conditions, à condition que
        vous respectiez les présentes Conditions.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        7.2. <span className={styles.underline}>Marques de commerce</span>. Les
        marques « HOPPER », les images de lapin et de lapin qui court, entre
        autres marques, sont des marques de commerce déposées ou non déposées de
        Hopper, protégées par les lois des États-Unis, du Canada et d'autres
        pays dans le monde, et ne peuvent être utilisées en relation avec des
        services ou des produits autres que ceux fournis ou expressément
        autorisés par Hopper, d'une manière susceptible de confondre les
        clients, ou d'une manière qui dénigre ou discrédite Hopper. Les Services
        HTS Connect peuvent également incorporer des marques de commerce
        déposées et protégées de tiers, et l'utilisation de ces marques de
        commerce déposées ne constitue pas une affiliation, une approbation ou
        un parrainage de Hopper par un tiers.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        7.3. <span className={styles.underline}>Propriété de tiers</span>. Les
        Services HTS Connect comprennent les marques de commerce déposées, les
        marques de service, les images, les logos et les données de tiers, y
        compris ceux des partenaires de services de voyage tiers de Hopper (
        <span className={styles.bold}>« Propriété de tiers »</span>). Chaque
        propriétaire tiers conserve tous les droits sur cette Propriété de
        tiers. Toute utilisation de cette Propriété de tiers fournie ou affichée
        dans les Services HTS Connect se réalise uniquement à l'avantage de
        leurs propriétaires respectifs. L'utilisation par Hopper de cette
        Propriété de tiers n'implique aucune affiliation ou relation entre
        Hopper et le tiers, ni aucune approbation de Hopper ou des Services HTS
        Connect par ce tiers.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        7.4.{" "}
        <span className={styles.underline}>
          Utilisation des données de recherche
        </span>
        . Votre utilisation des données de recherche trouvées sur
        media.hopper.com/research ou que nous mettons à votre disposition par
        d'autres moyens, ou bien sur d'autres emplacements physiques ou
        électroniques, y compris, mais sans s'y limiter, les données, les bases
        de données, les rapports, les livres blancs, les analyses ou les
        compilations réalisées et/ ou distribuées par Hopper (les{" "}
        <span className={styles.bold}>« Données de recherche »</span>) sont
        aussi régies par les conditions de la présente Section. Hopper vous
        accorde un droit non exclusif, limité, non transférable, non
        sous-cessible et révocable d'utiliser, de reproduire, d'afficher et de
        redistribuer les données de recherche à condition que vous respectiez
        les conditions suivantes. Vous devez citer Hopper comme source de toute
        Donnée de recherche dans toute publication que vous produisez qui
        inclut, cite ou s'appuie sur des Données de recherche de quelque manière
        que ce soit. Si votre publication est en format électronique, vous devez
        créer un lien vers Hopper.com et tous les liens de retour existants.
        Vous devez créditer Hopper en tant que Source des Données de recherche,
        mais vous ne devez pas non plus utiliser les marques de commerce de
        Hopper ou toute variante similaire pouvant prêter à confusion. Vous ne
        devez pas prétendre ou impliquer qu'une entité Hopper approuve votre
        utilisation des Données de recherche, ou des conclusions ou des œuvres
        dérivées que vous pourriez créer. Vous reconnaissez que les Données de
        recherche sont fournies « TELLES QUELLES ». Hopper ne garantit pas que
        les données et les publications seront exemptes d'erreurs ou complètes,
        et ne peut assumer la responsabilité de votre utilisation ou de la
        confiance que vous accordez aux Données de recherche. Vous reconnaissez
        notre droit de modifier, supprimer ou retirer les Données de recherche à
        tout moment et sans préavis. Vous reconnaissez que nous maintenons tous
        les droits, titres et intérêts, y compris la propriété, de toutes les
        Données de recherche.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        7.5.{" "}
        <span className={styles.underline}>
          Digital Millennium Copyright Act
        </span>
        . Hopper respecte la propriété intellectuelle des autres. Si vous pensez
        que votre œuvre protégée par des droits d'auteur a été copiée et est
        accessible dans les Services HTS Connect d'une manière qui constitue une
        violation des droits d'auteur, veuillez fournir à Hopper un avis
        contenant les informations suivantes requises par le Digital Millennium
        Copyright Act, 17 U.S.C. §512 (le{" "}
        <span className={styles.bold}>« DMCA »</span>) à Hopper, à l'attention
        de : Département juridique, 265, Franklin Street, bureau 1702, Boston
        (MA) 02110 :
      </Typography>
      <span className={clsx(styles.gridCenter, styles.mb)}>
        <ul>
          <li>
            des informations raisonnablement suffisantes pour nous permettre de
            contacter la partie alléguant l'infraction, y compris une adresse
            courriel;
          </li>
          <li>
            l'identification de l'œuvre protégée par les droits d'auteur qui
            aurait été violée ou, si plusieurs œuvres protégées par des droits
            d'auteur auraient été violées, une liste représentative de ces
            œuvres protégées par des droits d'auteur;
          </li>
          <li>
            l'identification du matériel considéré comme étant en infraction et
            qui doit être supprimé ou dont l'accès doit être révoqué, et des
            informations raisonnablement suffisantes pour nous permettre de
            localiser le matériel considéré comme étant en infraction, par
            exemple, l'adresse de la page Web en question ou la capture d'écran
            des Services HTS Connect;
          </li>
          <li>
            une déclaration selon laquelle la partie alléguant l'infraction
            croit de bonne foi que l'utilisation de l'œuvre protégée de la
            manière reprochée n'est pas autorisée par le titulaire des droits
            d'auteur ou son agent, ou n'est pas autrement autorisée par la loi;
          </li>
          <li>
            une déclaration selon laquelle les informations contenues dans
            l'avis sont exactes et, sous peine de parjure, que la partie
            alléguant l'infraction est autorisée à agir au nom du titulaire des
            droits d'auteur de l'œuvre qui aurait été enfreinte; et
          </li>
          <li>
            une signature physique ou électronique d'une personne autorisée à
            agir au nom du titulaire des droits d'auteur certifiant les
            déclarations et les informations fournies.
          </li>
        </ul>
      </span>
      <Typography variant="body2" className={styles.mb}>
        EN VERTU DE LA LOI FÉDÉRALE, SI VOUS DÉCLAREZ SCIEMMENT QUE LE MATÉRIEL
        EN LIGNE CONSTITUE UNE INFRACTION, VOUS POURRIEZ ÊTRE SUJET À DES
        POURSUITES CRIMINELLES POUR PARJURE ET DES SANCTIONS CIVILES, Y COMPRIS
        DES DOMMAGES PÉCUNIAIRES, DES FRAIS JUDICIAIRE ET DES FRAIS D'AVOCAT.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        7.6 <span className={styles.underline}>Commentaires</span>. Vous n'êtes
        sous aucune obligation de fournir à Hopper des idées, des suggestions,
        des recommandations, des demandes d'amélioration, des témoignages, des
        approbations, des évaluations de produits ou des informations concernant
        votre expérience d'utilisation des Services HTS Connect (
        <span className={styles.bold}>« Commentaires »</span>). Cependant, si
        vous envoyez des Commentaires à ou à propos de Hopper, de manière
        directe ou indirecte via un site d'évaluation tiers ou une enquête
        initiée par Hopper, vous accordez à Hopper une licence non révocable,
        non exclusive, mondiale et libre de droits qui est sous-cessible et
        transférable pour produire, utiliser, reproduire, afficher publiquement,
        distribuer, vendre, modifier ou laisser publiquement des Commentaires de
        quelque manière que ce soit et sans aucune obligation, redevance ou
        restriction basée sur des droits de propriété intellectuelle ou autre.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        8. Produits exclusifs Hopper.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Les Services HTS Connect peuvent proposer un certain nombre de produits
        exclusifs (les <span className={styles.bold}>« Produits »</span>), qui
        visent à accroître la flexibilité des articles de voyage achetés.
        L'achat de chaque Produit est soumis à des conditions générales
        spécifiques que vous pourrez consulter avant l'achat. Ces Produits ne
        sont PAS des produits financiers réglementés tels que des assurances, et
        les conditions générales spécifiées ne constituent pas des offres
        d'assurance ou un contrat d'assurance, et ne remplacent pas l'assurance
        à laquelle vous avez souscrite ou à laquelle vous pouvez souscrire. La
        solution HTS Connect comprend le produit Garantie de correspondance
        manquée de Hopper. Ce produit est soumis à des termes et conditions
        spécifiques qui peuvent être consultés{" "}
        <a href={PATH_TERMS_MCP} target="_blank" rel="noreferrer">
          ici
        </a>
        , et qui sont incorporés par référence aux présentes.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        9. Exclusion de garanties.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        9.1. Les Services HTS Connect, y compris, mais sans s'y limiter, les
        prévisions de prix et les recommandations de Hopper, sont fournis « TELS
        QUELS » et « TELS QUE DISPONIBLES ». DANS TOUTE LA MESURE PERMISE PAR LA
        LOI APPLICABLE, HOPPER DÉCLINE, EN CE QUI A TRAIT AUX SERVICES HTS
        CONNECT, AUX SERVICES ET TOUT LEUR CONTENU, TOUTES LES GARANTIES,
        EXPLICITES OU IMPLICITES, STATUTAIRES OU AUTRES, Y COMPRIS, MAIS SANS
        S'Y LIMITER, LES GARANTIES IMPLICITES DE QUALITÉ MARCHANDE, D'ADÉQUATION
        À UN USAGE PARTICULIER ET DE NON-INFRACTION. Hopper ne garantit pas que
        les Services HTS Connect fonctionneront sans erreur ou sans temps
        d'arrêt. Hopper peut mettre en pause ou interrompre les Services HTS
        Connect à tout moment, et les utilisateurs doivent s'attendre à des
        temps d'arrêt périodiques pour les mises à jour. Hopper ne garantit ni
        ne fait aucune déclaration concernant l'utilisation ou les résultats des
        Services HTS Connect en matière d'exactitude, de précision, de
        fiabilité, de rapidité, d'exhaustivité, d'actualité ou autrement, y
        compris, mais sans s'y limiter, la restriction, la sécurité, la qualité
        et/ou l'échéance de tout service de voyage acheté auprès de tiers via
        les Services HTS Connect. Hopper ne sera pas responsable des retards,
        des dommages ou des défaillances résultant de causes échappant au
        contrôle raisonnable de Hopper, y compris, mais sans s'y limiter, les
        retards et autres problèmes inhérents à l'utilisation d'Internet et de
        l'envoi de messages électroniques, les cas de force majeure, ou les
        actions ou omissions de tiers. Tout le contenu des Services HTS Connect
        est fourni à titre informatif uniquement. Vous fier aux informations
        fournies via les Services HTS Connect est à vos propres risques, y
        compris, mais sans s'y limiter, aux informations sur les services de
        voyage tiers, ainsi qu'aux prédictions et aux recommandations de Hopper.
        Bien qu'imprévus, des erreurs et des bogues peuvent se produire dans les
        Services HTS Connect de temps à autre, pouvant avoir un impact sur votre
        réservation. Les prix pourraient donc être erronés ou vous pourriez
        recevoir d'autres offres. Hopper se réserve le droit de corriger toute
        erreur de prix dans les Services HTS Connect et/ou les réservations en
        attente effectuées avec un prix inexact, et il est à la seule discrétion
        de Hopper d'honorer toute erreur de prix.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        9.2. LA LOI APPLICABLE PEUT NE PAS PERMETTRE L'EXCLUSION DES GARANTIES
        IMPLICITES, DONC CERTAINES OU TOUTES LES EXCLUSIONS CI-DESSUS PEUVENT NE
        PAS S'APPLIQUER PLEINEMENT À VOUS.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        10. Limitations de responsabilité
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        10.1. DANS TOUTE LA MESURE PERMISE PAR LA LOI APPLICABLE, HOPPER NE SERA
        EN AUCUN CAS RESPONSABLE À VOTRE ENDROIT DE TOUT DOMMAGE INDIRECT,
        SPÉCIAL, ACCESSOIRE, PUNITIF, EXEMPLAIRE OU CONSÉCUTIF, OU DE TOUTE
        PERTE OU DOMMAGE QUELS QU'ILS SOIENT (Y COMPRIS LES BLESSURES
        CORPORELLES, LA PERTE DE DONNÉES, LES REVENUS, LES PROFITS, L'ATTEINTE À
        LA RÉPUTATION, L'UTILISATION OU TOUT AUTRE AVANTAGE ÉCONOMIQUE), MÊME SI
        HOPPER A ÉTÉ INFORMÉ AU PRÉALABLE DE LA POSSIBILITÉ DE TELS DOMMAGES,
        DÉCOULANT D'UNE GARANTIE, D'UN CONTRAT, D'UNE NÉGLIGENCE, D'UN DÉLIT OU
        DE TOUTE AUTRE ACTION QUI DÉCOULE DE OU EN LIEN AVEC L'UTILISATION,
        L'INCAPACITÉ D'UTILISER OU L'EXÉCUTION DES SERVICES HTS CONNECT. HOPPER
        DÉCLINE TOUTE RESPONSABILITÉ QUELLE QU'ELLE SOIT EN CAS D'INFORMATIONS,
        DE CONTENUS, DE PRODUITS, DE VOYAGES AÉRIENS OU DE TOUT AUTRE SERVICE OU
        MATÉRIEL DE VOYAGE DISPONIBLE SUR OU VIA LES SERVICES HTS CONNECT, OU
        VIA DES PAGES DE SITES WEB TIERS OU DES SITES WEB SUPPLÉMENTAIRES LIÉS
        AUX SERVICES HTS CONNECT. CES LIMITATIONS S'APPLIQUENT NONOBSTANT TOUT
        ÉCHEC QUANT AU BUT PREMIER DE TOUT RECOURS LIMITÉ. SAUF DANS LES CAS
        INTERDITS PAR LA LOI, LA RESPONSABILITÉ TOTALE DE HOPPER À VOTRE ENDROIT
        POUR TOUS LES DOMMAGES, PERTES ET CAUSES D'ACTION, QU'IL S'AGISSE D'UNE
        GARANTIE, D'UN CONTRAT OU D'UN DÉLIT CIVIL, NE DOIT EN AUCUN CAS
        DÉPASSER (A) LE MONTANT PAYÉ PAR VOUS À HOPPER OU À UN FOURNISSEUR DE
        SERVICES DE VOYAGE TIERS VIA LES SERVICES HTS CONNECT, LE CAS ÉCHÉANT,
        OU (B) 1 000 $ ( SELON LE MONTANT LE PLUS ÉLEVÉ). EN AUCUN CAS HOPPER NE
        SERA RESPONSABLE DE TOUT MANQUEMENT AUX PRÉSENTES CONDITIONS, OU DE TOUT
        MANQUEMENT À FOURNIR LES SERVICES HTS CONNECT, DANS LA MESURE OÙ CE
        MANQUEMENT DÉCOULE DE FACTEURS ÉCHAPPANT AU CONTRÔLE RAISONNABLE DE
        HOPPER.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        10.2. PUISQUE CERTAINES JURIDICTIONS N'AUTORISENT PAS L'EXCLUSION OU LA
        LIMITATION DE RESPONSABILITÉ POUR LES DOMMAGES INDIRECTS OU ACCESSOIRES,
        DANS CES JURIDICTIONS, CETTE RESPONSABILITÉ EST LIMITÉE À TOUTE LA
        MESURE AUTORISÉE PAR LA LOI.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        10.3. VOUS ACCEPTEZ QUE LES EXCLUSIONS DE GARANTIE ET LES LIMITATIONS DE
        RESPONSABILITÉ DANS CETTE SECTION SONT IMPORTANTES, ONT DÛMENT ÉTÉ
        NÉGOCIÉES POUR CES CONDITIONS ET QU'ELLES ONT ÉTÉ PRISES EN COMPTE DANS
        LA DÉTERMINATION DE L'IMPORTANCE À DONNER PAR CHAQUE PARTIE EN VERTU DES
        PRÉSENTES ET DANS LA DÉCISION DE CHAQUE PARTIE DE CONCLURE UN CONTRAT
        AVEC L'AUTRE. VOUS CONVENEZ QUE LES EXCLUSIONS DE GARANTIE ET LES
        LIMITATIONS DE RESPONSABILITÉ DANS CES CONDITIONS GÉNÉRALES
        D'UTILISATION SONT JUSTES ET RAISONNABLES. SAUF DISPOSITION CONTRAIRE
        DANS CETTE SECTION, VOTRE SEUL ET UNIQUE RECOURS CONSISTE À CESSER
        D'UTILISER LES APPLICATIONS ET LES SERVICES HTS CONNECT.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        10.4. REMARQUE IMPORTANTE POUR LES CONSOMMATEURS DU NEW JERSEY : SI VOUS
        ÊTES UN CONSOMMATEUR RÉSIDANT DANS LE NEW JERSEY, LES DISPOSITIONS
        SUIVANTES DES PRÉSENTES CONDITIONS GÉNÉRALES D'UTILISATION NE
        S'APPLIQUENT PAS À VOUS (ET NE LIMITENT PAS LES DROITS QUE VOUS POURRIEZ
        AVOIR) DANS LA MESURE OÙ ILS SONT INAPPLICABLES EN VERTU DE LA LOI DU
        NEW JERSEY : (A) L'EXONÉRATION DE RESPONSABILITÉ POUR TOUT DOMMAGE
        INDIRECT, PARTICULIER, ACCESSOIRE, PUNITIF, EXEMPLAIRE OU CONSÉCUTIF DE
        QUELQUE NATURE QUE CE SOIT (PAR EXEMPLE, DANS LA MESURE INAPPLICABLE EN
        VERTU DE LA LOI SUR LES DOMMAGES-INTÉRÊTS PUNITIFS DU NEW JERSEY, LA LOI
        SUR LA RESPONSABILITÉ RELATIVE AUX PRODUITS DU NEW JERSEY, LE UNIFORM
        COMMERCIAL CODE DU NEW JERSEY ET LA LOI SUR LA PROTECTION DU
        CONSOMMATEUR DU NEW JERSEY); (B) LA LIMITATION DE RESPONSABILITÉ POUR LA
        PERTE DE PROFITS, OU LA PERTE OU L'UTILISATION ABUSIVE DE TOUTE DONNÉE
        (PAR EXEMPLE, DANS LA MESURE NON APPLICABLE EN VERTU DE LA LOI SUR LA
        PROTECTION CONTRE LE VOL D'IDENTITÉ DU NEW JERSEY ET DE LA LOI SUR LA
        PROTECTION DU CONSOMMATEUR DU NEW JERSEY); (C) L'APPLICATION DES
        LIMITATIONS DE RESPONSABILITÉ AU RECOUVREMENT DES DOMMAGES QUI DÉCOULENT
        D'UN CONTRAT ET D'UN DÉLIT, Y COMPRIS, MAIS SANS S'Y LIMITER, LA
        NÉGLIGENCE, LA RESPONSABILITÉ ABSOLUE OU TOUTE AUTRE THÉORIE (PAR
        EXEMPLE, DANS LA MESURE OÙ DE TELS DOMMAGES SONT RECOUVRABLES PAR UN
        CONSOMMATEUR EN VERTU DE LA LOI DU NEW JERSEY, Y COMPRIS, MAIS SANS S'Y
        LIMITER, LA LOI SUR LA RESPONSABILITÉ RELATIVE AUX PRODUITS DU NEW
        JERSEY); ET (D) LA DISPOSITION SUR LA LOI APPLICABLE AU MASSACHUSETTS
        (PAR EXEMPLE, DANS LA MESURE OÙ VOS DROITS EN TANT QUE CONSOMMATEUR
        RÉSIDANT DANS LE NEW JERSEY DOIVENT ÊTRE RÉGIS PAR LA LOI DU NEW
        JERSEY).
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        10.5. En ce qui a trait à toute transaction non autorisée et frauduleuse
        effectuée en votre nom lors de l'utilisation de la Solution HTS Connect,
        en l'absence de toute violation de ces Conditions par vous, votre droit
        au remboursement de toute perte directe (c'est-à-dire les pertes non
        remboursées par votre entité émettrice du Mode de paiement) comme
        indiqué dans les présentes sera votre seul et unique droit et recours
        pour toute perte ou dommage subi par vous résultant de, en relation avec
        ou lié à toute utilisation frauduleuse ou non autorisée de la Solution
        HTS Connect en votre nom, et Hopper peut conditionner son paiement de
        toute fraude ou remboursement d'utilisation non autorisée à la
        libération de Hopper de toute autre réclamation à cet égard.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        10.6. Une action ou une procédure relative à toute réclamation découlant
        des Services HTS Connect doit être entreprise conformément à la Section
        13.1 dans le délai le plus court du délai de prescription applicable, ou
        un an après que la cause d'action se soit présentée, ou elle sera
        révoquée.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        11. Indemnisation
      </Typography>
      <Typography variant="body2" className={styles.mb}></Typography>
      <Typography variant="body2" className={styles.mb}>
        11.1. Si vous utilisez les Services HTS Connect à des fins commerciales
        et/ou pour effectuer des réservations de voyages au nom d'une tierce
        personne ou d'une tierce partie, vous acceptez d'indemniser et de
        dégager Hopper et ses dirigeants, administrateurs, employés, agents et
        sociétés affiliées de toute réclamation, toute poursuite, toute
        procédure, tout litige, toute demande, toute responsabilité, toute
        dommage, toute perte, tout coût et toute dépense, y compris les frais
        juridiques et comptables raisonnables, causés par, découlant de, ou liés
        à votre utilisation des Services HTS Connect, votre infraction aux
        présentes Conditions ou votre utilisation de tout produit ou service
        acheté via les Services HTS Connect.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        12. RÈGLEMENT DES LITIGES, ARBITRAGE, RENONCIATION DE DROITS
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        12.1.{" "}
        <span className={styles.underline}>
          ARBITRAGE ET RENONCIATION À UNE ACTION COLLECTIVE
        </span>
        . Vous acceptez que toutes les réclamations, et tous les litiges ou
        désaccords qui peuvent découler de l'interprétation ou de l'exécution en
        vertu des présentes Conditions, de tout paiement à Hopper, ou autrement
        liés à votre utilisation des Services HTS Connect et/ou du contenu de
        ceux-ci, doivent être soumis exclusivement à un arbitrage contraignant,
        sauf que chaque partie conserve : (i) le droit d'intenter une action
        individuelle devant un tribunal de petites créances pour les litiges et
        les actions relevant de la compétence de ce tribunal; et (ii) le droit
        de demander une injonction ou une autre mesure équitable devant un
        tribunal compétent pour empêcher la violation ou l'appropriation
        illicite réelle ou potentielle des droits d'auteur, marques de commerce,
        secrets commerciaux, brevets ou autres droits de propriété
        intellectuelle. L'arbitrage signifie qu'un arbitre et non un juge ou un
        jury statuera sur la réclamation. Vos droits à l'échange d'informations
        et aux appels préalables à l'audience peuvent également être limités en
        arbitrage. VOUS RECONNAISSEZ ET ACCEPTEZ QUE VOUS ET HOPPER RENONCEZ
        CHACUN À VOTRE DROIT À UN PROCÈS DEVANT JURY. Vous reconnaissez et
        acceptez en outre que vous renoncez à votre droit de participer en tant
        que demandeur ou membre du groupe à toute action collective ou procédure
        de représentation. De plus, à moins que vous et Hopper n'en conveniez
        autrement par écrit, l'arbitre ne peut pas regrouper la réclamation de
        plus d'une personne, et ne peut présider autrement aucune forme de
        procédure de groupe ou de représentation. Si cette renonciation à
        l'action collective est jugée inapplicable, alors l'intégralité de cette
        section « Règlement des litiges » sera réputée nulle. Sous réserve des
        dispositions de la phrase précédente, cette section « Règlement des
        litiges » survivra à toute résiliation des présentes Conditions.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        12.2. <span className={styles.underline}>Règlements d'arbitrage</span>.
        Tout arbitrage sera administré par l'American Arbitration Association («
        l'AAA »). Sauf modification par la présente disposition de « Règlement
        des différends », l'AAA administrera l'arbitrage conformément soit (A)
        aux Règles d'arbitrage commerciales alors en vigueur, soit (B) aux
        Règles d'arbitrage de la protection du consommateur alors en vigueur
        s'il s'agit d'une « entente avec le consommateur », telles que définies
        par la Règle d'arbitrage de la protection du consommateur R-1 (ci-après
        dénommées, les « Règles applicables de l'AAA »). (Vous pouvez consulter
        les Règles applicables de l'AAA au{" "}
        <a href="https://www.adr.org/Rules" target="_blank" rel="noreferrer">
          https://www.adr.org/Rules
        </a>{" "}
        ou en appelant l'AAA au 1 800 778-7879). Le Federal Arbitration Act
        régira l'interprétation et l'application de l'ensemble de cette
        disposition de « Règlement des litiges ».
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        12.3. <span className={styles.underline}>Processus d'arbitrage</span>.
        Une partie qui souhaite initier un arbitrage doit fournir à l'autre
        partie une Demande d'arbitrage écrite, tel que spécifié dans les Règles
        applicables de l'AAA. (L'AAA met en ligne des formulaires applicables
        pour les Demandes d'arbitrage au{" "}
        <a
          href="https://www.adr.org/sites/default/files/Demand_for_Arbitration_0.pdf"
          target="_blank"
          rel="noreferrer"
        >
          https://www.adr.org/sites/default/files/Demand_for_Arbitration_0.pdf
        </a>{" "}
        (Règles d'arbitrage commerciales) et au{" "}
        <a
          href="https://www.adr.org/sites/default/files/Consumer_Demand_for_Arbitration_Form_1.pdf"
          target="_blank"
          rel="noreferrer"
        >
          https://www.adr.org/sites/default/files/Consumer_Demand_for_Arbitration_Form_1.pdf
        </a>{" "}
        (Règles d'arbitrage de la protection du consommateur), et une
        déclaration sous serment distincte pour la renonciation aux frais pour
        les résidents de la Californie uniquement est disponible au{" "}
        <a
          href="https://www.adr.org/sites/default/files/AAA%20Affidavit%20for%20Waiver%20of%20Fees%20Notice%20California%20Consumers.pdf"
          target="_blank"
          rel="noreferrer"
        >
          https://www.adr.org/sites/default/files/AAA%20Affidavit%20for%20Waiver%20of%20Fees%20Notice%20California%20Consumers.pdf.
        </a>{" "}
        L'arbitre sera soit un juge à la retraite soit un avocat autorisé à
        pratiquer le droit dans le comté où vous résidez, et il sera choisi
        conformément aux Règles applicables de l'AAA. Si les parties n'arrivent
        pas à choisir ensemble un arbitre dans les quatorze (14) jours suivant
        l'avis envoyé par l'AAA aux parties de sa réception de la Demande
        d'arbitrage, l'AAA nommera l'arbitre conformément aux Règles de l'AAA.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        12.4.{" "}
        <span className={styles.underline}>Lieu et procédure d'arbitrage</span>.
        À moins que vous et Hopper n'en conveniez autrement, l'arbitrage se
        déroulera dans le comté où vous résidez. Si votre réclamation ne dépasse
        pas 10 000 $ , alors l'arbitrage sera mené uniquement sur la base des
        documents que vous et Hopper soumettez à l'arbitre, à moins que vous
        demandiez une audience ou que l'arbitre détermine qu'une audience est
        nécessaire. Si votre réclamation dépasse 10 000 $ , votre droit à une
        audience sera déterminé par les Règles de l'AAA applicables. Sous
        réserve des Règles de l'AAA applicables, l'arbitre aura le pouvoir
        discrétionnaire d'ordonner un échange raisonnable d'informations entre
        les parties, conformément à la nature accélérée de l'arbitrage.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        12.5. <span className={styles.underline}>Décision de l'arbitre</span>.
        L'arbitre rendra une sentence dans le délai spécifié dans les Règles de
        l'AAA applicables. La décision de l'arbitre comprendra les constatations
        et conclusions essentielles sur lesquelles l'arbitre s'est basé pour
        rendre la sentence. Le jugement sur la sentence de l'arbitrage peut être
        rendu dans tout tribunal compétent en la matière. L'attribution de
        dommages-intérêts et/ou d'autres réparations par l'arbitre doit être
        conforme aux conditions de la section « Limitations de responsabilité »
        ci-dessus en ce qui a trait aux types et aux montants des
        dommages-intérêts ou d'autres réparations pour lesquels une partie peut
        être tenue responsable. L'arbitre peut uniquement accorder des mesures
        déclaratoires ou injonctives en faveur du demandeur et uniquement dans
        la mesure nécessaire pour fournir la réparation justifiée par la
        réclamation individuelle du demandeur. Si l'arbitre détermine que vous
        êtes la partie gagnante de l'arbitrage, et seulement si c'est autorisé
        en vertu de la loi applicable, l'arbitre peut adjuger les honoraires et
        les frais d'avocat raisonnables. Hopper ne cherchera pas à faire valoir,
        et renonce par la présente à, tous les droits qu'Hopper peut avoir en
        vertu de la loi applicable pour récupérer les honoraires et les frais
        d'avocat si Hopper gagne l'arbitrage, à moins que l'arbitre ne juge que
        le contenu de votre réclamation ou la réparation demandée dans votre
        Demande d'arbitrage était frivole ou a été entreprise dans un but
        inapproprié (tel qu'évalué en vertu des normes énoncées dans la Règle
        fédérale de procédure civile 11(b)).
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        12.6. <span className={styles.underline}>Frais</span>. Votre
        responsabilité de payer tous les frais de dépôt, administratifs et
        d'arbitrage de l'AAA sera uniquement telle qu'énoncée dans les Règles de
        l'AAA applicables. Toutefois, si votre réclamation en dommages-intérêts
        ne dépasse pas 25 000 $ , Hopper paiera tous ces frais, à moins que
        l'arbitre ne juge que le contenu de votre réclamation ou la réparation
        demandée dans votre Demande d'arbitrage était frivole ou a été
        entreprise dans un but inapproprié (tel qu'évalué en vertu des normes
        énoncées dans la Règle fédérale de procédure civile 11(b)).
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        12.7. <span className={styles.underline}>Modifications</span>. Hopper se
        réserve le droit de modifier la section « Règlement des litiges », mais
        ces modifications apportées ne s'appliqueront pas aux litiges survenant
        avant la date d'entrée en vigueur d'une telle modification. Nonobstant
        les dispositions des dispositions relatives aux modifications ci-dessus,
        si Hopper modifie la section « Règlement des litiges » après la date à
        laquelle vous avez accepté les Conditions (ou accepté toute modification
        ultérieure des présentes Conditions), vous acceptez que votre
        utilisation continue des Services HTS Connect après cette modification
        constituera votre acceptation de ces modifications. Si vous n'acceptez
        pas la modification, vous pouvez rejeter la modification en envoyant un
        avis écrit à Hopper par la poste ou en main propre à : Hopper, à
        l'attention de : Département juridique, 265, Franklin Street, bureau
        1702, Boston (MA) 02110, ou par courriel en utilisant l'adresse courriel
        associée à votre compte à : legal@hopper.com, dans les 30 jours suivant
        la date à laquelle cette modification est entrée en vigueur, tel
        qu'indiqué dans les présentes. Pour être valide, l'avis doit inclure
        votre nom complet et indiquer clairement votre intention de rejeter les
        modifications apportées à la section « Règlement des litiges ». En
        rejetant les modifications, vous acceptez de soumettre en arbitrage tout
        litige entre vous et Hopper conformément aux dispositions de la section
        « Règlement des litiges » à compter de la date à laquelle vous avez
        accepté les présentes Conditions (ou accepté toute modification
        ultérieure des Conditions, le cas échéant).
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        13. Conditions supplémentaires.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        13.1. Les présentes Conditions constituent l'intégralité de l'entente
        entre les parties quant à son objet, et elles ont préséance sur toutes
        les déclarations et toutes les discussions écrites et orales préalables
        entre les parties. Les Conditions sont régies par les lois du
        Commonwealth du Massachusetts sans référence aux principes de conflits
        de lois, sauf disposition contraire ci-dessus dans la Section 13. Aucun
        retard ou aucune omission de la part de Hopper dans l'exercice de l'un
        de ses droits découlant d'une non-conformité ou d'un manquement de votre
        part à ce qui a trait aux présentes Conditions ne portera atteinte à ce
        droit ou ne sera interprété comme une renonciation à celui-ci, et une
        renonciation de la part de Hopper à l'une des clauses, conditions ou
        ententes devant être exécutées par vous ne sera pas interprétée comme
        une renonciation à toute violation ultérieure de celui-ci ou de toute
        autre clause, condition ou entente contenues dans les présentes. Vous ne
        pouvez céder aucun de vos droits découlant des présentes Conditions sans
        le consentement écrit de Hopper. Sauf dans les cas prévus dans la
        Section 13 ci-dessus (Règlement des litiges), si une disposition des
        présentes Conditions est jugée invalide ou inapplicable par un tribunal
        compétent, les présentes Conditions resteront alors pleinement en
        vigueur et seront restructurées pour être valides et exécutoires tout en
        reflétant l'intention des parties dans toute la mesure permise par la
        loi. Les dispositions des présentes Conditions sont conclues au bénéfice
        de Hopper et vous, et aucun tiers ne sera bénéficiaire des Conditions.
        En cas de conflit entre les présentes Conditions et toute autre entente
        ou toutes autres conditions ou politiques de Hopper qui vous sont
        fournies, directement ou indirectement, ces Conditions ont préséance.
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        14. Langue
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        14.1 Les parties aux présentes reconnaissent avoir convenu que la
        présente entente et les documents connexes soient rédigés en langue
        anglaise.{" "}
        <span className={styles.bold}>
          Vous et Hopper avez accepté que ces Conditions et que tous les
          documents connexes soient rédigés en anglais.
        </span>
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.bold, styles.mb, styles.center)}
      >
        15. Mises à jour et modifications.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        15.1 Hopper peut apporter des modifications ou des mises à jour aux
        présentes Conditions à tout moment et à sa discrétion. Vous convenez que
        si vous n'acceptez pas une modification, vous ne devez pas réserver via
        les Services HTS Connect. Le non-respect de cette obligation ou la
        poursuite de l'utilisation d'un Service HTS Connect, constituera une
        acceptation des présentes Conditions telles que modifiées, sauf dans les
        cas prévus dans la Section 13.7.
      </Typography>
      <Typography variant="body2" className={styles.mb}>
        Date d'entrée en vigueur des présentes Conditions générales
        d'utilisation : version 1.1; Août 16, 2024
      </Typography>
    </>
  );
};
