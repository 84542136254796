import { Language } from "@hopper-b2b/types";
import {
  Button,
  FormControl,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useI18nContext } from "./hooks/useI18nContext";
import "./styles.scss";
import { setLang, updateLang } from "./utils/getLang";

export enum LanguageSelectType {
  FORM,
  DROPDOWN,
  RADIO,
}

interface ILanguageSelectProps {
  supportedLanguages?: Array<Language>;
  defaultLocale?: string;
  type?: LanguageSelectType;
  onChange?: (locale: string) => void;
}

const defaultLanguage = { key: "en", name: "English", label: "English" };

export const LanguageSelect = ({
  supportedLanguages = [defaultLanguage],
  defaultLocale = "en",
  type = LanguageSelectType.FORM,
  onChange = window?.location?.reload.bind(window.location),
}: ILanguageSelectProps) => {
  const [storageAvailable, setStorageAvailable] = useState(true);

  useEffect(() => {
    try {
      // Testing to see if localstorage is available
      updateLang();
    } catch (e) {
      setStorageAvailable(false);
    }
  }, []);

  const onLocaleSelect = useCallback(
    (locale: string) => {
      onChange?.(locale);
      setLang(locale);
    },
    [onChange]
  );

  const handleLocaleSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onLocaleSelect(e.target.value),
    [onLocaleSelect]
  );

  // Hide component if storage is unavailable
  //TODO: Stop hiding when user preference service is available
  if (!storageAvailable) {
    return null;
  }

  const typeMap = {
    [LanguageSelectType.FORM]: (
      <LanguageSelectForm
        defaultLocale={defaultLocale}
        supportedLanguages={supportedLanguages}
        handleLocaleSelect={handleLocaleSelect}
      />
    ),
    [LanguageSelectType.DROPDOWN]: (
      <LanguageSelectDropdown
        supportedLanguages={supportedLanguages}
        onLocaleSelect={onLocaleSelect}
      />
    ),
    [LanguageSelectType.RADIO]: (
      <LanguageSelectRadio
        defaultLocale={defaultLocale}
        supportedLanguages={supportedLanguages}
        handleLocaleSelect={handleLocaleSelect}
      />
    ),
  };

  return typeMap[type];
};

type LanguageSelectFormProps = {
  handleLocaleSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & Omit<ILanguageSelectProps, "type">;

const LanguageSelectForm = ({
  supportedLanguages,
  defaultLocale,
  handleLocaleSelect,
}: LanguageSelectFormProps) => {
  const { t } = useI18nContext();

  return (
    <form className="b2b-LanguageSelect">
      <fieldset>
        <legend>{t("languages")}</legend>
        {supportedLanguages?.map((l) => (
          <div
            key={l.key}
            className={`b2b-LanguageSelectItem ${
              defaultLocale === l.key ? "selected" : ""
            }`}
          >
            <input
              type="radio"
              name="locale"
              id={l.key}
              value={l.key}
              defaultChecked={defaultLocale === l.key}
              onChange={handleLocaleSelect}
            />
            <label htmlFor={l.key}>{l.label}</label>
          </div>
        ))}
      </fieldset>
    </form>
  );
};

const LanguageSelectRadio = ({
  supportedLanguages,
  defaultLocale,
  handleLocaleSelect,
}: LanguageSelectFormProps) => {
  const { t } = useI18nContext();

  return (
    <FormControl className="b2b-LanguageSelectRadio">
      <legend>{t("languages")}</legend>
      <RadioGroup
        aria-labelledby="lang-radio-buttons-group-label"
        defaultValue={defaultLocale}
        name="language-buttons-group"
        onChange={handleLocaleSelect}
      >
        {supportedLanguages?.map((l) => (
          <FormControlLabel
            key={l.key}
            name="locale"
            id={l.key}
            value={l.key}
            defaultChecked={defaultLocale === l.key}
            checked={defaultLocale === l.key}
            control={<Radio size="small" color="primary" />}
            label={l.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

type LanguageSelectDropdownProps = {
  onLocaleSelect: (locale: string) => void;
} & Omit<ILanguageSelectProps, "type">;

const LanguageSelectDropdown = ({
  supportedLanguages,
  onLocaleSelect,
}: LanguageSelectDropdownProps) => {
  const { language } = useI18nContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onSetLanguage = useCallback(
    (language: string) => {
      onCloseMenu();
      onLocaleSelect(language);
    },
    [onLocaleSelect]
  );

  return (
    <>
      <Button variant="outlined" onClick={onOpenMenu}>
        {language}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onCloseMenu}
      >
        {supportedLanguages?.map((language: Language) => (
          <MenuItem
            key={language.key}
            onClick={() => {
              onSetLanguage(language.key);
            }}
          >
            <Typography>{language.key}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
