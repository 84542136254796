import { throttle } from "lodash-es";

const UPDATE_THROTTLE_DURATION = 1000;

export const updateContactInfo = throttle(
  ({ countryCode, phoneNumber, email, callback }) => {
    callback?.({
      countryCode,
      phoneNumber,
      email,
    });
  },
  UPDATE_THROTTLE_DURATION
);
