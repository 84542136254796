import { assign } from "xstate";
import { Product } from "@b2bportal/purchase-api";
import {
  ContactSelectors,
  LodgingSelectors,
  ParentState,
  PassengerSelectors,
  PriceChangeType,
  cartHelpers,
} from "../../../index";
import {
  getHasPriceChanged,
  getObjectKeysAsObject,
  setContextWithKey,
} from "../../../helpers";
import { LodgingContext } from "./types";

import { getSelectedOpaqueRoomRate } from "./selectors";
import { LodgingQuoteRequest } from "@b2bportal/lodging-api";
import { getEnvVariables } from "@hopper-b2b/utilities";

export const actions = {
  addLodgingProduct: assign((context: LodgingContext, _event: unknown) => {
    const selectedUsers = PassengerSelectors.getAllSelectedUserPassengers({
      context,
    });
    const selectedOpaqueRoomRate = getSelectedOpaqueRoomRate({ context });

    if (!selectedUsers[0]?.id || !selectedOpaqueRoomRate) {
      return context;
    }

    const contactInformation = ContactSelectors.getContactInformation({
      context,
    });

    const params: LodgingQuoteRequest = {
      quoteRequest: selectedOpaqueRoomRate,
      personId: selectedUsers[0].id,
      additionalGuests: [],
    };

    const hotelProduct = {
      type: Product.Hotel,
      value: params,
    };
    const ctxWithHotel = cartHelpers.addQuoteProduct(hotelProduct, context);

    return setContextWithKey(
      ctxWithHotel,
      `${ParentState.cartQuote}.customerEmail`,
      contactInformation?.email
    );
  }),

  checkForPriceChange: assign((context: LodgingContext, _event) => {
    const selectedRoom = context.lodgingShop?.selectedRoom?.products?.[0];
    const shopPricing = selectedRoom?.total?.fiat;
    const quotedPricing = LodgingSelectors.getQuotedLodgingProductPricingV1({
      context,
    });

    const predictedTotal = shopPricing ? shopPricing.value : 0;
    const priceQuoteTotal = quotedPricing?.tripTotal?.fiat?.value
      ? quotedPricing.tripTotal.fiat.value
      : 0;

    const difference = Math.abs(priceQuoteTotal - predictedTotal);

    // Tenant defines allowed price change difference in respective .env file
    const tenantDifferenceAllowed = getEnvVariables(
      "hotelPriceChangeDiff"
    ) as string;
    const allowed = tenantDifferenceAllowed.split(",");
    const allowedValue = allowed?.[0] ? parseInt(allowed[0], 10) : Infinity;
    const allowedCurrency = allowed?.[1] ? allowed[1] : "USD";
    const allowedDifference = {
      value: allowedValue,
      currencyCode: allowedCurrency,
    };

    const actualDifference = {
      value: difference,
      currencyCode: quotedPricing?.tripTotal?.fiat?.currencyCode
        ? quotedPricing.tripTotal.fiat.currencyCode
        : "USD",
    };
    const hasDifference = getHasPriceChanged(
      allowedDifference,
      actualDifference
    );

    const differenceString = difference.toFixed();
    const priceChange: PriceChangeType = {
      hasDifference,
      predictedTotal,
      priceQuoteTotal,
      ...(hasDifference
        ? {
            difference,
            differenceString,
          }
        : {}),
    };

    return setContextWithKey(context, `${ParentState.cartQuote}.priceChange`, {
      ...context.cartQuote.priceChange,
      priceChange,
    });
  }),
};

export const ActionTypes = getObjectKeysAsObject(actions);
