import { ReactComponent as HelpCenterFAQ } from "./../../assets/client/flair/question-circled.svg";
import { ReactComponent as BaggageInfoFAQ } from "./../../assets/client/flair/checked-bag.svg";
import { ReactComponent as LostFlightFAQ } from "./../../assets/client/flair/disruption-guarantee.svg";
import { ReactComponent as ExternalLink } from "./../../assets/client/external-link.svg";
import { PATH_FLY_FLAIR } from "../../utils/constants";

export const LANGUAGE_HELP_CENTER_MAP = {
  en: "en_us",
  fr: "fr",
};

export const HELP_CENTER_FAQS_LINK_PATH = "/categories/HkzihUYMA";

export const FAQ_BUTTONS = [
  {
    Icon: ExternalLink,
    title: "homeScreen.links.flyFlairCard.title",
    subtitle: "homeScreen.links.flyFlairCard.subtitle",
    href: PATH_FLY_FLAIR,
  },
  {
    Icon: HelpCenterFAQ,
    title: "homeScreen.links.helpCenter.title",
    subtitle: "homeScreen.links.helpCenter.subtitle",
    href: HELP_CENTER_FAQS_LINK_PATH,
  },
  {
    Icon: LostFlightFAQ,
    title: "homeScreen.links.lostConnection.title",
    subtitle: "homeScreen.links.lostConnection.subtitle",
    href: "/By8ywjFzC",
  },
  {
    Icon: BaggageInfoFAQ,
    title: "homeScreen.links.baggageInfo.title",
    subtitle: "homeScreen.links.baggageInfo.subtitle",
    href: "/ByTwritGA",
  },
];
