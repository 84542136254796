import { assign } from "xstate";
import { Product, ProductOpaqueValue } from "@b2bportal/purchase-api";
import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { getDisruptionCartUpdateParams } from "./selectors";
import {
  DisruptionProtectionProductV1,
  InitDisruptionStateEvent,
  PartialParentContextWithAirDisruptionV1,
} from "./types";

export const actions = {
  addDisruptionProducts: assign(
    (context: PartialParentContextWithAirDisruptionV1, _event) => {
      const filterOutDisruptionFromProducts = (
        context[ParentState.cartUpdate].addProducts ?? []
      ).filter((p: ProductOpaqueValue) => p.type !== Product.AirDisruption);
      const selectedOfferQuote =
        context[ParentState.disruptionV1].selectedOfferQuote;
      if (selectedOfferQuote) {
        const disruptionQuoteRequest = getDisruptionCartUpdateParams({
          context,
        });
        const newDisruptionProduct: DisruptionProtectionProductV1 = {
          type: Product.AirDisruption,
          value: disruptionQuoteRequest,
        };
        context[ParentState.cartUpdate].addProducts = [
          ...filterOutDisruptionFromProducts,
          newDisruptionProduct,
        ];
      }
      return context;
    }
  ),
  initDisruptionState: assign(
    (
      context: PartialParentContextWithAirDisruptionV1,
      event: InitDisruptionStateEvent
    ) => {
      context[ParentState.disruptionV1] = event.state;
      return context;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject({
  ...actions,
});
