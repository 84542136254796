import { ParentState } from "@checkout/types";
import { useSessionContext } from "@hopper-b2b/utilities";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import { createMachine, interpret, MachineConfig } from "xstate";

export const CheckoutStateContext = createContext({});

export interface CheckoutStateProviderProps {
  children: ReactNode;
  stateMachine: MachineConfig<any, any, any>;
  actions: any;
  guards: any;
  context: any;
  services: any;
  getInitialContext: any;
  onStateValueChange?: any;
  onPathnameChange?: any;
  validateContext?: (ctx: unknown) => boolean;
}

const cleanContext = (ctx: unknown, initialContext: unknown): unknown => {
  // INFO: Context key values are populated in modules and tenant apps
  // we don't want to reset these values
  const keys = [
    "lodgingShop",
    "rewards",
    "flightSearch",
    "flightShop",
    "sessionInfo",
    "priceFreeze",
    "passengerMap",
    "cancelForAnyReason",
    "changeForAnyReason",
    "featureFlags",
    "disruption",
    ParentState.disruptionV1,
    ParentState.cancelForAnyReasonV1,
    ParentState.contactInformation,
    ParentState.changeForAnyReasonDiscount,
    ParentState.wallet,
    ParentState.priceDrop,
  ];

  const newObj = {};
  Object.keys(ctx).forEach((key) => {
    if (keys.includes(key)) {
      newObj[key] = ctx[key];
    } else {
      newObj[key] = initialContext[key];
    }
  });
  return newObj;
};

export const CheckoutStateProvider = (props: CheckoutStateProviderProps) => {
  const {
    children,
    stateMachine,
    actions,
    guards,
    services,
    // sending context to initialize state machine's context
    context,
    getInitialContext,
    onStateValueChange,
    onPathnameChange,
    validateContext,
  } = props;
  const history = useHistory();
  const { isLoggedIn } = useSessionContext();

  const getMachine = useCallback(() => {
    const machine = {
      ...stateMachine,
      context: cleanContext(context, getInitialContext()),
    };

    return createMachine(machine, { actions, guards, services });
    /**
     * context is intentially left out of the dependencies
     * Adding it would trigger a restart of the state machine.
     */
    /*
      Added isLoggedIn to the dependencies to refetch session context when a user
      logs in/out during the checkout flow
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateMachine, getInitialContext, actions, guards, services, isLoggedIn]);

  const [service, setService] = useState(() =>
    interpret(getMachine(), { devTools: import.meta.env.DEV }).start()
  );
  const isFirstMount = useRef(true);

  useEffect(() => {
    if (isFirstMount.current === true) {
      isFirstMount.current = false;
    } else {
      setService(
        interpret(getMachine(), { devTools: import.meta.env.DEV }).start()
      );
    }
    return () => {
      service.stop();
    };
  }, [getMachine]);

  useEffect(() => {
    const subscription = service.subscribe((state) => {
      onStateValueChange && onStateValueChange(state.value, history);
    });
    return subscription.unsubscribe;
  }, [service]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      onPathnameChange &&
        onPathnameChange(service.state.value, history, service.send);
    });

    return () => unlisten();
  }, []);

  useEffect(() => {
    if (validateContext !== undefined && !validateContext(context)) {
      history.replace("/");
    }
  }, [context, history, validateContext]);

  return (
    <CheckoutStateContext.Provider value={service}>
      {children}
    </CheckoutStateContext.Provider>
  );
};
