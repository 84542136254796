import {
  Branding,
  ChatAvailability,
  ClientName,
  ContactSupportConfig,
  Currency,
  FlightShopCardType,
  I18nNamespace,
  TranslationLanguage,
} from "@hopper-b2b/types";
import { DEFAULT_LANG } from "../utils/constants";

const contactSupport: ContactSupportConfig = {
  chatAvailability: ChatAvailability.None,
  displaySupportNumber: "+1 833-566-3205",
  intlSupportNumber: "13476954555",
  supportNumber: "+1 833-566-3205",
};

const supportedLanguages = [
  {
    key: TranslationLanguage["en-US"],
    name: "English",
    label: "English",
  },
  {
    key: TranslationLanguage.fr,
    name: "French",
    label: "Français",
  },
];

export const branding: Branding = {
  default: DEFAULT_LANG,
  contactSupport,
  merchantPayments: "",
  clientName: ClientName.FLAIR,
  currencies: [
    {
      locale: "en-US",
      code: Currency.USD,
      // For more info on symbolOverride and localeOverride, see https://hopper-jira.atlassian.net/wiki/spaces/IEP/pages/6205308931/Tenant-specific+currency+symbols
      // Always use `US$` for USD. The default is to use `$` which is not what we want for Volaris.
      symbolOverride: "USD $",
      // Always format the currency as if the user's locale is en-US
      localeOverride: "en-US",
    },
    {
      locale: "fr",
      code: Currency.CAD,
      // For more info on symbolOverride and localeOverride, see https://hopper-jira.atlassian.net/wiki/spaces/IEP/pages/6205308931/Tenant-specific+currency+symbols
      // @hopper-i18n/formatter#formatCurrency uses `MX$` by default so there is no need to specify the override here.
      // We add this override just for easier understanding of what is going on
      symbolOverride: "CAD $",
      // Always format the currency as if the user's locale is en-US
      localeOverride: "en-US",
    },
  ],
  supportedLanguages,
  flightShopCardType: FlightShopCardType.MINI,
  [I18nNamespace.translation]: {
    "en-US": {
      portalName: "Flair by HTS",
      urls: {
        termsAndConditions: "https://hopper.com/legal/terms-and-conditions",
        privacyPolicy: "https://hopper.com/legal/privacy-policy",
        missedConnectionTerms:
          "https://hopper.com/legal/Missed-Connection-Guarantee",
        helpCenterBaseUrl: "https://hopper-cloud-flair.kustomer.help/",
      },
      preferredCountryCode: "US",
      preferredAreaCode: "+1",
      calendarMonthFormat: "MMMM - YYYY",
      timeFormat: "HH:mm",
    },
    fr: {
      portalName: "Flair por HTS",
      urls: {
        termsAndConditions:
          "https://www.hopper.com/es/legal/terms-and-conditions/",
        privacyPolicy: "https://hopper.com/fr/legal/privacy-policy",
        missedConnectionTerms:
          "https://hopper.com/es/legal/Missed-Connection-Guarantee",
        helpCenterBaseUrl: "https://hopper-cloud-flair.kustomer.help/",
      },
      preferredCountryCode: "US",
      preferredAreaCode: "+1",
      calendarMonthFormat: "MMMM - YYYY",
      timeFormat: "HH:mm",
    },
  },
};
