import { TrackingPropertiesType } from "@hopper-b2b/types";
import {
  USER_CAMPAIGN_KEY,
  USER_MEDIUM_KEY,
  getDeviceData,
  getUserDeviceData,
  useNavigate,
} from "@hopper-b2b/utilities";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { ReactNode, useCallback, useEffect } from "react";
import {
  DEVICE_ID_HEADER,
  getDeviceId,
  validateAndSetDeviceId,
} from "./deviceId";
import { setUserLocation } from "./userLocation";

const USER_SOURCE_KEY = "user_source";
const PORTAL_UNAUTHORIZED_PATH = "/auth/invalidsession/";

const apiVersionPrefix = "/api/v0";
const analyticsApiPrefix = `${apiVersionPrefix}/tracking`;
const analyticsEventApi = `${analyticsApiPrefix}/event`;

export interface ClientHintHeaders {
  // https://wicg.github.io/responsive-image-client-hints/#sec-ch-width
  // Remove when sec-ch-width is released and supported
  "X-Sec-CH-Viewport-Width"?: number;
}

export interface Headers extends ClientHintHeaders {
  "Content-Type"?: string;
  "Accept-Language"?: string;
  "Hopper-Session"?: string;
  "Tenant-Session"?: string;
}

export interface AxiosInterceptorsProps {
  children?: ReactNode;
  trackingProperties: TrackingPropertiesType | undefined;
  additionalTrackingProperties?: TrackingPropertiesType | undefined;
  userSource: string | null;
  userMedium?: string;
  userCampaign?: string;
  isSignedIn?: boolean;
  isGuestUser?: boolean;
  delegatedTo?: string;
  tenant: string;
  currency: string;
  requestHeaders: Headers;
  version: string;
  logRequest?: (res: AxiosRequestConfig) => void;
  logResponse?: (res: AxiosResponse) => void;
  logError?: (error: AxiosError) => void;
  errorAction?: (error: AxiosError) => Promise<AxiosError | AxiosResponse>;
}

function addTrackingProperties(
  trackingProperties: TrackingPropertiesType | undefined,
  properties?: object
): object {
  if (!properties) {
    properties = {};
  }
  if (trackingProperties) {
    properties["experiments"] = [
      ...(Array.isArray(properties["experiments"])
        ? properties["experiments"]
        : []),
      ...Object.keys(trackingProperties).map(
        (key: string) => `${key}_${trackingProperties[key]}`
      ),
    ];
  }

  return properties;
}

export const axiosInstance: AxiosInstance = axios.create();

export const AxiosInterceptors = ({
  children,
  delegatedTo,
  isSignedIn,
  isGuestUser,
  tenant,
  currency,
  trackingProperties,
  additionalTrackingProperties,
  userSource,
  userMedium,
  userCampaign,
  requestHeaders,
  version,
  logRequest,
  logResponse,
  logError,
  errorAction,
}: AxiosInterceptorsProps) => {
  const navigate = useNavigate();

  const handleReq = useCallback(
    (req) => {
      const deviceId = getDeviceId();
      const headers: Headers = {
        "Content-Type": "application/json",
        ...(deviceId ? { [DEVICE_ID_HEADER]: deviceId } : {}),
        ...requestHeaders,
      };
      let properties = {};
      if (req.url === analyticsEventApi) {
        properties = {
          ...req.data.properties,
          ...getUserDeviceData(),
          device_type: getDeviceData().type,
          is_agent_session: Boolean(delegatedTo),
          delegated_to: delegatedTo,
          guest_user: isGuestUser,
          signed_in: isSignedIn,
          currency,
          tenant,
          app_version: version,
          referrer_url: document.referrer,
          referrer_url_host: document.referrer
            ? new URL(document.referrer).hostname
            : "",
        };
        if (additionalTrackingProperties) {
          properties = {
            ...properties,
            ...additionalTrackingProperties,
          };
        }
        if (trackingProperties) {
          properties = addTrackingProperties(trackingProperties, properties);
        }

        if (userSource) {
          properties[USER_SOURCE_KEY] = userSource;
          properties["utm_source"] = userSource;
        }
        if (userMedium) {
          properties[USER_MEDIUM_KEY] = userMedium;
          properties["utm_medium"] = userMedium;
        }

        if (userCampaign) {
          properties[USER_CAMPAIGN_KEY] = userCampaign;
          properties["utm_campaign"] = userCampaign;
        }
        req.data.properties = properties;
      }

      req.headers = { ...req.headers, ...headers };

      if (req.url !== analyticsEventApi && logRequest) {
        logRequest(req);
      }
      return req;
    },
    [
      requestHeaders,
      delegatedTo,
      tenant,
      currency,
      trackingProperties,
      userSource,
    ]
  );

  const handleRes = useCallback((res: AxiosResponse) => {
    if (res.config.url !== analyticsEventApi && logResponse) {
      logResponse(res);
    }
    validateAndSetDeviceId(res.headers);
    setUserLocation(res.headers);
    return res;
  }, []);

  const handleError = useCallback(
    (error: AxiosError) => {
      // Don't handle tracking events errors to prevent ad block issues
      logError?.(error);
      if (
        !error.request?.responseURL.includes("tracking/event") &&
        error.response?.status === 401
      ) {
        if (errorAction) {
          return errorAction(error);
        }
        navigate(PORTAL_UNAUTHORIZED_PATH);
      }
      return Promise.reject(error);
    },
    [PORTAL_UNAUTHORIZED_PATH]
  );

  useEffect(() => {
    const requestInterceptor = {
      instance: axiosInstance.interceptors.request.use(handleReq, handleError),
      global: axios.interceptors.request.use(handleReq, handleError),
    };

    const responseInterceptor = {
      instance: axiosInstance.interceptors.response.use(handleRes, handleError),
      global: axios.interceptors.response.use(handleRes, handleError),
    };

    //runs when component unmount
    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor.instance);
      axiosInstance.interceptors.response.eject(responseInterceptor.instance);
      axios.interceptors.request.eject(requestInterceptor.global);
      axios.interceptors.response.eject(responseInterceptor.global);
    };
  }, [delegatedTo, requestHeaders, tenant, trackingProperties, userSource]);

  return <>{children}</>;
};
