import { BrandNames } from "@hopper-b2b/types";
import { useI18nextContext } from "../I18nProvider";
import { setLang } from "../utils/getLang";
import { useI18nCurrency } from "./useI18nCurrency";
import {
  B2B_CURRENCY_PREFERENCE_KEY,
  B2B_LOCALE_PREFERENCE_KEY,
} from "../constants";
import { useCallback } from "react";
import {
  formatDateTime as hopperFormatDateTime,
  DateTimeFormatStyle,
} from "@hopper-i18n/formatter";

export { DateTimeFormatStyle };

export function useI18nContext() {
  const ctx = useI18nextContext();
  if (ctx === undefined) throw new Error(`must be used within a I18nProvider`);
  const {
    language,
    t,
    options: { resources, fallbackLng },
  } = ctx;

  let brand = resources?.[language]?.["brand"] as BrandNames;

  const supported = brand?.supportedLanguages?.some((l) => l.key === language);
  if (!supported) {
    // Use the i18n fallback if tenant does not support this language
    const fallback = fallbackLng?.[0];
    brand = resources?.[fallback]?.["brand"] as BrandNames;
    setLang(fallback);
    window.location.reload();
  }

  const { formatCurrency, formatFiatCurrency, formatRewardsCurrency } =
    useI18nCurrency();

  const setLanguage = useCallback((language: string) => {
    localStorage.setItem(B2B_LOCALE_PREFERENCE_KEY, language);
    window.location.reload();
  }, []);

  const setCurrency = useCallback((currencyCode: string) => {
    localStorage.setItem(B2B_CURRENCY_PREFERENCE_KEY, currencyCode);
    window.location.reload();
  }, []);

  const formatDateTime = useCallback(
    (date: Date, style: DateTimeFormatStyle) =>
      hopperFormatDateTime(date, language, style),
    [language]
  );

  const formatInterval = useCallback(
    (intervalInMinutes: number) => {
      const mins = intervalInMinutes;
      if (mins > 60) {
        const totalHours = Math.floor(mins / 60);
        const totalMins = mins - totalHours * 60;
        return t("intervals.hoursMinutes", {
          hours: totalHours,
          minutes: totalMins,
        });
      } else {
        return t("intervals.minutes", { minutes: mins });
      }
    },
    [t]
  );

  return {
    ...ctx,
    brand,
    formatCurrency,
    formatFiatCurrency,
    formatRewardsCurrency,
    setLanguage,
    setCurrency,
    DateTimeFormatStyle,
    formatDateTime,
    formatInterval,
  };
}
