import {
  initializeLogs,
  initializeRum,
  startRumSessionReplayRecording,
} from "@hopper-b2b/datadog";
import { ClientName } from "@hopper-b2b/types";
import { inspect } from "@xstate/inspect";
import { createRoot } from "react-dom/client";
import { v4 as uuidv4 } from "uuid";
import { Root } from "./Root";
import "./flights-overrides.scss";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import config from "./utils/config";
import { SESSION_ID_KEY } from "./utils/constants";

process.env.NODE_ENV !== "production" &&
  inspect({
    iframe: false,
  });

const sessionId = sessionStorage.getItem(SESSION_ID_KEY)
  ? sessionStorage.getItem(SESSION_ID_KEY)
  : uuidv4();
sessionStorage.setItem(SESSION_ID_KEY, sessionId);
const storeDomain = "flair.com"; // for riskified internal use

// Initialize Datadog Logs
initializeLogs({
  sessionId,
  env: config.ENV,
  version: config.REACT_APP_VERSION,
  service: ClientName.FLAIR,
});

setTimeout(() => {
  initializeRum({
    env: config.ENV,
    version: config.REACT_APP_VERSION,
    service: ClientName.FLAIR,
    enableSessionRecording: true,
    allowedTracingUrls: [
      "https://hopper-web.eng.app-development.h4r.io/flair",
      (url) => url.startsWith("https://flair.staging.hopper.com/api"),
      (url) => url.startsWith("https://connect.flyflair.com/api"),
    ],
  });

  // Start Recording Immediately
  startRumSessionReplayRecording();
}, 1000);

const _sift = (window._sift = window._sift || []);
_sift.push(["_setAccount", config.SIFT_ACCOUNT]);
_sift.push(["_setTrackerUrl", "fpsb.hopper.com"]);
_sift.push(["_setSessionId", sessionId]);
_sift.push(["_trackPageview"]);

const riskifiedScript = document.createElement("script");
riskifiedScript.src =
  ("https:" === document.location.protocol ? "https://" : "http://") +
  "beacon.riskified.com?shop=" +
  storeDomain +
  "&sid=" +
  sessionId;
riskifiedScript.async = true;
riskifiedScript.type = "text/javascript";
document.head.appendChild(riskifiedScript);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
