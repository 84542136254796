import { throttle } from "lodash-es";

const VALIDATE_THROTTLE_DURATION = 1000;

export const validateEmail = throttle(
  ({
    validate,
    emailRegex,
    email,
    validateEmpty = false,
    onInvalid,
    onValid,
  }) => {
    if (!validate) return;

    if (
      email &&
      (emailRegex.test(email) || (email === "" && validateEmpty === false))
    ) {
      onValid();
    } else {
      onInvalid("contactInfoEmailError");
    }
  },
  VALIDATE_THROTTLE_DURATION
);

export const cleanPhoneNumber = (str: string) => str.replace(/\D/g, "");

export const validatePhone = throttle(
  ({
    validate,
    phoneRegex,
    minPhoneLength,
    checkPhoneMinLength,
    phoneNumber,
    validateEmpty = false,
    onInvalid,
    onValid,
  }) => {
    if (!validate) return;

    if (
      phoneNumber &&
      ((checkPhoneMinLength && phoneNumber.length < minPhoneLength) ||
        phoneRegex.test(phoneNumber) ||
        (!phoneNumber && validateEmpty === false))
    ) {
      onValid();
    } else {
      onInvalid("contactInfoPhoneError");
    }
  },
  VALIDATE_THROTTLE_DURATION
);
