import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  FirstDayOfWeek,
  MonthType,
  OnDatesChangeProps,
  useDatepicker,
  useMonth,
} from "@datepicker-react/hooks";
import clsx from "clsx";

import { getLang, useI18nContext } from "@hopper-b2b/i18n";
import { DateTimeFormatStyle } from "@hopper-i18n/formatter";
import { customFormatDateTime } from "@hopper-b2b/utilities";

import AirplaneIcon from "../../../../../../assets/icons/flight/airplane.svg";
import { PickerType } from "../../types";
import { firstWeekDay } from "../../component";
import { Slot } from "../../../../../Slots";
import { generateRenderWeekdayLabels, Month } from "../Month";
import "./styles.scss";

export interface IColumnViewProps {
  startDate: Date | null;
  cleanedEndDate: Date | null;
  setStartDate?: (date: Date | null) => void;
  setEndDate?: (date: Date | null) => void;
  minAllowedDate: Date | null;
  maxAllowedDate: Date | null;
  numberOfMonths: number;
  focusedMonthIndex?: number;
  pickerType?: PickerType;
  priceRangeTags: JSX.Element;
  hideSelectedDateIcon?: boolean;
  startDateLabel?: string;
  endDateLabel?: string;
  className?: string;
  assets?: Record<string, any>;
  startDateIcon?: React.ReactElement;
  endDateIcon?: React.ReactElement;
  overrideScrollOnStart?: boolean;
  isMissingDate?: boolean;
}

export const ColumnView = ({
  startDate,
  cleanedEndDate,
  setStartDate,
  setEndDate,
  minAllowedDate,
  maxAllowedDate,
  numberOfMonths,
  focusedMonthIndex,
  pickerType,
  priceRangeTags,
  hideSelectedDateIcon,
  startDateLabel,
  endDateLabel,
  className,
  assets,
  startDateIcon,
  endDateIcon,
  overrideScrollOnStart,
  isMissingDate = false,
}: IColumnViewProps) => {
  const { t, brand, language } = useI18nContext();
  const [scrolledOnStart, setScrolledOnStart] = useState<boolean>(
    overrideScrollOnStart ?? false
  );
  const monthRefs = Array.from({ length: numberOfMonths }, (_) => {
    return useRef<HTMLDivElement | null>(null);
  });

  const handleDateChange = (props: OnDatesChangeProps) => {
    const { startDate, endDate } = props;
    setStartDate && setStartDate(startDate);
    setEndDate && setEndDate(endDate);
  };

  const { firstDayOfWeek, activeMonths } = useDatepicker({
    firstDayOfWeek: firstWeekDay(getLang()) as FirstDayOfWeek, // 0 is Sunday.
    startDate: minAllowedDate,
    endDate: maxAllowedDate,
    focusedInput: null,
    onDatesChange: handleDateChange,
    numberOfMonths,
  });

  const [firstMonth] = activeMonths;

  const getFormattedWeekdayLabel = useCallback(
    (date: Date) => {
      const formatted = customFormatDateTime(
        date,
        language,
        DateTimeFormatStyle.Custom({
          weekday: "short",
        }),
        brand?.customDateTimeFormats?.calendarDay
      );

      return brand?.customDateTimeFormats?.calendarDay
        ? formatted
        : formatted[0].toLocaleUpperCase(language);
    },
    [language, brand]
  );

  const { weekdayLabels } = useMonth({
    year: firstMonth.year,
    month: firstMonth.month,
    firstDayOfWeek: firstDayOfWeek,
    weekdayLabelFormat: (date: Date) => getFormattedWeekdayLabel(date),
  });

  const currentFocusIndex = useMemo(() => {
    return (
      focusedMonthIndex ??
      // when focusedMonthIndex is not given, use startDate to determine index
      (startDate && minAllowedDate
        ? // left side returns 1 or 0; increment is 12-based
          (startDate.getFullYear() - minAllowedDate.getFullYear()) * 12 +
          (startDate.getMonth() - minAllowedDate.getMonth())
        : 0)
    );
  }, [focusedMonthIndex, minAllowedDate, startDate]);

  useEffect(() => {
    if (!scrolledOnStart) {
      monthRefs[currentFocusIndex]?.current?.scrollIntoView();
      setScrolledOnStart(true);
    }
  }, [scrolledOnStart, currentFocusIndex, monthRefs]);

  const formatDate = useCallback(
    (date: Date) =>
      customFormatDateTime(
        date,
        language,
        DateTimeFormatStyle.ShortMonthDayShortWeekday,
        brand?.customDateTimeFormats?.calendar
      ),
    [language, brand]
  );

  return (
    <div className={clsx("column-view-date-range-picker", className)}>
      <div className="selected-dates-section">
        <Slot
          id="flight-calendar-date-label"
          label={t("depart") + ":"}
          dateSelected={startDate ? formatDate(startDate) : "-"}
          warning={isMissingDate && !startDate}
          icon={assets?.airplaneDepart || assets?.hotelIcon || AirplaneIcon}
          component={
            <div
              className={clsx("selected-date", "start-date", {
                selected: !!startDate,
              })}
            >
              <div
                className={clsx("date-text", {
                  "hide-icon": hideSelectedDateIcon,
                })}
              >
                {startDateIcon ??
                  (!hideSelectedDateIcon ? (
                    <img
                      src={
                        assets?.airplaneDepart ||
                        assets?.hotelIcon ||
                        AirplaneIcon
                      }
                      className="airplane-icon"
                      alt="airplane icon"
                    />
                  ) : null)}
                {startDate
                  ? formatDate(startDate)
                  : startDateLabel || t("depart")}
              </div>
            </div>
          }
        />
        {pickerType === PickerType.RANGE && (
          <Slot
            id="flight-calendar-date-label"
            label={t("return") + ":"}
            dateSelected={cleanedEndDate ? formatDate(cleanedEndDate) : "-"}
            warning={isMissingDate && startDate && !cleanedEndDate}
            icon={assets?.airplaneDepart || assets?.hotelIcon || AirplaneIcon}
            component={
              <div
                className={clsx("selected-date", "end-date", {
                  inactive: !startDate,
                  selected: !!cleanedEndDate,
                })}
              >
                <div
                  className={clsx("date-text", {
                    "hide-icon": hideSelectedDateIcon,
                  })}
                >
                  {endDateIcon ??
                    (!hideSelectedDateIcon ? (
                      <img
                        src={
                          assets?.airplaneDepart ||
                          assets?.hotelIcon ||
                          AirplaneIcon
                        }
                        className="airplane-icon"
                        alt="airplane icon"
                      />
                    ) : null)}
                  {cleanedEndDate
                    ? formatDate(cleanedEndDate)
                    : endDateLabel || t("return")}
                </div>
              </div>
            }
          />
        )}
      </div>
      <div className="price-range-tags-section">{priceRangeTags}</div>
      <div className="weekday-label-section">
        {generateRenderWeekdayLabels({
          month: firstMonth.month,
          year: firstMonth.year,
        })(weekdayLabels)}
      </div>
      <div className="scrollable-months-container">
        {renderMonths(
          activeMonths,
          firstDayOfWeek,
          monthRefs,
          brand?.calendarMonthFormat || "MMM"
        )}
      </div>
    </div>
  );
};

const renderMonths = (
  months: MonthType[],
  firstDayOfWeek: FirstDayOfWeek,
  monthRefs: React.MutableRefObject<HTMLDivElement | null>[],
  monthFormat?: string
) =>
  months.map((month: MonthType, index: number) => (
    <div
      ref={monthRefs[index]}
      key={`${month.year}-${month.month}`}
      className="column-view-month-wrapper"
    >
      <Month
        year={month.year}
        month={month.month}
        firstDayOfWeek={firstDayOfWeek}
        date={month.date}
        hideWeekdayLabels={true}
        headerClassName="column-view-month-label"
        monthFormat={monthFormat}
      />
    </div>
  ));
