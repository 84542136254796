import { useI18nContext } from "@hopper-b2b/i18n";
import { HeaderMenuDropdown } from "@hopper-b2b/ui";

import clsx from "clsx";
import { useCallback } from "react";
import styles from "./CurrencySelectDropdown.module.scss";
import { supportedCurrencies } from "./constants";
import { getCurrency, setCurrency } from "./utils";
import { IconName } from "../Icon";
import { IconComponent } from "../IconComponent";

export const CurrencySelectDropdown = () => {
  const { t } = useI18nContext();
  const currency = getCurrency();

  const handleCurrencySelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrency(e.target.value);
      window?.location?.reload();
    },
    []
  );

  const dropdownLabel = supportedCurrencies.find((c) => currency === c.key);

  return (
    <HeaderMenuDropdown
      className={styles.currencySelectDropdown}
      content={
        <legend className={styles.currencySelectDropdownLabel}>
          {t(dropdownLabel.label) || t("currencies")}
          <IconComponent
            name={IconName.ChevronDown}
            className={styles.currencySelectDropdownLabelIcon}
          />
        </legend>
      }
      dropdownContent={
        <fieldset className={styles.currencySelectDropdownList}>
          {supportedCurrencies.map((c) => (
            <div
              key={c.key}
              className={clsx(
                styles.currencySelectDropdownItem,
                currency === c.key ? styles.selected : ""
              )}
            >
              <input
                type="radio"
                name="locale"
                id={c.key}
                value={c.key}
                defaultChecked={currency === c.key}
                onChange={handleCurrencySelect}
              />
              <label htmlFor={c.key}>{t(c.label)}</label>
            </div>
          ))}
        </fieldset>
      }
    />
  );
};
